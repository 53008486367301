import { Unit } from '../units/Unit';

export class UserInfo {
    constructor(
        public fullname: string,
        public email: string,
        public units: Unit[]
    ) {}

    static fromJson(json: any): UserInfo {
        return new UserInfo(
            json.fullname,
            json.email,
            json.units.map(u => Unit.fromJson(u)),
        );
    }
}
