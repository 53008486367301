import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { Validator } from '../../_base/validation/Validator';
import { ChangePassword } from './ChangePassword';
import { AuthService } from '../../models/auth/AuthService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { UserNotFoundError } from '../../models/auth/UserNotFoundError';

export class ChangePasswordHandler implements CommandHandler<ChangePassword> {
    constructor(private authService: AuthService, private sessionStorage: SessionStorage) {
    }

    async handle(command: ChangePassword): Promise<void> {
        this.validate(command);
        await this.changePassword(command);
    }

    private async changePassword(command: ChangePassword) {
        try {
            await this.authService.changePassword(command.validationCode, command.password);
            const response = await this.authService.login(command.email, command.password);
            await this.sessionStorage.do(currentSession => {
                currentSession.authenticate(response.user);
                currentSession.selectUnit(parseInt(response.unitId));
                currentSession.suggestPasswordReset = response.suggestPasswordReset;
            });
        } catch (e: any) {
            Validator.errorToValidationError(e, [
                { error: UserNotFoundError, message: 'Código inválido' },
            ]);
        }
    }

    private validate(command: ChangePassword) {
        const validator = new Validator();
        validator.check('password', command.password)
            .minLength(6, 'Debe tener al menos 6 caracteres');
        validator.check('password', command.password)
            .equals(command.confirmationPassword, 'Las contraseñas deben ser iguales');
        validator.check('validationCode', command.validationCode)
            .maxLength(4, 'Debe tener 4 caracteres')
            .notNullOrBlank('No puede estar vacio');
        validator.throwIfHasErrors();
    }
}
