import { Nullable } from '../../_base/lang/Nullable';
import { Address } from './Address';

export class ContactInfo {
    constructor(
        public emails: string[],
        public phones: string[],
        public whatsappNumber: Nullable<string>,
        public address: Nullable<Address>,
    ) {}

    static fromJson(json: any): ContactInfo {
        return new ContactInfo(
            json.emails,
            json.phones,
            json.whatsappNumber,
            json.address ? Address.fromJson(json.address) : null,
        );
    }

    isEmpty() {
        return this.emails.isEmpty() && this.phones.isEmpty() && !this.whatsappNumber && !this.address;
    }
}
