import { ToastContainer } from 'react-toastify';
import React from 'react';
import styled from 'styled-components';

export const Toast: React.FC = () => {
    return (
        <_ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            className={'toast'}
        />
    );
};

const _ToastContainer = styled(ToastContainer)`
  --toastify-icon-color-info: #1565D8;
  --toastify-icon-color-success: #119611;
  --toastify-icon-color-warning: #F2C94C;
  --toastify-icon-color-error: #DB640F;
`;
