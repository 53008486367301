import { Money } from '../general/Money';

export class Slot {
    constructor(
        public startAt: string,
        public durationInMinutes: number,
        public fee: Money,
    ) {}

    static fromJson(json: any) {
        return new Slot(json.startAt, json.durationInMinutes, Money.of(json.fee));
    }
}
