import { CommandHandler } from '../../../../_base/actionBus/CommandHandler';
import { AmenitiesService } from '../../../../models/amenities/AmenitiesService';
import { CancelBooking } from './CancelBooking';

export class CancelBookingHandler implements CommandHandler<CancelBooking> {
    constructor(private amenitiesService: AmenitiesService) {}

    async handle(command: CancelBooking): Promise<void> {
        await this.amenitiesService.cancelBooking(command.bookingId);
    }
}
