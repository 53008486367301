import React from 'react';
import styled from 'styled-components';
import arrow from './view_detail.png';
import { PaymentStatuses } from '@eroman/common/src/models/payments/PaymentStatuses';
import { PaymentDetailVM } from '../PaymentsVM';
import classNames from 'classnames';
import { styles } from '../../../styles';

export const PaymentRow: React.FC<Props> = (props) => {
    let statusColor = '#4D556F';
    let selectedColor = '#1565D8';
    let lineColor = '#1565D8';
    switch (props.item.status) {
        case PaymentStatuses.Informed:
            statusColor = '#c4cbdc';
            lineColor = '#F2C94C';
            break;
        case PaymentStatuses.Rejected:
            statusColor = '#DB640F';
            selectedColor = '#DB640F';
            lineColor = '#DB640F';
            break;
    }

    return <Item className={classNames(props.className, { selected: props.selected })} onClick={() => props.onClick(props.item.id)} selectedColor={selectedColor}>
        <Container statusColor={statusColor} lineColor={lineColor}>
            <Date>{props.item.date}</Date>
            <Amount>{props.item.amount}</Amount>
        </Container>
        <Arrow src={arrow} alt="Ver detalle de pago"/>
    </Item>;
};

const Amount = styled.p`
  flex-grow: 1;
  text-align: right;
  font-size: 20px;
  margin-right: 14px;
`;

const Date = styled.p``;

const Arrow = styled.img`
  flex-shrink: 0;
`;

const Container = styled.div.attrs((props: { statusColor: string, lineColor:string }) => props)`
  color: ${(props) => props.statusColor};
  flex-grow: 1;
  display: flex;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
  line-height: 40px;
  margin-right: 5px;
  padding-left: 39px;
  padding-right: 3px;
  border-radius: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: ' ';
    display: block;
    background-color: ${(props) => props.lineColor};
    width: 5px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    left: 15px;
    top: 10px;
  }
`;

const Item = styled.li.attrs((props: { selectedColor: string }) => props)`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  ${Arrow} { visibility: hidden; }
  
  &:hover, &.selected {
    
    ${Container} { background-color: #F4F7FE; }

    ${Amount} {
      color: ${(props) => props.selectedColor};
      font-size: 24px;
      font-weight: ${styles.fonts.weight.bold};
      margin-right: 14px;
    }
    
    ${Arrow} { visibility: visible; }
  }
`;

interface Props {
    item: PaymentDetailVM;
    onClick: (id: number) => void;
    selected: boolean;
    className? : string;
}
