import { FormField } from '../FormField';
import { FormTextAreaInput } from './FormTextAreaInput';
import React from 'react';

export const FormTextAreaField: React.FC<Props> = (props) => {
    return <FormField label={props.label} error={props.error}>
        <FormTextAreaInput
            className={props.className}
            placeholder={props.placeholder}
            hasError={!!props.error}
            {...props}
        />
    </FormField>;
};

interface Props {
    label?: string;
    error?: string|null;
    value?: string;
    onChangeText?: ((text: string) => void);
    className?: string;
    placeholder?: string;
}
