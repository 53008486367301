import { ChatMessage, MessageDirections } from '@eroman/common/src/models/chat/ChatMessage';
import React from 'react';
import styled from 'styled-components';
import { LocalDateTime } from '@eroman/common/src/_base/time/LocalDateTime';

type Props = {
    message: ChatMessage;
    recipient: string
};

export const Message: React.FC<Props> = ({ message , recipient }) => {

    function formatAMPM(date: LocalDateTime) {
        try {
            let minutes = date.minutes.toString();
            if (minutes.length === 1) minutes = `0${minutes}`;
            let amOrPm = date.hours >= 12 ? 'pm' : 'am';
            let month = date.month.toString();
            let day = date.day.toString();
            if (month.length === 1) month = `0${month}`;
            if (day.length === 1) day = `0${day}`;

            return `${day}/${month} ${date.hours}:${minutes} ${amOrPm}`;
        } catch (e: any) {
            let newDate = LocalDateTime.fromDate(new Date());
            formatAMPM(newDate);
        }
    }


    const renderHeader = () => {
        let date = formatAMPM(message.at);
        return message.direction === MessageDirections.Sent ? (
            <div style={{ marginBottom: '4px' }}>
                <Autor>PE Bot</Autor>
                <Time>{date}</Time>
            </div>
        ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Time style={{ marginRight: '10px' }}>{date}</Time>
                <Autor>{recipient}</Autor>
            </div>
        );
    };

    return (
        <>
            {renderHeader()}
            <MessageContainer className={message.direction}>
                <MessageBody>{message.body}</MessageBody>
            </MessageContainer>
        </>
    );
};

const MessageBody = styled.p`
  margin-bottom: 0;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.1em;
`;

const MessageContainer = styled.div`
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  max-width: 700px; 
  width: fit-content; 
  min-width: 300px;
  background-color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;

  &.Sent {
    color: #676767;
    background-color: #ffffff;
    margin-right: auto;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
    border-top-right-radius: 17px;
  }

  &.Received {
    background-color: #00a3ff;
    margin-left: auto;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
    color: #ffffff;
  }
`;


const Autor = styled.span`
  color: #515151;
  font-weight: 700;
  margin-right: 10px;
  font-size: 14px;
`;

const Time = styled.span`
  color: #a0a0a0;
  font-weight: 600;
  font-size: 14px;
`;
