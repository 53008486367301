import React from 'react';
import styled from 'styled-components';
import icon from './copyableIcon.png';
import { toast } from 'react-toastify';
import { styles } from '../../../../styles';

export const CopyableInfo: React.FC<Props> = (props) => {
    async function copy() {
        await navigator.clipboard.writeText(props.info);
        toast.info(`${props.title} copiado`, {
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return <Container onClick={() => copy()}>
        <Info>{props.title}: {props.info}</Info>
        <CopyIcon/>
    </Container>;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ECF0F5;
  border-radius: 5px 0 0 5px;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 52px;
  padding: 10px 20px 10px 10px;

  &:hover {
    background-color: #C8DEFF;
  }

  &:hover i {
    background-position-y: -20px;
  }
`;

const Info = styled.p`
  font-size: ${styles.fonts.size.regular};
  color: #666F89;
`;

const CopyIcon = styled.i`
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url(${icon});
  background-position-y: 0;
  background-size: 20px 40px;
  background-repeat: no-repeat;
`;

interface Props {
    title: string,
    info: string,
}
