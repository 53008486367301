import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetClaimSubjects } from './GetClaimSubjects';
import { ClaimSubject } from '../../models/claims/ClaimSubject';
import { ClaimService } from '../../models/claims/ClaimService';

export class GetClaimSubjectsHandler implements QueryHandler<GetClaimSubjects, ClaimSubject[]> {
    constructor(private claimService: ClaimService) {
    }

    handle(query: GetClaimSubjects): Promise<ClaimSubject[]> {
        return this.claimService.getSubjects();
    }
}
