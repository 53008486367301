import { GetUnitPaymentStatusResponse, UnitService } from '../../models/units/UnitService';
import { HttpClient } from '../../_base/http/HttpClient';
import { Expense } from '../../models/expenses/Expense';
import { paymentProviderfromJson } from '../../models/associations/paymentProviders/paymentProviderFromJson';
import { Money } from '../../models/general/Money';
import { PaymentData } from '../../models/payments/PaymentData';

export class HttpUnitService implements UnitService {
    constructor(private httpClient: HttpClient) {}

    async getUnitPaymentStatus(unitId: number): Promise<GetUnitPaymentStatusResponse> {
        const response = await this.httpClient.get(`/units/${unitId}/paymentStatus`);
        return {
            expense: response.body.expense ? Expense.fromJson(response.body.expense) : null,
            balance: Money.of(response.body.balance),
            payments: response.body.payments.map(p => PaymentData.fromJson(p)),
            paymentProviders: response.body.paymentProviders?.filter(p => p != null && p.type)?.map(x => paymentProviderfromJson(x)) || [],
        };
    }
}
