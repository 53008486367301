import React from 'react';
import { AmenityInfo } from '@eroman/common/src/views/app/Amenities/AmenitiesVM';
import { AmenityModalHeader } from '../Amenities/AmenityModalHeader/AmenityModalHeader';
import styled from 'styled-components';
import AppContext from '../../lib/AppContext';
import { BookAmenityVM } from '@eroman/common/src/views/app/BookAmenity/BookAmenityVM';
import { styles } from '../../styles';
import { Slot } from './Slot';
import { BookAmenityView } from '@eroman/common/src/views/app/BookAmenity/BookAmenityView';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { toast } from 'react-toastify';
import 'react-calendar/dist/Calendar.css';
import { CalendarField } from '../../components/form/CalendarField';
import { RightModal } from '../../components/modals/RightModal';
import { showConfirm } from '../../components/modals/ConfirmModal';

export class BookAmenityModal extends React.Component<Props> implements BookAmenityView {
    private presenter = AppContext.presenters.bookAmenity(this);

    state = {
        model: new BookAmenityVM(),
        canSubmit: true,
    };

    modelChanged(model: any) {
        this.setState({ model });
    }

    async componentDidMount() {
        this.presenter.set({ amenity: this.presenter.toSelectedAmenityInfo(this.props.amenity) });
        await this.presenter.start();
    }

    showSuccessMessage() {
        toast.success('Reserva realizada con éxito');
    }

    showErrorMessage(message: string) {
        toast.error(message);
    }

    async submit() {
        this.confirmBooking();
        this.props.closeModal();
    }
    confirmBooking = () => {
        const message = `
            Horario: ${this.state.model.selectedSlot?.startAt} - 
            Duración: ${this.state.model.selectedSlot?.durationInMinutes} minutos - 
            Precio: ${this.state.model.selectedSlot?.fee}
        `;
        showConfirm('¿Desea confirmar la reserva?', message, () => this.presenter.book());
    };

    parseDatesToOptions = () => {
        return this.state.model.dates.map(it => ({ value: it, label: it }));
    };

    private renderAvailableSlots() {
        return <>
            <Slots>
                {this.state.model.slots.map(slot =>
                    <Slot
                        key={slot.startAt}
                        onClick={() => this.presenter.selectSlot(slot)}
                        slot={slot}
                        selectedSlot={this.state.model.selectedSlot}
                    />,
                )}
            </Slots>
            {this.state.model.selectedSlot != null &&
                <SelectedSlotContainer>
                    <SelectedSlotInfo>
                        <p>Horario: {this.state.model.selectedSlot.startAt}</p>
                        <p>|</p>
                        <p>Duración: {this.state.model.selectedSlot.durationInMinutes} min.</p>
                        <p>|</p>
                        <p>Importe: {this.state.model.selectedSlot.fee}</p>
                    </SelectedSlotInfo>

                    <_PrimaryButton onClick={() => this.submit()}>Reservar</_PrimaryButton>
                </SelectedSlotContainer>
            }
        </>;
    }

    render() {
        const bookingText = 'Seleccione un día para ver los turnos disponibles para el amenity seleccionado.';

        return <RightModal isOpen={this.props.isOpen} closeModal={this.props.closeModal} >
            <AmenityModalHeader
                amenityName={this.props.amenity.name}
                closeModal={this.props.closeModal}
                info={bookingText}
            />
            <Container>
                {!this.state.model.isLoading &&
                    <CalendarField
                        value={this.state.model.selectedDate}
                        onChange={this.presenter.loadSlots}
                        daysToShow={this.state.model.dates}
                    />
                }
                {(this.state.model.selectedDate && this.state.model.slots.isNotEmpty()) && this.renderAvailableSlots()}
                {(this.state.model.selectedDate && this.state.model.slots.isEmpty()) &&
                    <p>No hay turnos disponibles para la fecha seleccionada.</p>
                }
            </Container>
        </RightModal>;
    }
}

const Container = styled.div`
  margin-top: 30px;
`;

const Slots = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const _PrimaryButton = styled(PrimaryButton)`
  width: 30%
`;

const SelectedSlotContainer = styled.div`
  margin-top: 20px;
  flex-direction: column;
  display: flex;
  gap: 20px;
  text-align: center;
  align-items: center;
`;

const SelectedSlotInfo = styled.div`
  display: flex;
  justify-content: center;

  p {
    font-size: ${styles.fonts.size.small};
    font-weight: ${styles.fonts.weight.regular};
    color: #666F89;
    line-height: 25px;
    margin: 0 5px;
  }
`;

interface Props {
    isOpen: boolean,
    closeModal: () => void,
    amenity: AmenityInfo
}
