import { AnalyticsService } from '../../models/AnalyticsService';
import { DeviceInfo } from '../../models/DeviceInfo';
import { HttpClient } from '../../_base/http/HttpClient';

export class HttpAnalyticsService implements AnalyticsService {
    constructor(private httpClient: HttpClient) {}

    async registerDevice(info: DeviceInfo): Promise<void> {
        await this.httpClient.post('/analytics/devices', { platform: info.platform, version: info.version });
    }
}
