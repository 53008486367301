import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { CreateClaim } from './CreateClaim';
import { ClaimService } from '../../models/claims/ClaimService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { Validator } from '../../_base/validation/Validator';

export class CreateClaimHandler implements CommandHandler<CreateClaim> {
    constructor(private sessionStorage: SessionStorage, private claimService: ClaimService){}

    async handle(command: CreateClaim): Promise<void> {
        this.validate(command);
        await this.createClaim(command);
    }

    private async createClaim(command: CreateClaim) {
        const session = await this.sessionStorage.get();
        const currentUnitId = session.unit!!.id;
        await this.claimService.createClaim(currentUnitId, Number(command.subjectId), command.description, command.attachments);
    }

    private validate(command: CreateClaim) {
        const validator = new Validator();
        validator.check('subjectId', command.subjectId)
            .notNullOrBlank('Debe seleccionar un asunto');
        validator.check('description', command.description)
            .notNullOrBlank('Debe ingresar una descripción');
        validator.throwIfHasErrors();
    }
}
