import React, { Component } from 'react';
import styled from 'styled-components';
import logo from './logo.png';
import classNames from 'classnames';
import AppContext from '../../../lib/AppContext';
import { MainMenuView } from './MainMenuPresenter';
import { MainMenuVM } from './MainMenuVM';
import { styles } from '../../../styles';

export class MainMenu extends Component implements MainMenuView{
    private presenter = AppContext.presenters.mainMenu(this);

    state = {
        model: new MainMenuVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    render() {
        return (
            <Container>
                <Logo src={logo} alt="Portal Expensas"/>
                <ul>
                    {this.state.model.items.map(i => (
                        <li key={i.label} className={classNames({ selected: i.selected })}>
                            <a href="#" onClick={e => this.onItemClick(e, i.label)}>
                                {i.label}
                            </a>
                        </li>
                    ))}
                </ul>
                <Link href='https://e-roman.com.ar/' target='_blank'>Rom&aacute;n Computaci&oacute;n</Link>
            </Container>
        );
    }

    private async onItemClick(e: React.MouseEvent<HTMLAnchorElement>, itemLabel: string) {
        e.preventDefault();
        await this.presenter.select(itemLabel);
    }

    modelChanged(model: MainMenuVM) {
        this.setState({ model });
    }
}

const Container = styled.div`
  background-color: #178BFF;
  border-top-right-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    flex-grow: 1;
  }
  
  li {
    display: block;
    position: relative;
    margin-bottom: 10px;
    
    a {
      display: block;
      text-decoration: none;
      color: #FFFFFF;
      font-weight: ${styles.fonts.weight.regular};
      font-size: ${styles.fonts.size.regular};
      border-radius: 7px;
      line-height: 40px;
      margin-left: 10px;
      margin-right: 5px;
      padding-left: 18px;
      padding-right: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 225px;
      
      &::after {
        content: ' ';
        display: block;
        background-color: #1565D8;
        width: 5px;
        height: 24px;
        border-radius: 10px;
        position: absolute;
        left: 17px;
        top: 8px;
      }
    }

    &.selected a, &:hover a {
      background-color: #1565D8;
      
      &::after {
        background-color: #178BFF;
        right: 13px;
        left: auto;
      }
    }
  }
`;

const Logo = styled.img`
  margin: 25px auto 50px;
`;

const Link = styled.a`
  color: #FFFFFF;
  margin-right: 13px;
  margin-bottom: 1rem;
  align-self: flex-end;
`;
