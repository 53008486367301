import { Nullable } from '@eroman/common/src/_base/lang/Nullable';

export class AnnouncementsVM {
    isLoading = false;
    announcements: AnnouncementDetailVM[] = [];
    selectedAnnouncement: Nullable<AnnouncementDetailVM> = null;
}

export class AnnouncementDetailVM {
    id = 0;
    associationId = 0;
    title = '';
    content = '';
    date = '';
    attachments: FileVM[] = [];
}

export class FileVM {
    url = '';
    label = '';
}
