import React from 'react';
import styled from 'styled-components';
import { MainMenu } from './MainMenu/MainMenu';
import { RouterProps } from '../../lib/navigation/reactRouter/RouterProps';
import { Header } from './Header/Header';

export const Layout: React.FC<RouterProps> = (props) => {
    return (
        <Container>
            <HeaderContainer>
                <Header navigation={props.navigation} />
            </HeaderContainer>
            <InnerContainer>
                <Sidebar>
                    <MainMenu />
                </Sidebar>
                <MainContent>
                    {props.children}
                </MainContent>
            </InnerContainer>
        </Container>
    );
};

const HeaderContainer = styled.header`
  background-color: #ffffff;
  padding-left: 240px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-basis: 240px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: -90px;
`;

const MainContent = styled.main`
  flex-grow: 1;
  padding: 30px 40px;
`;
