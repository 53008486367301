import { Query } from '../../_base/actionBus/Query';
import { Expense } from '../../models/expenses/Expense';
import { Nullable } from '../../_base/lang/Nullable';
import { PaymentProvider } from '../../models/associations/paymentProviders/PaymentProvider';
import { Money } from '../../models/general/Money';
import { PaymentData } from '../../models/payments/PaymentData';

export class GetUnitPaymentStatus extends Query<GetUnitPaymentStatusResult> {
}

export interface GetUnitPaymentStatusResult {
    expense: Nullable<Expense>;
    balance: Money;
    payments: PaymentData[];
    paymentProviders: PaymentProvider[];
}
