import { WebNavigator } from '../WebNavigator';
import { createBrowserHistory } from 'history';
import { RouteCall, RouteConfig, RouteInfo } from './RouteConfig';
import { RouteUrlGenerator } from './RouteUrlGenerator';
import { RouteMatch } from '../RouteMatch';
import { Location } from '../Location';
import queryString from 'query-string';
import { RouterRenderer } from './RouterRenderer';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { Observable } from '@eroman/common/src/_base/lang/Observable';
import { EventsLogger } from '@eroman/common/src/infrastructure/EventsLogger';

export type IsAuthenticatedFunc = () => Promise<boolean>;

export class ReactRouterNavigation implements WebNavigator {
    private readonly urlGenerator = new RouteUrlGenerator();
    private _currentRouteChanged = new Observable<Nullable<RouteMatch>>();
    private _currentRoute: Nullable<RouteMatch> = null;
    private renderer: RouterRenderer;

    constructor(
        private routeConfig: RouteConfig,
        private isAuthenticated: IsAuthenticatedFunc,
        private analyticsLogger: EventsLogger,
        private history = createBrowserHistory(),
    ) {
        this.renderer = this.createRenderer();
        this.validateDuplicatedNames();
        this.addNotFoundRoute();
    }

    get currentRouteChanged(): Observable<Nullable<RouteMatch>> {
        return this._currentRouteChanged;
    }

    get location(): Location {
        return this.history.location;
    }

    get currentRoute() {
        return this._currentRoute;
    }

    private validateDuplicatedNames() {
        const routeNames: string[] = [];
        for (const route of this.routeConfig.routes) {
            if (routeNames.includes(route.name)) throw new Error(`Route with name '${route.name}' already exists`);
            routeNames.push(route.name);
        }
    }

    generateUrl(name: string, params?: Record<string, any>): string {
        const route = this.findRouteByName(name);
        if (!route) throw new Error(`Route with name "${name}" not found`);
        return this.urlGenerator.generate(route, params);
    }

    navigate(name: string, params?: Record<string, any>, replace = false) {
        const url = this.generateUrl(name, params);
        replace ? this.history.replace(url) : this.history.push(url);
        this.logAnalytics(name);
    }

    navigateToPath(path: string) {
        this.history.push(path);
        this.logAnalytics(path.split('/')[1]);
    }

    private logAnalytics(view: string) {
        this.analyticsLogger.logScreenViewEvent(view).catch(reason => console.log(reason));
    }

    private findRouteByName(name: string) {
        return this.routeConfig.routes.find(route => route.name === name);
    }

    navigateToAuth(replace: boolean = true) {
        const call = this.routeConfig.authRouteCall();
        let params = this.queryParams(call);
        this.navigate(call.name, params, replace);
    }

    private queryParams(call: RouteCall) {
        let nextRoute = `${this.location.pathname}${this.location.search}`;
        let params = call.params;
        if (nextRoute != '/' && !nextRoute.includes('undefined')) {
            params = { ...params, next: encodeURIComponent(nextRoute) };
        }
        return params;
    }

    navigateToHome() { this.navigate('expenses'); }

    navigateToNotFound() {
        this.navigate('404');
    }

    private addNotFoundRoute() {
        this.routeConfig.routes.unshift({
            name: '404',
            path: this.routeConfig.notFoundRoutePath || '/404',
            component: this.routeConfig.notFoundComponent,
        });
    }

    private updateMatch(route: RouteInfo, match) {
        const query = queryString.parse(this.location.search) as Record<string, string>;
        this._currentRoute = {
            name: route.name,
            public: route.public,
            path: route.path,
            params: match.params,
            meta: route.meta ?? {},
            query,
        };

        // notify in next event-loop cycle because observers can produce
        // React renders and this method is executed in a render context
        setTimeout(() => { this._currentRouteChanged.notify(this.currentRoute); }, 0);
    }

    render() {
        return this.renderer.render();
    }

    private createRenderer() {
        return new RouterRenderer(
            this,
            this.history,
            this.routeConfig,
            this.isAuthenticated,
            (route, match) => this.updateMatch(route, match),
        );
    }
}
