import React, { KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../../styles';

export const FormNumberInput: React.FC<Props> = (props) => {
    const regEx = /^\d*[.,]?\d*$/;

    const isCharacter = key => key.length === 1;

    const isSpecialKeyHold = e => e.metaKey || e.ctrlKey || e.altKey;

    const shouldDiscardKey = e => isCharacter(e.key) && !isSpecialKeyHold(e) && !(e.target.value + e.key).match(regEx);

    const onChange = e => { if (e.target.value.match(regEx)) props.onChangeText?.(e.target.value); };

    const onKeyDown = e => shouldDiscardKey(e) ? e.preventDefault() : props.onKeyDown?.(e);

    return <StyledInputMask className={classNames(props.className, { 'has-error': props.hasError })}
        value={props.value}
        spellCheck="false"
        onChange={onChange}
        onKeyDown={onKeyDown}
    />;
};

const StyledInputMask = styled.input`
  color: #494949;
  font-family: 'Nunito', sans-serif;
  font-size: ${styles.fonts.size.xsmall};
  line-height: 16px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #8692A6;

  &:focus { border-color: #1565D8; }

  &.has-error {
    color: #E15201;
    border-color: #E15201;
  }
`;

interface Props {
    hasError?: boolean;
    value?: string;
    onChangeText?: ((text: string) => void);
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    isPassword?: boolean;
    className?: string;
}
