import React from 'react';
import styled from 'styled-components';
import AppContext from '../../../lib/AppContext';
import { Loader } from '../../../components/Loader';
import { Row } from './Row';
import mailIcon from './mailIcon.png';
import phonesIcon from './phonesIcon.png';
import addressIcon from './addressIcon.png';
import whatsappIcon from './whatsappIcon.png';
import workingHoursIcon from './workingHoursIcon.png';
import webSiteIcon from './webSite.png';
import { ManagerInfoVM } from '@eroman/common/src/views/app/ManagerInfo/ManagerInfoVM';
import { styles } from '../../../styles';
import { ManagerInfoView } from '@eroman/common/src/views/app/ManagerInfo/ManagerInfoPresenter';

export class ManagerInfoBox extends React.Component<any, State> implements ManagerInfoView {
    private presenter = AppContext.presenters.managerInfo(this);

    state = {
        model: new ManagerInfoVM(),
    };

    modelChanged(model: ManagerInfoVM) {
        this.setState({ model });
    }

    async componentDidMount() {
        await this.presenter.start();
    }

    private renderContent() {
        if (this.state.model.isLoading) return <_Loader/>;
        return <>
            <h1>{this.state.model.name}</h1>
            <_Row title="EMAILS:" content={this.state.model.emails} icon={mailIcon}/>
            <_Row title="TELÉFONOS:" content={this.state.model.phones} icon={phonesIcon}/>
            <_Row title="WHATSAPP:" content={this.state.model.whatsapp} icon={whatsappIcon}/>
            <_Row title="HORARIOS DE ATENCIÓN:" content={this.state.model.workingHours} icon={workingHoursIcon}/>
            <_Row title="DIRECCIÓN:" content={this.state.model.address} icon={addressIcon}/>
            <_Row title="SITIO WEB:" content={this.state.model.website} icon={webSiteIcon}/>
        </>;
    }

    render() {
        return (
            <Container>
                {this.renderContent()}
            </Container>
        );
    }
}

const _Row = styled(Row)``;

const Container = styled.div`
  width: 474px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  padding: 20px;
  min-height: 236px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: ${styles.fonts.size.big};
    font-weight: ${styles.fonts.weight.bold};
    color: #42557B;
    margin-bottom: 20px;
  }

  ${_Row} {
    border-bottom: 1px solid rgba(138, 150, 186, .5);
    
    &:last-child {
      border: none;
    }
  }
`;

const _Loader = styled(Loader)`
  align-self: center;
  flex-grow: 1;
  width: 44px;
  height: 44px;
`;

interface State {
    model: ManagerInfoVM;
}
