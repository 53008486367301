import React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';
import AppContext from '../../lib/AppContext';
import iconFile from '../../components/attachmentList/file.png';
import { PublicAnnouncementView } from './PublicAnnouncementPresenter';
import { PublicAnnouncementVM } from './PublicAnnouncementVM';
import logo from './logo.png';

export class PublicAnnouncementPage extends React.Component<PublicAnnouncementVM> implements PublicAnnouncementView {
    private presenter = AppContext.presenters.publicAnnouncements(this);

    state = new PublicAnnouncementVM();

    modelChanged(model: PublicAnnouncementVM) { this.setState(model); }

    async componentDidMount() { await this.presenter.start(); }

    render() {
        return <Root>
            <img className="logo" src={logo} alt="logo" />
            <Box>
                <TitleAndDate>
                    <Title>{this.state.title}</Title>
                    <Date>{this.state.date}</Date>
                </TitleAndDate>
                <AttachmentList>
                    {this.state.attachments.mapIndexed((attachment, idx) =>
                        <Attachment key={idx}>
                            <img src={iconFile} alt="icon" />
                            <a href={attachment.url} rel="noreferrer" target="_blank">{attachment.label}</a>
                        </Attachment>
                    )}
                </AttachmentList>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </Box>
            <Link href="https://e-roman.com.ar/" rel="noreferrer" target="_blank">Rom&aacute;n Computaci&oacute;n</Link>
        </Root>;
    }
}

const Title = styled.div`font-size: ${styles.fonts.size.xxbig};`;

const Date = styled.div`font-size: ${styles.fonts.size.big};`;

const TitleAndDate = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  line-height: 32px;

  ${Title} { flex: auto; }

  ${Date} {
    flex: none;
    margin: 0 15px 0 50px;
  }
`;

const Attachment = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  img {
    flex: none;
    height: 13px;
    width: 10px;
  }

  a {
    flex: none;
    font-size: ${styles.fonts.size.small};
    margin-left: 5px;
    text-decoration: none;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Attachment} {
    flex: none;

    &:not(:last-child) { margin-right: 13px; }
  }
`;

const Box = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 30%);
  color: ${styles.textColor};
  font-family: ${styles.fonts.default};
  font-size: ${styles.fonts.size.regular};
  padding: 50px 100px 100px;

  ${AttachmentList} { margin: 10px 0 15px; }
`;

const Link = styled.a`color: white;`;

const Root = styled.div`
  align-items: center;
  background-color: ${styles.primaryColor};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 5vh 10vh 20vh;

  .logo {
    flex: none;
    height: 53px;
    width: 191px;
  }

  ${Box} {
    flex: auto;
    margin-top: 15px;
    max-width: 800px;
    width: 100%;
  }

  ${Link} { margin-top: 50px; }

  @media (max-width: 640px) { padding: 5vh 5vh 20vh; }

  @media (max-width: 800px) {
    ${Box} {
      padding: 50px 50px 100px;

      ${TitleAndDate} {
        flex-direction: column-reverse;

        ${Title} {
          font-size: ${styles.fonts.size.xbig};
          font-weight: bold;
        }

        ${Date} { margin: 0; }
      }
    }
  }
`;
