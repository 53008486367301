import { AmenitiesVM } from '@eroman/common/src/views/app/Amenities/AmenitiesVM';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetAmenities } from '@eroman/common/src/actions/amenities/getAmenities/GetAmenities';
import { GetDisabledAmenities } from '@eroman/common/src/actions/amenities/getAmenities/GetDisabledAmenities';
import { Amenity } from '@eroman/common/src/models/amenities/Amenity';

export interface AmenitiesView {
    modelChanged(model);
}

export class AmenitiesPresenter {
    private model = new AmenitiesVM();

    constructor(
        private view: AmenitiesView,
        private actionBus: ActionBus,
    ) {}

    async start() {
        await this.loadAmenities();
        await this.loadDisabledAmenities();
    }

    async loadAmenities() {
        this.set({ isLoading: true });
        let amenities = await this.actionBus.query(new GetAmenities());
        this.set({ amenities: amenities.map(a => this.toAmenityVM(a)), isLoading: false });
    }

    async loadDisabledAmenities() {
        this.set({ isLoading: true });
        let disabledAmenities = await this.actionBus.query(new GetDisabledAmenities());
        this.set({ disabledAmenities: disabledAmenities, isLoading: false });
    }

    async selectAmenity(id: number) {
        const amenity = this.model.amenities.singleOrNull(item => item.id === id);
        this.set({ selectedAmenity: amenity });
    }

    private toAmenityVM(amenity: Amenity) {
        return {
            id: amenity.id,
            name: amenity.name,
            general: amenity.general,
            availability: amenity.availability,
            slots: amenity.slots,
            iconPath: amenity.iconPath,
        };
    }

    private set<K extends keyof AmenitiesVM>(changes: Pick<AmenitiesVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
