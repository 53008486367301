import React from 'react';
import AppContext from '../../lib/AppContext';
import styled from 'styled-components';
import stainImage from './top_stain.png';
import loginImage from './login_image.png';
import { Loader } from '../../components/Loader';
import { FormGeneralError } from '../../components/form/FormGeneralError';
import { FormField } from '../../components/form/FormField';
import { FormTextInput } from '../../components/form/FormText/FormTextInput';
import { styles } from '../../styles';
import { PasswordView } from './PasswordPresenter';
import { PasswordVM } from './PasswordVM';
import { toast } from 'react-toastify';
import { PasswordField } from '../../components/form/Password/PasswordField';

export class PasswordPage extends React.Component<any, State> implements PasswordView {
    private presenter = AppContext.presenters.password(this);

    state = {
        model: new PasswordVM(),
    };

    modelChanged(model: PasswordVM) {
        this.setState({ model });
    }

    showSuccessMessage() {
        toast.success('Contraseña cambiada con éxito');
    }

    goBackToEmail() {
        this.presenter.set({
            isValidationCodeSent: false,
            password: '',
            confirmationPassword: '',
            validationCode: '',
        });
    }

    async componentDidMount() {}

    render() {
        return (
            <Container>
                <InnerContainer>
                    <LoginImage src={loginImage} alt="Portal Expensas"/>
                    <LoginForm>
                        {this.state.model.isLoading ? (
                            <Loader/>
                        ) : (
                            <>
                                <Title>No sé mi contraseña</Title>
                                {!this.state.model.isValidationCodeSent ?
                                    <>
                                        <_FormGeneralError error={this.state.model.errors['']}/>
                                        <_FormField
                                            label="Email:"
                                            error={this.state.model.errors['email']}
                                        >
                                            <_FormTextInput
                                                value={this.state.model.email}
                                                onChangeText={(email) => this.presenter.set({ email })}
                                                hasError={!!this.state.model.errors['email']}
                                            />
                                        </_FormField>
                                        <CodeText>Enviaremos un código de 4 dígitos al email ingresado.</CodeText>
                                    </>
                                    :
                                    <>
                                        <Text>Ingresá el código que hemos enviado a tu correo y creá tu nueva contraseña. En caso de ser necesario revisar la carpeta de spam.</Text>
                                        <_FormGeneralError error={this.state.model.errors['']}/>
                                        <_FormFieldCode
                                            label="Código:"
                                            error={this.state.model.errors['validationCode']}
                                        >
                                            <_FormTextInputCode
                                                value={this.state.model.validationCode}
                                                onChangeText={(validationCode) => this.presenter.set({ validationCode })}
                                                hasError={!!this.state.model.errors['validationCode']}
                                                autocomplete={'off'}
                                            />
                                        </_FormFieldCode>
                                        <CodeHelper onClick={() => this.presenter.forgotPassword()}>Reenviar código</CodeHelper>
                                        <_FormField
                                            label="Nueva contraseña:"
                                            error={this.state.model.errors['password']}
                                        >
                                            <PasswordField
                                                value={this.state.model.password}
                                                onChangeText={(password) => this.presenter.set({ password })}
                                                hasError={!!this.state.model.errors['password']}
                                                autocomplete={'off'}
                                            />
                                        </_FormField>
                                        <_FormField
                                            label="Repetir nueva contraseña:"
                                            error={this.state.model.errors['confirmationPassword']}
                                        >
                                            <PasswordField
                                                value={this.state.model.confirmationPassword}
                                                onChangeText={(confirmationPassword) => this.presenter.set({ confirmationPassword })}
                                                hasError={!!this.state.model.errors['confirmationPassword']}
                                                autocomplete={'off'}
                                            />
                                        </_FormField>
                                    </>
                                }
                                <ButtonsContainer>
                                    <PrimaryButton href="#" onClick={this.state.model.isValidationCodeSent ? () => this.goBackToEmail() : () => this.presenter.goBack('/')}>Volver</PrimaryButton>
                                    <PrimaryButton href="#" onClick={!this.state.model.isValidationCodeSent ? () => this.presenter.forgotPassword() : () => this.presenter.changePassword()}>Enviar</PrimaryButton>
                                </ButtonsContainer>
                            </>
                        )}
                    </LoginForm>
                </InnerContainer>
            </Container>
        );
    }
}

const _FormFieldCode = styled(FormField)`
  width: 150px;
`;

const CodeHelper = styled.a`
  margin-top: -20px;
  cursor: pointer;
  margin-bottom: 20px;
  color: #696f79;

  &:hover {
    color: #4f545b;
  }
`;

const Text = styled.p`
  margin-top: -20px;
  margin-bottom: 20px;
  color: #696f79;
  font-size: ${styles.fonts.size.small};
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const _FormTextInput = styled(FormTextInput)`
  font-size: ${styles.fonts.size.small};
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 5px;
`;

const _FormTextInputCode = styled(FormTextInput)`
  font-size: ${styles.fonts.size.small};
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 5px;
  height: 35px;
`;

const Container = styled.div`
  background-color: #178BFF;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 810px;
  padding: 100px 60px 90px;
  background: #FFFFFF url(${stainImage}) no-repeat left top;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
`;

const LoginImage = styled.img`
  margin-top: 7px;
  flex-shrink: 0;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const CodeText = styled.p`
  margin-bottom: 40px; 
  margin-top: -10px;
`;

const LoginForm = styled.div`
  width: 340px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const _FormField = styled(FormField)`
  label {
    font-size: ${styles.fonts.size.regular};
    margin-bottom: 4px;
  }
`;

const PrimaryButton = styled.a`
  display: inline-block;
  font-size: ${styles.fonts.size.big};
  font-weight: ${styles.fonts.weight.regular};
  color: #FFFFFF;
  padding: 10px 0;
  margin-top: 10px;
  text-align: center;
  background-color: #178BFF;
  border-radius: 80px;
  text-decoration: none;
  width: 30%;

  &:hover {
    background-color: #055DD4;
  }
`;

const _FormGeneralError = styled(FormGeneralError)`
  font-size: ${styles.fonts.size.big};
`;

interface State {
    model: PasswordVM;
}
