import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { CommentClaim } from './CommentClaim';
import { ClaimService } from '../../models/claims/ClaimService';
import { Validator } from '../../_base/validation/Validator';

export class CommentClaimHandler  implements CommandHandler<CommentClaim> {
    constructor(private claimService: ClaimService) {}

    async handle(command: CommentClaim): Promise<void> {
        this.validate(command);
        await this.claimService.commentClaim(command.claimId, command.message, command.attachments);
    }

    private validate(command: CommentClaim) {
        const validator = new Validator();
        validator.check('message', command.message).notNullOrBlank('Debe ingresar un mensaje');
        validator.throwIfHasErrors();
    }

}
