import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';
import { LocalDateTime } from '../../_base/time/LocalDateTime';

export class ChatMessage {
    constructor(public body: string, public direction: MessageDirections, public at: LocalDateTime = LocalDateTime.fromDate(new Date())) {}

    static fromJson(json: any, jsonDateSerializer: JsonDateSerializer): ChatMessage {
        return new ChatMessage(
            json.body.text,
            ChatMessage.getDirectionFromString(json.direction),
            jsonDateSerializer.fromIso8601(json.at)
        );
    }

    static getDirectionFromString(direction: string): MessageDirections {
        switch (direction.toLowerCase()) {
            case 'sent':
                return MessageDirections.Sent;
            case 'received':
                return MessageDirections.Received;
            case 'error':
                return MessageDirections.Error;
            default:
                throw new Error('Invalid direction');
        }
    }
}

export enum MessageDirections {
    Sent = 'Sent',
    Received = 'Received',
    Error = 'Error'
}
