import { LocalDate } from './LocalDate';
import { TimeZone } from './TimeZone';

export class LocalDateTime {
    private readonly _day: number;
    private readonly _month: number;
    private readonly _year: number;
    private readonly _hours: number;
    private readonly _minutes: number;
    private readonly _seconds: number;
    private readonly _milliseconds: number;

    constructor(year: number, month: number, day: number, hours: number, minutes: number, seconds: number, milliseconds: number) {
        this._year = year;
        this._month = month;
        this._day = day;
        this._hours = hours;
        this._minutes = minutes;
        this._seconds = seconds;
        this._milliseconds = milliseconds;
    }

    get day(): number {
        return this._day;
    }

    get month(): number {
        return this._month;
    }

    get year(): number {
        return this._year;
    }

    get hours(): number {
        return this._hours;
    }

    get minutes(): number {
        return this._minutes;
    }

    get seconds(): number {
        return this._seconds;
    }

    get milliseconds(): number {
        return this._milliseconds;
    }

    equals(other: LocalDateTime) {
        return this.day === other.day &&
            this.month === other.month &&
            this.year === other.year &&
            this.hours === other.hours &&
            this.minutes === other.minutes &&
            this.seconds === other.seconds &&
            this.milliseconds === other.milliseconds;
    }

    equalsDate(other: LocalDateTime) {
        return this.day === other.day &&
            this.month === other.month &&
            this.year === other.year;
    }

    isBefore(other: LocalDateTime): boolean {
        const otherDate = new Date(other.year, other.month - 1, other.day, other.hours, other.minutes, other.seconds);
        const thisDate = new Date(this.year, this.month - 1, this.day, this.hours, this.minutes, this.seconds);
        return thisDate.getTime() <= otherDate.getTime();
    }

    toDate(): LocalDate {
        return LocalDate.of(this.year, this.month, this.day);
    }

    static of(year: number, month: number, day: number, hours = 0, minutes = 0, seconds = 0, milliseconds = 0) {
        return new LocalDateTime(year, month, day, hours, minutes, seconds, milliseconds);
    }

    static fromDate(date: Date) {
        return new LocalDateTime(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    static now() {
        return LocalDateTime.fromDate(new Date());
    }

    static fromISO8601String(value: string) {
        const valueParsed = value.replace('Z', '').replace('T', ' ');
        const date = valueParsed.split(' ')[0].split('-');
        const time = valueParsed.split(' ')[1].split(':');
        return LocalDateTime.of(
            parseInt(date[0], 10),
            parseInt(date[1], 10),
            parseInt(date[2], 10),
            parseInt(time[0], 10),
            parseInt(time[1], 10),
            parseInt(time[2], 10)
        );
    }

    toISO8601String(): string {
        const dayStr = this.day.toString(10).padZeros(2);
        const monthStr = this.month.toString(10).padZeros(2);
        const hoursStr = this.hours.toString(10).padZeros(2);
        const minutesStr = this.minutes.toString(10).padZeros(2);
        const secondsStr = this.seconds.toString(10).padZeros(2);
        return `${this.year}-${monthStr}-${dayStr}T${hoursStr}:${minutesStr}:${secondsStr}Z`;
    }

    static fromIso8601Date(iso8601Date: string, timeZone: TimeZone): LocalDateTime {
        const date = new Date(iso8601Date);
        date.setUTCHours(date.getUTCHours() + timeZone.localOffset());
        return LocalDateTime.of(
            date.getUTCFullYear(),
            date.getUTCMonth() + 1,
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        );
    }

    static fromDDMMYYYHHMMString(dateString: string): LocalDateTime {
        const parts = dateString.split(' ');
        const dateParts = parts[0].split('/');
        const timeParts = parts[1].split(':');

        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        return LocalDateTime.of(year, month, day, hours, minutes);
    }

}
