import { ErrorMap } from '../../../_base/validation/ErrorMap';
import { FileInfo } from '../../../models/general/FileInfo';
import { Nullable } from '../../../_base/lang/Nullable';

export class InformPaymentVM {
    isLoading = false;
    date = '';
    amount = '';
    transactionNumber = '';
    receiptFile: Nullable<FileInfo> = null;
    errors: ErrorMap = {};
}
