import React from 'react';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const NoExpensesTitle: React.FC = () => {
    return <>
        <Title>No hay informaci&oacute;n en de expensas</Title>
    </>;
};

const Title = styled.p`
  font-size: ${styles.fonts.size.big};
  color: #5D7198;
  margin-top: 14px;
  margin-left: 14px;
`;
