import React from 'react';
import AppContext from '../../lib/AppContext';
import { SectionHeader } from '../../components/SectionHeader';
import styled from 'styled-components';
import openedEnvelope from './opened_envelope.png';
import closedEnvelope from './closed_envelope.png';
import clip from './clip.png';
import { Loader } from '../../components/Loader';
import { AnnouncementsVM } from './AnnouncementsVM';
import { AnnouncementsView } from './AnnouncementsPresenter';
import { AnnouncementDetail } from './AnnouncementDetail';
import { AnnouncementRow } from './AnnouncementRow';
import { styles } from '../../styles';
import { ScrollableList } from '../../components/ScrollableList';


export class AnnouncementsPage extends React.Component implements AnnouncementsView {
    private presenter = AppContext.presenters.announcements(this);

    state = {
        model: new AnnouncementsVM(),
        isRefreshing: false,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    componentWillUnmount() {
        this.presenter.stop();
    }

    modelChanged(model: AnnouncementsVM) {
        this.setState({ model });
    }

    renderAnnouncements() {
        let selectedAnnouncement = this.state.model.selectedAnnouncement;
        return <>
            {
                this.state.model.announcements.length > 0 &&
                <ScrollableList>
                    {this.state.model.announcements.map(announcement => (
                        <AnnouncementRow
                            key={announcement.id}
                            item={announcement}
                            icon={clip}
                            onClick={this.presenter.selectAnnouncement}
                            selected={selectedAnnouncement?.id === announcement.id}
                        />
                    ))}
                </ScrollableList> ||
                <NoAnnouncements>No hay comunicados</NoAnnouncements>
            }
        </>;
    }

    render() {
        const selectedAnnouncement = this.state.model.selectedAnnouncement;
        return (
            <Box>
                <Announcements>
                    <SectionHeader icon={closedEnvelope} title={'Bandeja de entrada'}/>
                    {this.state.model.isLoading ? <Loader/> : this.renderAnnouncements()}
                </Announcements>
                <Detail>
                    {
                        (this.state.model.announcements.length > 0 && selectedAnnouncement) &&
                        <>
                            <SectionHeader icon={openedEnvelope} title={'Mensaje'}/>
                            <AnnouncementDetail announcement={selectedAnnouncement}/>
                        </>
                    }
                </Detail>
            </Box>
        );
    }
}

const Box = styled.div`
  display: flex;
  height: 500px;
  flex-grow: 1;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Announcements = styled.li`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  padding: 20px 9px 20px 20px;
  border-right: 1px solid rgba(146, 162, 195, 0.5);
`;

const Detail = styled.div`
  width: 50%;
  padding: 20px;
`;

const NoAnnouncements = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;
