import { UsefulContactsVM } from './UsefulContactsVM';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { HtmlSanitizer } from '../../../infrastructure/htmlSanitizer/HtmlSanitizer';
import { GetUsefulContacts } from '../../../actions/usefulContacts/GetUsefulContacts';

export interface UsefulContactsView {
    modelChanged(model: UsefulContactsVM)
}

export class UsefulContactsPresenter {
    private model = new UsefulContactsVM();

    constructor(
        private view: UsefulContactsView,
        private actionBus: ActionBus,
        private sanitizer: HtmlSanitizer,
    ) {}

    async start() {
        await this.loadContent();
    }

    private async loadContent() {
        this.set({ isLoading: true });
        const content = await this.actionBus.query(new GetUsefulContacts());
        this.set({ content, isLoading: false });
        this.sanitizeContent();
    }

    private sanitizeContent() {
        if (this.model.content.length > 0) {
            this.model.content = this.sanitizer.sanitize(this.model.content);
            this.set({
                content: this.model.content,
            });
        }
    }

    private set<K extends keyof UsefulContactsVM>(changes: Pick<UsefulContactsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
