import { Command } from '../../../../_base/actionBus/Command';

export class BookAmenity extends Command {
    constructor(
        public amenityId: number,
        public date: string,
        public startAt: string,
        public durationInMinutes: number
    ) {
        super();
    }
}