import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { PasswordVM } from './PasswordVM';
import { handleValidationErrors } from '@eroman/common/src/_base/validation/catchValidationErrors';
import { ForgotPassword } from '@eroman/common/src/actions/auth/ForgotPassword';
import { ChangePassword } from '@eroman/common/src/actions/auth/ChangePassword';

export interface PasswordView {
    modelChanged(model: PasswordVM);
    showSuccessMessage();
}

export class PasswordPresenter {
    private model = new PasswordVM();

    constructor(private view: PasswordView, private actionBus: ActionBus, private navigation: WebNavigator) {}

    set<K extends keyof PasswordVM>(changes: Pick<PasswordVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    goBack(path: string) {
        this.navigation.navigateToPath(path);
    }

    async forgotPassword() {
        try {
            this.set({ isLoading: true });
            await this.actionBus.exec(new ForgotPassword(this.model.email));
            this.set({ isValidationCodeSent: true, isLoading: false });
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages(), isLoading: false }));
        }
    }

    async changePassword() {
        try {
            this.set({ isLoading: true });
            await this.actionBus.exec(new ChangePassword(this.model.validationCode, this.model.email, this.model.password, this.model.confirmationPassword));
            this.navigation.navigateToHome();
            this.view.showSuccessMessage();
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages(), isLoading: false }));
        }
    }
}
