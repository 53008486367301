import { DeviceInfo } from '@eroman/common/src/models/DeviceInfo';

export class WebDeviceInfo implements DeviceInfo {
    get platform(): string {
        return 'web';
    }
    get version(): string {
        return '1';
    }
}
