import { QueryHandler } from '../../../_base/actionBus/QueryHandler';
import { AmenitiesService } from '../../../models/amenities/AmenitiesService';
import { SearchDateAvailableSlots } from './SearchDateAvailableSlots';
import { Slot } from '../../../models/amenities/Slot';

export class SearchDateAvailableSlotsHandler implements QueryHandler<SearchDateAvailableSlots, Slot[]> {
    constructor(private amenitiesService: AmenitiesService) {}

    async handle(query: SearchDateAvailableSlots): Promise<Slot[]> {
        return this.amenitiesService.searchDateAvailableSlots(query.amenityId, query.date);
    }
}
