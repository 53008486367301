import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { WebNavigator } from '../../../lib/navigation/WebNavigator';
import { HeaderVM } from './HeaderVM';

export interface HeaderView {
    modelChanged(model: HeaderVM);
}

export class HeaderPresenter {
    constructor(private view: HeaderView, private session: SessionStorage, private navigator: WebNavigator) {}

    async showResetPasswordModal() {
        const session = await this.session.get();
        return session.suggestPasswordReset;
    }

    async dontShowResetPasswordModal() {
        const session = await this.session.get();
        session.suggestPasswordReset = false;
    }

    getTitle() {
        return this.navigator.currentRoute?.meta.title;
    }
}
