import { ValidationError } from './ValidationError';
import { ValidationsError } from './ValidationsError';
import { PropertyValidationError } from './PropertyValidationError';
import { GeneralValidationError } from './GeneralValidationError';
import { PropertyAssertions } from './PropertyAssertions';

export class Validator {
    private _errors: ValidationError[] = [];

    check(name: string, value: any): PropertyAssertions {
        return new PropertyAssertions(name, value, this);
    }

    addGeneralError(errorMessage: string): Validator {
        this._errors.push(new GeneralValidationError(errorMessage));
        return this;
    }

    addPropertyError(name: string, errorMessage: string): Validator {
        this._errors.push(new PropertyValidationError(name, errorMessage));
        return this;
    }

    hasErrors(): boolean {
        return this._errors.length > 0;
    }

    throwIfHasErrors() {
        if (this.hasErrors()) {
            this.throw();
        }
    }

    throw() {
        throw new ValidationsError(this._errors);
    }

    static throwGeneralError(errorMessage: string) {
        const validator = new Validator();
        validator.addGeneralError(errorMessage).throw();
    }

    static errorToValidationError(e: Error, mappings: ValidationErrorMapping[]) {
        for (const mapping of mappings) {
            if (e instanceof mapping.error) { Validator.throwGeneralError(mapping.message); }
        }
        throw e;
    }
}

interface ValidationErrorMapping {
    error: any,
    message: string
}
