import { Command } from '../../_base/actionBus/Command';

export class VotePoll extends Command {
    constructor(
        public pollId: number,
        public option: string
    ) {
        super();
    }
}
