import { DatesFormatter } from './DatesFormatter';
import { LocalDate } from '../../../_base/time/LocalDate';

export class FileNameFormatter {
    static formatFile(fileName: string, maxNameLength: number) {
        const [name, extension] = this.getNameAndExtension(fileName);
        if (name.length <= maxNameLength) return fileName;
        const truncatedFirst = this.truncateFirstPart(name, maxNameLength);
        const truncatedLast = this.truncateLastPart(name, maxNameLength);
        return truncatedFirst + '...' + truncatedLast + extension;
    }

    static formatAsImageFile(fileName: string): string {
        const [, extension] = this.getNameAndExtension(fileName);
        const date = DatesFormatter.fileDate(LocalDate.today());
        return `IMG-${date}${extension}`;
    }

    private static getNameAndExtension(fileName: string) {
        let lastDot = fileName.lastIndexOf('.');
        if (lastDot === -1) return [fileName, ''];
        const name = fileName.substr(0, lastDot);
        const extension = fileName.substr(lastDot);
        return [name, extension];
    }

    private static truncateFirstPart(name: string, maxNameLength: number) {
        const sizeWithoutEllipsis = maxNameLength - 3;
        const firstPartSize = Math.ceil(sizeWithoutEllipsis / 2.0);
        return name.substr(0, firstPartSize);
    }

    private static truncateLastPart(name: string, maxNameLength: number) {
        const sizeWithoutEllipsis = maxNameLength - 3;
        const firstPartSize = Math.ceil(sizeWithoutEllipsis / 2.0);
        const lastPartSize = sizeWithoutEllipsis - firstPartSize;
        return name.substr(name.length - lastPartSize);
    }

}
