import React from 'react';
import styled from 'styled-components';
import { BalanceTypes } from '@eroman/common/src/views/app/Expenses/ExpensesVM';
import { styles } from '../../../styles';

export const Balance: React.FC<Props> = (props) => {
    let balanceTitle = 'Saldo a pagar';
    let balanceColor = '';
    switch (props.type) {
        case BalanceTypes.Zero:
            balanceColor = '#42557B';
            break;
        case BalanceTypes.Negative:
            balanceColor = '#F1831D';
            break;
        case BalanceTypes.Positive:
            balanceColor = '#11AB8F';
            balanceTitle = 'Saldo a favor';
            break;
    }

    return (
        <Container>
            <Title color={balanceColor}>{balanceTitle}</Title>
            <Value color={balanceColor}>{props.amount}</Value>
        </Container>
    );
};

const Container = styled.div`
  padding: 12px 14px 7px;
  display: flex;
  align-items: center;
  background-color: #ECF0F5;
  margin-top: 7px;
  border-top: 1px solid #DDE4F0;
`;

const Title = styled.div.attrs<{ color: string }>({})`
  color: ${(props) => props.color};
  font-size: ${styles.fonts.size.big};
  font-weight: ${styles.fonts.weight.bold};
  flex-grow: 0;
`;

const Value = styled.div.attrs<{ color: string }>({})`
  font-size: 24px;
  font-weight: ${styles.fonts.weight.bold};
  color: ${(props) => props.color};
  text-align: right;
  flex-grow: 1;
`;

interface Props {
    type: BalanceTypes,
    amount: string;
}
