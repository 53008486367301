import { UserInfo } from '../auth/UserInfo';
import { Nullable } from '../../_base/lang/Nullable';
import { Unit } from '../units/Unit';
export class Session {
    private _user: Nullable<UserInfo> = null;
    private _currentUnitId: number = 0;
    private _isAuthenticated: boolean = false;
    private _suggestPasswordReset: boolean = false;

    get user(): Nullable<UserInfo> { return this._user; }

    set user(user: any) { this._user = user; }
    get unit(): Nullable<Unit> {
        if (this._user === null) return null;
        return this._user.units.firstOrNull(u => u.id === this._currentUnitId);
    }

    get isAuthenticated(): boolean { return this._isAuthenticated; }

    get currentUnitId(): number { return this._currentUnitId; }

    set currentUnitId(value: number) { this._currentUnitId = value; }

    get suggestPasswordReset(): boolean { return this._suggestPasswordReset; }

    set suggestPasswordReset(value: boolean) { this._suggestPasswordReset = value; }

    authenticate(user: UserInfo) {
        this.refreshUser(user);
        this._isAuthenticated = true;
    }

    loadUser(user: UserInfo) {
        this._user = user;
    }

    refreshUser(newUser: UserInfo) {
        this._user = newUser;
        this._currentUnitId = newUser.units.first().id;
    }

    logout() {
        this._user = null;
        this._currentUnitId = 0;
        this._isAuthenticated = false;
        this._suggestPasswordReset = false;
    }

    selectUnit(unitId: number) {
        if (!this._user?.units.any(u => u.id === unitId)) return;
        this._currentUnitId = unitId;
    }

    snapshot() {
        return {
            isAuthenticated: this._isAuthenticated,
            user: this._user,
            currentUnitId: this._currentUnitId,
            suggestPasswordReset: this._suggestPasswordReset,
        };
    }

    static fromSnapshot(snapshot): Session {
        const session = new Session();
        session._user = snapshot.user ? UserInfo.fromJson(snapshot.user) : null;
        session._currentUnitId = snapshot.currentUnitId;
        session._isAuthenticated = snapshot.isAuthenticated;
        session._suggestPasswordReset = snapshot.suggestPasswordReset;
        return session;
    }
}
