import { LocalDateTime } from '../../_base/time/LocalDateTime';
import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';
import { ClaimSubject } from './ClaimSubject';
import { FileInfo } from '../general/FileInfo';

export class Claim {
    constructor(
        public id: number,
        public subject: ClaimSubject,
        public description: string,
        public isClosed: boolean,
        public createdAt: LocalDateTime,
        public attachments: FileInfo[]
    ) {
    }

    toJson() {
        return {
            id: this.id,
            subjectId: this.subject.id,
            subjectTitle: this.subject.title,
            description: this.description,
            isClosed: this.isClosed,
            createdAt: this.createdAt.toISO8601String(),
            attachments: this.attachments,
        };
    }

    static fromJson(json: any, jsonDateSerializer: JsonDateSerializer) {
        return new Claim(
            json.id,
            new ClaimSubject(json.subjectId, json.subjectTitle),
            json.description,
            json.isClosed,
            jsonDateSerializer.fromIso8601(json.createdAt),
            json.attachments,
        );
    }
}
