import React from 'react';
import styled from 'styled-components';
import { BookingInfo } from '@eroman/common/src/views/app/Bookings/BookingsVM';
import { BookingStatus } from '@eroman/common/src/models/bookings/BookingStatus';
import { styles } from '../../styles';
import activeIcon from './activeIcon.png';
import canceledIcon from './canceledIcon.png';
import { LocalDate } from '@eroman/common/src/_base/time/LocalDate';

export const BookingDetail: React.FC<Props> = (props) => {
    let dateHasPassed = LocalDate.fromISO8601String(props.booking.date).toDate() < LocalDate.today().toDate();

    return (
        <Booking key={props.booking.id}>
            <div>
                {
                    (props.booking.status === BookingStatus.Active && !dateHasPassed) ?
                        <Icon src={activeIcon}  alt="activeIcon"/>
                        :
                        <Icon src={canceledIcon}  alt="activeIcon"/>
                }
                <span>{props.booking.date}</span> |
                <span>{props.booking.startAt}hs</span> |
                <span>Duración: {props.booking.durationInMinutes}min</span> |
                <span>${props.booking.fee}</span>
            </div>
            { props.booking.status === BookingStatus.Active && !dateHasPassed &&
                <Cancel onClick={async (e) => await props.onCancel(e, props.booking.id)}>Cancelar</Cancel>
            }
        </Booking>
    );
};

const Booking = styled.div`
  background-color: #F4F7FE;
  padding: 10px;
  margin-left: -10px;
  border-radius: 7px;
  display: flex;
  font-size: ${styles.fonts.size.small};
  font-weight: ${styles.fonts.weight.regular};
  color: #5F6B8C;
  justify-content: space-between;
  margin-bottom: 5px;
  
  div {
    display: flex;
  }
  
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const Cancel = styled.a`
  color: #1151AD;
  font-size: ${styles.fonts.size.small};
  text-decoration: underline #1151ad;
  cursor: pointer;
  justify-self: flex-end;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 15px;
  align-self: center;
`;

export interface Props {
    booking: BookingInfo;
    onCancel: (e: React.MouseEvent<HTMLAnchorElement>, bookingId: number) => void;
}
