import React from 'react';
import AppContext from '../../lib/AppContext';

export class UnitPage extends React.Component<any, any> {
    private presenter = AppContext.presenters.unit();

    async componentDidMount() {
        await this.presenter.start(window.location.pathname + window.location.search);
    }

    render() {
        return <></>;
    }
}
