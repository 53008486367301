import { LoginPresenter, LoginView } from '../public/Login/LoginPresenter';
import { WebNavigator } from './navigation/WebNavigator';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { ExpensesPresenter, ExpensesView } from '@eroman/common/src/views/app/Expenses/ExpensesPresenter';
import { EventBus } from '@eroman/common/src/_base/eventBus/EventBus';
import { PaymentsPresenter, PaymentsView } from '../app/Payments/PaymentsPresenter';
import { Clock } from '@eroman/common/src/_base/time/Clock';
import { CurrentUnitPresenter, CurrentUnitView } from '@eroman/common/src/views/components/CurrentUnit/CurrentUnitPresenter';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { AnnouncementsPresenter, AnnouncementsView } from '../app/Announcements/AnnouncementsPresenter';
import { FormFileFieldPresenter, FormFileFieldView } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldPresenter';
import { Config } from '../../infrastructure/Config';
import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { SafeHtmlSanitizer } from '@eroman/common/src/infrastructure/htmlSanitizer/SafeHtmlSanitizer';
import { MainMenuPresenter, MainMenuView } from '../app/Layout/MainMenu/MainMenuPresenter';
import { FeatureConfigClient } from '@eroman/common/src/lib/featureConfig/FeatureConfigClient';
import { ClaimsPresenter, ClaimsView } from '../app/Claims/ClaimsPresenter';
import { InformPaymentPresenter, InformPaymentView } from '@eroman/common/src/views/app/InformPayment/InformPaymentPresenter';
import { CreateClaimPresenter, CreateClaimView } from '@eroman/common/src/views/app/Claims/CreateClaim/CreateClaimPresenter';
import { ClaimCommentFormPresenter, ClaimCommentFormView } from '@eroman/common/src/views/app/Claims/ClaimCommentFormPresenter';
import { BookAmenityView } from '@eroman/common/src/views/app/BookAmenity/BookAmenityView';
import { BookAmenityPresenter } from '../app/BookAmenity/BookAmenityPresenter';
import { AmenitiesInfoView, AmenityInfoPresenter } from '../app/AmenityInfo/AmenityInfoPresenter';
import { DownloaderPresenter, DownloaderView } from '../components/downloader/DownloaderPresenter';
import { UnitPresenter } from '../app/Unit/UnitPresenter';
import { ManagerInfoPresenter, ManagerInfoView } from '@eroman/common/src/views/app/ManagerInfo/ManagerInfoPresenter';
import { UsefulContactsPresenter, UsefulContactsView } from '@eroman/common/src/views/app/UsefulContacts/UsefulContactsPresenter';
import { DocumentsPresenter, DocumentsView } from '@eroman/common/src/views/app/Documents/DocumentsPresenter';
import { AmenitiesPresenter, AmenitiesView } from '../app/Amenities/AmenitiesPresenter';
import { PublicAnnouncementPresenter, PublicAnnouncementView } from '../public/Announcement/PublicAnnouncementPresenter';
import { PasswordPresenter, PasswordView } from '../public/Password/PasswordPresenter';
import { ResetPasswordPresenter, ResetPasswordView } from '@eroman/common/src/views/app/Password/ResetPasswordPresenter';
import { HeaderPresenter, HeaderView } from '../app/Layout/Header/HeaderPresenter';
import { PollsPresenter, PollsView } from '../app/Polls/PollsPresenter';
import { ChatPresenter, ChatView } from '@eroman/common/src/views/app/Chat/ChatPresenter';

export class PresenterFactory {
    private downloadUrlBuilder = new DownloadUrlBuilder(Config.get('apiBaseUrl'));
    private sanitizer = new SafeHtmlSanitizer();

    constructor(
        private actionBus: ActionBus,
        private navigation: WebNavigator,
        private eventBus: EventBus,
        private clock: Clock,
        private sessionStorage: SessionStorage,
        private httpFeatureConfigClient: FeatureConfigClient,
    ) {
    }

    amenities(view: AmenitiesView) { return new AmenitiesPresenter(view, this.actionBus); }

    amenityInfo(view: AmenitiesInfoView) { return new AmenityInfoPresenter(view, this.actionBus); }

    announcements(view: AnnouncementsView) { return new AnnouncementsPresenter(view, this.actionBus, this.eventBus, this.navigation, this.sanitizer, this.downloadUrlBuilder); }

    bookAmenity(view: BookAmenityView) { return new BookAmenityPresenter(view, this.actionBus); }

    claims(view: ClaimsView) { return new ClaimsPresenter(view, this.actionBus, this.eventBus, this.navigation, this.downloadUrlBuilder); }

    claimCommentForm(view: ClaimCommentFormView) { return new ClaimCommentFormPresenter(view, this.actionBus, this.eventBus); }

    createClaim(view: CreateClaimView) { return new CreateClaimPresenter(view, this.actionBus, this.eventBus); }

    currentUnit(view: CurrentUnitView) { return new CurrentUnitPresenter(view, this.sessionStorage, this.actionBus); }

    documents(view: DocumentsView) { return new DocumentsPresenter(view, this.actionBus, this.downloadUrlBuilder); }

    expenses(view: ExpensesView) { return new ExpensesPresenter(view, this.actionBus, this.eventBus, this.downloadUrlBuilder, this.sessionStorage); }

    formFileField(view: FormFileFieldView) { return new FormFileFieldPresenter(view, this.actionBus); }

    informPayment(view: InformPaymentView) { return new InformPaymentPresenter(view, this.actionBus, this.eventBus, this.clock); }

    login(view: LoginView) { return new LoginPresenter(view, this.actionBus, this.navigation); }

    polls(view: PollsView) { return new PollsPresenter(view, this.actionBus, this.sessionStorage); }

    mainMenu(view: MainMenuView) { return new MainMenuPresenter(view, this.navigation, this.httpFeatureConfigClient, this.actionBus, this.sessionStorage); }

    header(view: HeaderView) { return new HeaderPresenter(view, this.sessionStorage, this.navigation); }

    managerInfo(view: ManagerInfoView) { return new ManagerInfoPresenter(view, this.actionBus); }

    payments(view: PaymentsView) { return new PaymentsPresenter(view, this.actionBus, this.sessionStorage, this.eventBus, this.navigation); }

    usefulPhoneNumbers(view: UsefulContactsView) { return new UsefulContactsPresenter(view, this.actionBus, this.sanitizer); }

    unit() { return new UnitPresenter(this.navigation, this.sessionStorage, this.actionBus); }

    downloader(view: DownloaderView) { return new DownloaderPresenter(view, this.navigation, this.downloadUrlBuilder); }

    publicAnnouncements(view: PublicAnnouncementView) { return new PublicAnnouncementPresenter(view, this.navigation, this.actionBus, this.downloadUrlBuilder); }

    password(view: PasswordView) { return new PasswordPresenter(view, this.actionBus, this.navigation); }

    resetPassword(view: ResetPasswordView) { return new ResetPasswordPresenter(view, this.actionBus); }

    chat(view: ChatView) { return new ChatPresenter(view, this.actionBus, this.sessionStorage); }
}
