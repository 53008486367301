import React from 'react';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { FormField } from '../FormField';
import { FormDateInput } from './FormDateInput';

export const FormDateField: React.FC<Props> = (props) => {
    return <FormField label={props.label} error={props.error} >
        <FormDateInput
            hasError={!!props.error}
            {...props}
        />
    </FormField>;
};

interface Props {
    label?: string;
    error?: Nullable<string>;
    value?: string;
    onChangeDate?: ((text: string) => void);
}
