import React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';

export const Info: React.FC<Props> = (props) => {
    return <Label>{props.label} <span>{props.text}</span></Label>;
};

const Label = styled.p`
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
  line-height: 20px;
  color: #5F6B8C;
  padding: 4px;
  
  span {
    font-weight: ${styles.fonts.weight.regular};
    font-size: ${styles.fonts.size.small};
    line-height: 20px;
    color: #8A96BA;
  }
`;


interface Props {
    label: string,
    text: string,
}
