import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';

export const ChatBotHeader = () => {
    return (
        <Header>
            <Container style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Avatar style={{ backgroundColor: '#4a73ff', color: '#f4f4f4' }} shape={'circle'} size={50}>PE</Avatar>
                <Container style={{ alignItems: 'baseline', flexDirection: 'column' }}>
                    <Name>PE Bot</Name>
                    <Status>online</Status>
                </Container>
            </Container>
        </Header>
    );
};

const Header = styled.div`
  background-color: #fff;
  font-weight: 700;
  font-size: 16px;
  height: 70px;
  border-bottom: 1px solid #cecdcd;
  display: flex;
  padding: 0 10px;
  align-items: center;
  grid-area: header;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Name = styled.span`
  margin-left: 5px;
  font-size: large;
  color: #8a96ba;
`;

const Status = styled.span`
  font-size: smaller;
  font-weight: 500;
  color: dodgerblue;
  cursor: pointer;
`;
