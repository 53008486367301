import React from 'react';
import { DownloaderView } from './DownloaderPresenter';
import AppContext from '../../lib/AppContext';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

export class Downloader extends React.Component implements DownloaderView {
    private presenter = AppContext.presenters.downloader(this);

    showErrorMessage(message: string) {
        toast.error(message);
    }

    download(url: string) {
        saveAs(url);
    }

    private getQueryParams(param: string) {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get(param);
    }

    async componentDidMount() {
        const file = decodeURIComponent(this.getQueryParams('file') || '');
        const name = decodeURIComponent(this.getQueryParams('name')|| '');
        await this.presenter.start(file, name);
    }

    render() {
        return <></>;
    }
}
