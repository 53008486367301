import barbecue from './barbecue.png';
import bathtub from './bathtub.png';
import cellar from './cellar.png';
import football from './football.png';
import grill from './grill.png';
import gym from './gym.png';
import laundry from './laundry.png';
import park from './park.png';
import pool from './pool.png';
import solarium from './solarium.png';
import sum from './sum.png';
import tennis from './tennis.png';
import terrace from './terrace.png';
import barbecueDisabled from './barbecue_disabled.png';
import bathtubDisabled from './bathtub_disabled.png';
import cellarDisabled from './cellar_disabled.png';
import footballDisabled from './football_disabled.png';
import grillDisabled from './grill_disabled.png';
import gymDisabled from './gym_disabled.png';
import laundryDisabled from './laundry_disabled.png';
import parkDisabled from './park_disabled.png';
import poolDisabled from './pool_disabled.png';
import solariumDisabled from './solarium_disabled.png';
import sumDisabled from './sum_disabled.png';
import tennisDisabled from './tennis_disabled.png';
import terraceDisabled from './terrace_disabled.png';

export const findIcon = (name, disabled = false) => {
    const images = {
        'barbecue': barbecue,
        'bathtub': bathtub,
        'cellar': cellar,
        'football': football,
        'grill': grill,
        'gym': gym,
        'laundry': laundry,
        'park': park,
        'pool': pool,
        'solarium': solarium,
        'sum': sum,
        'tennis': tennis,
        'terrace': terrace,
        'barbecue_disabled': barbecueDisabled,
        'bathtub_disabled': bathtubDisabled,
        'cellar_disabled': cellarDisabled,
        'football_disabled': footballDisabled,
        'grill_disabled': grillDisabled,
        'gym_disabled': gymDisabled,
        'laundry_disabled': laundryDisabled,
        'park_disabled': parkDisabled,
        'pool_disabled': poolDisabled,
        'solarium_disabled': solariumDisabled,
        'sum_disabled': sumDisabled,
        'tennis_disabled': tennisDisabled,
        'terrace_disabled': terraceDisabled,
    };
    return disabled ? images[`${name}_disabled`] : images[name];
};