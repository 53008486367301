import React from 'react';
import { FormField } from '../FormField';
import { FormItemPickerInput } from './FormItemPickerInput';

export const FormItemPickerField: React.FC<Props> = (props) => {
    return <FormField label={props.label} error={props.error}>
        <FormItemPickerInput
            options={props.options}
            hasError={!!props.error}
            onChangeSelected={props.onChangeSelected}
            value={props.value}
        />
    </FormField>;
};

interface Props {
    label: string;
    error?: string | null;
    options: { value: any, label: string }[];
    onChangeSelected: ((id: any) => void);
    value: any;
}
