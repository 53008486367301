// noinspection SpellCheckingInspection

export const styles = {
    textColor: '#696F79',
    borderColor: '#8692A6',
    primaryColor: '#178bff',
    primaryPressedColor: '#055DD4',
    pressedColor: '#0038BC',
    focusedColor: '#1565D8',
    errorColor: '#E15201',
    backgroundColor: '#F4F7FE',
    fonts: {
        dmSansBold: 'DMSans-Bold',
        dmSansMedium: 'DMSans-Medium',
        dmSansRegular: 'DMSans-Regular',
        nunitoRegular: 'Nunito-Regular',
        nunitoSemiBold: 'Nunito-SemiBold',
        nunitoBold: 'Nunito-Bold',
        default: 'Nunito, sans-serif',
        size: {
            xsmall: '12px',
            small: '14px',
            regular: '16px',
            big: '18px',
            xbig: '22px',
            xxbig: '28px',
        },
        weight: {
            regular: 500,
            bold: 600,
        },
    },
};
