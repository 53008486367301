import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { toast } from 'react-toastify';
import AppContext from '../../lib/AppContext';
import { ClaimCommentFormView } from '@eroman/common/src/views/app/Claims/ClaimCommentFormPresenter';
import { FormTextAreaField } from '../../components/form/FormTextArea/FormTextAreaField';
import { ClaimCommentVM } from '@eroman/common/src/views/app/Claims/ClaimCommentVM';
import { UploadedFile } from '../CreateClaim/UploadedFile';
import { FormFileField } from '../../components/form/FormFileField/FormFileField';
import { FormFileFieldStates } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldVM';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';

export class ClaimCommentForm extends React.Component<Props, any> implements ClaimCommentFormView {
    private presenter = AppContext.presenters.claimCommentForm(this);

    state = {
        model: new ClaimCommentVM(),
    };

    showSuccessMessage() {
        toast.success('Comentario enviado con éxito');
    }

    onAttachmentStateChanged(state) {
        this.setState({ canSubmit: state !== FormFileFieldStates.Uploading });
    }

    onChangeFile(model: Nullable<FileInfo>) {
        this.presenter.addAttachment(model);
    }

    modelChanged(model: ClaimCommentVM) {
        this.setState({ model });
    }

    render() {
        return(
            <>
                <FormTextAreaField
                    error={this.state.model.errors['message']}
                    value={this.state.model.message}
                    onChangeText={message => this.presenter.set({ message })}
                    className={'small'}
                    placeholder={'Escriba su comentario'}
                />
                {
                    this.state.model.attachments.map(attachment => (
                        <_UploadedFile
                            key={attachment.name}
                            attachment={attachment}
                            unSelectFile={name => this.presenter.removeAttachment(name)}
                        />
                    ))
                }
                <FormFileField
                    key={this.state.model.attachments.length}
                    className="large"
                    label="Adjunto"
                    onChangeFile={attachment => this.onChangeFile(attachment)}
                    onChangeState={state => this.onAttachmentStateChanged(state)}
                    error={this.state.model.errors['attachments']}
                />
                <_PrimaryButton onClick={() => this.presenter.submit(this.props.claimId)}>Enviar</_PrimaryButton>
            </>
        );
    }
}

const _PrimaryButton = styled(PrimaryButton)`
  width: 90px;
  height: 25px;
  line-height: 16px;
  padding: 5px 28px 5px;
  margin-bottom: 20px;
`;

const _UploadedFile = styled(UploadedFile)`
    margin-top: 15px;
`;

interface Props {
    error?: string | null;
    claimId: number | null;
}
