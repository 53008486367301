import { DocumentInfo, DocumentsVM } from '@eroman/common/src/views/app/Documents/DocumentsVM';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetDocuments } from '@eroman/common/src/actions/documents/GetDocuments';
import { DatesFormatter } from '@eroman/common/src/views/lib/formatters/DatesFormatter';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { Document } from '@eroman/common/src/models/documents/Document';
import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { VirtualFileTypes } from '@eroman/common/src/models/documents/VirtualFileTypes';

export interface DocumentsView {
    modelChanged(model: DocumentsVM);
}

export class DocumentsPresenter {
    private model = new DocumentsVM();
    private breadcrumbs: DocumentInfo[] = [];

    constructor(
        private view: DocumentsView,
        private actionBus: ActionBus,
        private downloadUrlBuilder: DownloadUrlBuilder,
    ) {}

    async start() {
        await this.loadDocuments();
    }

    async loadDocuments(id: Nullable<number> = null) {
        this.set({ isLoading: true });
        const documents = await this.actionBus.query(new GetDocuments(id));
        this.set({ documents: documents.map(document => this.toDocumentInfo(document)), isLoading: false });
    }

    toDocumentInfo(document: Document): DocumentInfo {
        return {
            id: document.id,
            parentId: document.parentId,
            name: document.name,
            physicalPath: document.physicalPath,
            type: document.type,
            url: this.generateUrl(document),
            createdAt: DatesFormatter.fullDateTimeWithoutSeconds(document.createdAt),
            updatedAt: DatesFormatter.fullDateTimeWithoutSeconds(document.updatedAt),
        };
    }

    private generateUrl(document: Document) {
        return document.type === VirtualFileTypes.Regular ?
            this.downloadUrlBuilder.buildFor({ name: document.physicalPath!, label: document.name })
            : null;
    }

    async openFolder(document: DocumentInfo) {
        this.breadcrumbs.push(document);
        this.set({ breadcrumbs: this.breadcrumbs, isLoading: true });
        const documents = await this.actionBus.query(new GetDocuments(document.id));
        this.set({ documents: documents.map(d => this.toDocumentInfo(d)), isLoading: false });
    }

    async goBackInFileSystem() {
        this.set({ isLoading: true });
        let parentId: Nullable<number> = null;
        if (this.breadcrumbs.isNotEmpty()) { parentId = this.breadcrumbs.pop()!!.parentId; }
        const documents = await this.actionBus.query(new GetDocuments(parentId));
        this.set({ breadcrumbs: this.breadcrumbs, documents: documents.map(d => this.toDocumentInfo(d)), isLoading: false });
    }

    set<K extends keyof DocumentsVM>(changes: Pick<DocumentsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
