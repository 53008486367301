import React from 'react';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import styled from 'styled-components';

export const NoClaims: React.FC<Prop> = (props) => {
    return <Container>
        <div>Aún no hay reclamos registrados</div>
        <PrimaryButton onClick={() => props.toggleModal()}>
            Nuevo reclamo
        </PrimaryButton>
    </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 35px auto;
  align-items: center;
`;

interface Prop {
    toggleModal: () => void,
}
