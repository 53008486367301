import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetPublicAnnouncement } from './GetPublicAnnouncement';
import { PublicAnnouncement } from '../../models/publicAnnouncements/PublicAnnouncement';
import { PublicAnnouncementService } from '../../models/publicAnnouncements/PublicAnnouncementService';

export class GetPublicAnnouncementHandler implements QueryHandler<GetPublicAnnouncement, PublicAnnouncement> {
    constructor(private service: PublicAnnouncementService) {}

    async handle(query: GetPublicAnnouncement): Promise<PublicAnnouncement> {
        return this.service.getAnnouncement(query.token);
    }
}
