import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { VotePoll } from './VotePoll';
import { PollService } from '../../models/polls/PollService';
import { SessionStorage } from '../../models/session/SessionStorage';

export class VotePollHandler  implements CommandHandler<VotePoll> {
    constructor(private sessionStorage: SessionStorage, private pollService: PollService) {}

    async handle(command: VotePoll): Promise<void> {
        const session = await this.sessionStorage.get();
        await this.pollService.vote(command.pollId, command.option, session.unit!!.id);
    }
}


