import React from 'react';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { SectionHeader } from '../../../../components/SectionHeader';
import icon from '../BankTransfer/bank_transfer_icon.png';
import styled from 'styled-components';
import { styles } from '../../../../styles';
import { CopyableInfo } from '../BankTransfer/CopyableInfo';

export const CVUTransfer: React.FC<Props> = (props) => {
    return (
        <>
            <HeaderContainer>
                <SectionHeader icon={icon} title="Pagar con transferencia inmediata" color="#1151AD"/>
            </HeaderContainer>
            <Description>
                Copia el CVU para hacer el pago de tus expensas por transferencia.
                <br/>
                <span> Usando este método no necesitás informar el pago.</span>
            </Description>
            <CopyableInfo title="CVU" info={props.CVU!}/>
        </>
    );
};

const HeaderContainer = styled.div`
  margin: 20px 20px 0;
`;

const Description = styled.p`
  margin: 0 60px;
  font-size: ${styles.fonts.size.regular};
  font-weight: ${styles.fonts.weight.regular};
  
  span {
    font-weight: 400;
  }
`;

interface Props {
    CVU: Nullable<string>
}
