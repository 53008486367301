import React from 'react';
import styled from 'styled-components';

export const ScrollableList: React.FC<Props> = (props) => {
    return <List className={props.className && props.className}>
        {props.children}
    </List>;
};

// noinspection CssUnknownProperty

const List = styled.ul`
  flex-grow: 1;
  margin-top: 25px !important;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #D9E4FF transparent;
  padding: 0 10px !important;
  height: 400px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D9E4FF;
    margin-left: 50px;
  }
`;

interface Props {
    className?: string;
}
