import { TimeZone } from './TimeZone';
import { LocalDateTime } from './LocalDateTime';

export class JsonDateSerializer {

    constructor(private timeZone: TimeZone) {
    }

    fromIso8601(date: string): LocalDateTime {
        return LocalDateTime.fromIso8601Date(date, this.timeZone);
    }
}