import { Command } from '../../_base/actionBus/Command';
import { FileInfo } from '../../models/general/FileInfo';
import { Nullable } from '../../_base/lang/Nullable';

export class InformPayment extends Command {
    constructor(
        public date: string,
        public amount: string,
        public transactionNumber: string,
        public receiptFile: Nullable<FileInfo>,
    ) {
        super();
    }
}
