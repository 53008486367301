import React from 'react';
import styled from 'styled-components';
import { DetailField } from './DetailField';
import { HalfDetailField } from './HalfDetailField';
import { PaymentStatuses } from '@eroman/common/src/models/payments/PaymentStatuses';
import { PaymentDetailVM } from '../PaymentsVM';
import { PaymentSources } from '@eroman/common/src/models/payments/PaymentSources';

export const PaymentInfo: React.FC<Props> = (props) => {
    let textColor = '#1565D8';
    let statusText = '';
    switch (props.item.status) {
        case PaymentStatuses.Informed:
            textColor = '#F2C94C';
            statusText = 'Informado';
            break;
        case PaymentStatuses.Confirmed:
        case PaymentStatuses.Processed:
            textColor = '#4D556F';
            statusText = 'Aprobado';
            break;
        case PaymentStatuses.Rejected:
            textColor = '#DB640F';
            statusText = 'Rechazado';
            break;
    }
    let paymentSource = '';
    switch (props.item.source) {
        case PaymentSources.BankTransfer:
            paymentSource = 'Transferencia bancaria';
            break;
        case PaymentSources.Redepagos:
            paymentSource = 'Redepagos';
            break;
        case PaymentSources.Cash:
            paymentSource = 'Efectivo';
            break;
        case PaymentSources.Check:
            paymentSource = 'Cheque';
            break;
        case PaymentSources.Unknown:
            paymentSource = 'Desconocido';
            break;
        case PaymentSources.Other:
            paymentSource = 'Otro';
            break;
    }


    return (
        <Container>
            <Row>
                <DetailField title="Período" value={props.item.period ?? 'No confirmado'}/>
            </Row>
            <Row>
                <DetailField title="Importe" isAmount={true} value={props.item.amount}/>
            </Row>
            <TwoColumns>
                <HalfDetailField title="Fecha" value={props.item.date}/>
                <HalfDetailField title="Estado" value={statusText} color={textColor}/>
            </TwoColumns>
            <Row>
                <DetailField title="Medio de pago" value={paymentSource}/>
            </Row>
            {props.item.transactionNumber && (
                <Row>
                    <DetailField title="Número de transacción" value={props.item.transactionNumber}/>
                </Row>
            )}
            {([PaymentStatuses.Confirmed, PaymentStatuses.Processed].includes(props.item.status) && props.item.period) &&
                <ButtonContainer>
                    <Label href={props.url} target={'_blank'}>Descargar recibo de pago</Label>
                </ButtonContainer>
            }
        </Container>
    );
};

const Container = styled.div`
  padding: 25px 42px;
`;

const Row = styled.div`
  margin-bottom: 23px;
`;

const TwoColumns = styled.div`
  margin-bottom: 23px;
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled(Row)`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Label = styled.a`
  border: solid;
  border-radius: 30px;
  padding: 10px 13px;
  background-color: dodgerblue;
  color: white;
  text-decoration: none;
`;

interface Props {
    item: PaymentDetailVM,
    url: string;
}
