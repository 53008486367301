import classNames from 'classnames';
import React from 'react';
import { SlotInfo } from '@eroman/common/src/views/app/BookAmenity/BookAmenityVM';
import styled from 'styled-components';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';

export const Slot: React.FC<Props> = (props) => {
    return <Container
        onClick={() => props.onClick(props.slot)}
        className={classNames({
            selected: props.selectedSlot?.startAt === props.slot.startAt && props.selectedSlot?.isSelected,
        })}
    >
        {props.slot.startAt}
    </Container>;
};

const Container = styled.div`
  background-color: #178BFF;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0050BC;
  }

  &.selected {
    background-color: #0050BC;
  }
`;

export interface Props {
    slot: SlotInfo;
    selectedSlot: Nullable<SlotInfo>;
    onClick: (slot) => void;
}
