import { DocumentService } from '../../models/documents/DocumentService';
import { HttpClient } from '../../_base/http/HttpClient';
import { Nullable } from '../../_base/lang/Nullable';
import { Document } from '../../models/documents/Document';
import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';

export class HttpDocumentService implements DocumentService {
    constructor(private httpClient: HttpClient, private jsonDateSerializer: JsonDateSerializer) {}

    async getDocuments(associationId: number, parentId: Nullable<number> = null): Promise<Document[]> {
        const searchParams = parentId ? `associationId=${associationId}&parentId=${parentId}` : `associationId=${associationId}`;
        const response =  await this.httpClient.get(`/documents?${searchParams}`);
        return response.body.documents.map(document => Document.fromJson(document, this.jsonDateSerializer));
    }
}
