import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { Validator } from '../../_base/validation/Validator';
import { AuthService } from '../../models/auth/AuthService';
import { ForgotPassword } from './ForgotPassword';
import { UserNotFoundError } from '../../models/auth/UserNotFoundError';

export class ForgotPasswordHandler implements CommandHandler<ForgotPassword> {
    constructor(private authService: AuthService) {}

    async handle(command: ForgotPassword): Promise<void> {
        this.validate(command);
        await this.resetPassword(command);
    }

    private async resetPassword(command: ForgotPassword) {
        try {
            await this.authService.forgotPassword(command.email);
        } catch (e: any) {
            Validator.errorToValidationError(e, [
                { error: UserNotFoundError, message: 'No existe ninguna cuenta con ese email' },
            ]);
        }
    }

    private validate(command: ForgotPassword) {
        const validator = new Validator();
        validator.check('email', command.email)
            .notNullOrBlank('No puede estar vacio')
            .email('Email no valido');
        validator.throwIfHasErrors();
    }
}
