import { Poll, PollStates } from '@eroman/common/src/models/polls/Poll';
import { Nullable } from '../../../_base/lang/Nullable';
import { DatesFormatter } from '../../lib/formatters/DatesFormatter';

export class PollsVM {
    polls: PollVM[] = [];
    selectedPoll: Nullable<PollVM> = null;
    votedOption: Nullable<string> = null;
    isLoading: boolean = false;
}

export class PollVM {
    id: number = 0;
    title: string = '';
    description: string = '';
    status: PollStates = PollStates.ONGOING;
    options: OptionVM[] = [];
    endDate: string = '';
    total: number = 0;
    selectedOption: Nullable<OptionVM> = null;
    unitHasAlreadyVoted: string|null = null;

    static fromPoll(poll: Poll, unitId: number): PollVM {
        let pollDetail = new PollVM();
        pollDetail.id = poll.id;
        pollDetail.title = poll.title;
        pollDetail.description = poll.description;
        pollDetail.status = poll.status;
        pollDetail.total = poll.getTotalVotes();
        pollDetail.endDate = DatesFormatter.fullDateTimeWithoutSeconds(poll.endDate);

        let options_: OptionVM[] = [];
        let foundUnitId: string|null = null;
        let totalPercentage = 0;

        Array.from(poll.options.entries()).forEach(([key, values]) => {
            if (values.includes(unitId)) foundUnitId = key;
            let option = new OptionVM(key, values.length);
            option.setPercentage(pollDetail.total);
            totalPercentage += option.percentage;
            options_.push(option);
        });

        // Adjust the percentages to ensure they sum up to 100%
        let remainder = 100 - totalPercentage;
        if (remainder !== 0) {
            // Adjust the option with the highest votes by the remainder
            let maxVotesOption = options_.reduce((prev, current) =>
                (current.votes > prev.votes ? current : prev), options_[0]);
            maxVotesOption.percentage += remainder;
        }

        pollDetail.options = options_;
        pollDetail.unitHasAlreadyVoted = foundUnitId;
        return pollDetail;
    }
}

export class OptionVM {
    percentage: number = 0;

    constructor(public label: string, public votes: number = 0) {}

    setPercentage(totalVotes: number) {
        if (totalVotes > 0) {
            this.percentage = Math.round( (this.votes / totalVotes) * 100 );
        }
    }
}



