import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { BookAmenityVM, SlotInfo } from '@eroman/common/src/views/app/BookAmenity/BookAmenityVM';
import { AmenityInfo } from '@eroman/common/src/views/app/Amenities/AmenitiesVM';
import { Slot } from '@eroman/common/src/models/amenities/Slot';
import { BookAmenityView } from '@eroman/common/src/views/app/BookAmenity/BookAmenityView';
import { GetAvailableBookingDays } from '@eroman/common/src/actions/amenities/getAvailableBookingDays/GetAvailableBookingDays';
import { SearchDateAvailableSlots } from '@eroman/common/src/actions/amenities/search/SearchDateAvailableSlots';
import { BookAmenity } from '@eroman/common/src/actions/amenities/booking/book/BookAmenity';
import { MaxWeekdayBookingsReachedError } from '@eroman/common/src/models/bookings/MaxWeekdayBookingsReachedError';
import { MaxWeekendBookingsReachedError } from '@eroman/common/src/models/bookings/MaxWeekendBookingsReachedError';

export class BookAmenityPresenter {
    private model = new BookAmenityVM();

    constructor(private view: BookAmenityView, private actionBus: ActionBus) {}

    async start() {
        this.set({ isLoading: true });
        const dates = await this.actionBus.query(new GetAvailableBookingDays(this.model.amenity.id));
        this.set({ dates: dates.map(date => date.toISO8601String()), isLoading: false });
    }

    toSelectedAmenityInfo(amenity: AmenityInfo) {
        return { id: amenity.id, name: amenity.name };
    }

    set<K extends keyof BookAmenityVM>(changes: Pick<BookAmenityVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async loadSlots(selectedDate: string) {
        this.set({ selectedDate: selectedDate });
        const slots = await this.actionBus.query(new SearchDateAvailableSlots(this.model.amenity.id, selectedDate));
        this.set({ slots: slots.map(s => this.toSlotInfo(s)) });
    }

    private toSlotInfo(slot: Slot): SlotInfo {
        return {
            startAt: slot.startAt,
            durationInMinutes: slot.durationInMinutes,
            fee: slot.fee.toString(),
            isSelected: false,
        };
    }

    selectSlot(slot: SlotInfo) {
        this.set({
            selectedSlot: {
                startAt: slot.startAt,
                durationInMinutes: slot.durationInMinutes,
                fee: slot.fee,
                isSelected: true,
            },
        });
    }

    async book() {
        try {
            const command = new BookAmenity(
                this.model.amenity.id,
                this.model.selectedDate!!,
                this.model.selectedSlot!!.startAt,
                this.model.selectedSlot!!.durationInMinutes
            );
            await this.actionBus.exec(command);
            this.view.showSuccessMessage();
        } catch (e: any) {
            if (e instanceof MaxWeekdayBookingsReachedError) {
                this.view.showErrorMessage('Ha alcanzado el número máximo mensual de reservas en días de la semana');
            } else if (e instanceof MaxWeekendBookingsReachedError) {
                this.view.showErrorMessage('Ha alcanzado el número máximo mensual de reservas en fines de semana');
            } else {
                this.view.showErrorMessage(e.message);
            }
        } finally {
            this.set({ isLoading: false });
        }
    }
}
