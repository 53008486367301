import React from 'react';
import { LoginView } from './LoginPresenter';
import AppContext from '../../lib/AppContext';
import { LoginVM } from './LoginVM';
import styled from 'styled-components';
import stainImage from './top_stain.png';
import loginImage from './login_image.png';
import { Loader } from '../../components/Loader';
import { FormGeneralError } from '../../components/form/FormGeneralError';
import { FormField } from '../../components/form/FormField';
import { FormTextInput } from '../../components/form/FormText/FormTextInput';
import { styles } from '../../styles';
import { PasswordField } from '../../components/form/Password/PasswordField';

export class LoginPage extends React.Component<any, State> implements LoginView {
    private presenter = AppContext.presenters.login(this);

    state = {
        model: new LoginVM(),
    };

    modelChanged(model: LoginVM) {
        this.setState({ model });
    }

    onLoginClick(e) {
        const nextRoute = this.getQueryParams('next');
        e.preventDefault();
        return this.presenter.login(null, nextRoute);
    }

    private async onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            const nextRoute = this.getQueryParams('next');
            e.preventDefault();
            await this.presenter.login(null, nextRoute);
        }
    }

    async componentDidMount() {
        let [ nextPath, nextQuery ] = this.getQueryParams('next').split('?');
        let nextParams = new URLSearchParams(nextQuery);
        const token = this.extractToken(nextParams);
        if (token) await this.presenter.login(token, this.generateNextRoute(nextPath, nextParams));
    }

    private generateNextRoute(nextRouteSearch: string, params: URLSearchParams) {
        return decodeURIComponent(nextRouteSearch + '?' + params.toString());
    }

    private extractToken(params: URLSearchParams) {
        let token = params.get('__auth__') || null;
        params.delete('__auth__');
        return token;
    }

    private getQueryParams(param: string) {
        const queryParams = new URLSearchParams(window.location.search);
        return decodeURIComponent(queryParams.get(param) || '');
    }

    render() {
        return (
            <Container>
                <InnerContainer>
                    <LoginImage src={loginImage} alt="Portal Expensas"/>
                    <LoginForm>
                        {this.state.model.isLoading ? (
                            <Loader/>
                        ) : (
                            <>
                                <_FormGeneralError error={this.state.model.errors['']}/>
                                <_FormField
                                    label="Email:"
                                    error={this.state.model.errors['email']}
                                >
                                    <_FormTextInput
                                        value={this.state.model.email}
                                        onChangeText={(email) => this.presenter.set({ email })}
                                        onKeyDown={e => this.onInputKeyDown(e)}
                                        hasError={!!this.state.model.errors['email']}
                                        autocomplete={'off'}
                                    />
                                </_FormField>
                                <_FormField
                                    label="Contraseña:"
                                    error={this.state.model.errors['password']}
                                >
                                    <PasswordField
                                        value={this.state.model.password}
                                        onChangeText={(password) => this.presenter.set({ password })}
                                        onKeyDown={e => this.onInputKeyDown(e)}
                                        hasError={!!this.state.model.errors['password']}
                                        placeHolder={'Ingresá tu contraseña'}/>
                                </_FormField>
                                <PasswordHelper href="#" onClick={() => this.presenter.navigateToPassword()}>No s&eacute; mi contraseña</PasswordHelper>
                                <PrimaryButton href="#" onClick={this.onLoginClick.bind(this)}>Ingresar</PrimaryButton>
                            </>
                        )}
                    </LoginForm>
                </InnerContainer>
            </Container>
        );
    }
}

const _FormTextInput = styled(FormTextInput)`
  font-size: ${styles.fonts.size.small};
  padding: 15px;
  border-radius: 6px;
`;

const Container = styled.div`
  background-color: #178BFF;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 810px;
  padding: 100px 60px 90px;
  background: #FFFFFF url(${stainImage}) no-repeat left top;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
`;

const LoginImage = styled.img`
  margin-top: 7px;
  flex-shrink: 0;
`;

const LoginForm = styled.div`
  width: 340px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const _FormField = styled(FormField)`
    label {
      font-size: ${styles.fonts.size.regular};
      margin-bottom: 4px;
    }
`;

const PrimaryButton = styled.a`
  display: inline-block;
  font-size: ${styles.fonts.size.big};
  font-weight: ${styles.fonts.weight.regular};
  color: #FFFFFF;
  padding: 10px 0;
  margin-top: 10px;
  text-align: center;
  background-color: #178BFF;
  border-radius: 80px;
  text-decoration: none;

  &:hover {
    background-color: #055DD4;
  }
`;

const _FormGeneralError = styled(FormGeneralError)`
  font-size: ${styles.fonts.size.big};
`;

const PasswordHelper = styled.a`
  color: #696f79;
  margin-top: -15px;
  margin-bottom: 30px;
  size: 13px;
`;

interface State {
    model: LoginVM;
}
