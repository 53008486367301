import React from 'react';
import { ExpenseInfoVM, PaymentInfoVM } from '@eroman/common/src/views/app/Expenses/ExpensesVM';
import { MonthHeader } from './MonthHeader/MonthHeader';
import { MonthDetailRow } from './MonthDetailRow';
import { Balance } from './Balance';

export const PaymentsInfo: React.FC<Props> = (props) => (
    <>
        <MonthHeader expenseDownloadUrl={props.expense.downloadUrl} month={props.expense?.period} />
        <MonthDetailRow title={`${props.info.dueDateNumberToPay} vto. ${props.info.dueDate}`} value={props.info.amount} />
        {props.info.payments.map(payment =>
            <MonthDetailRow
                key={payment.id}
                title={`Tu pago del ${payment.date}`}
                value={payment.amount}
                titleColor="#8A96BA"
                valueColor="#8A96BA"
            />
        )}
        <Balance type={props.info.balanceType} amount={props.info.balance}/>
    </>
);

interface Props {
    info: PaymentInfoVM;
    expense: ExpenseInfoVM;
}
