import React from 'react';
import arrow from './arrow.png';
import styled from 'styled-components';
import classNames from 'classnames';
import { AnnouncementDetailVM } from './AnnouncementsVM';
import { styles } from '../../styles';

export const AnnouncementRow: React.FC<Props> = (props) => {
    return <>
        <Container
            className={classNames(props.className, { selected: props.selected })}
            onClick={() => props.onClick(props.item.id)}
        >
            <Header>
                <Date>{props.item.date}</Date>
                {props.item.attachments.length > 0 && <Img src={props.icon} alt="Adjunto"/>}
                <img src={arrow} alt="Ver comunicado"/>
            </Header>
            <Title>{props.item.title}</Title>
        </Container>
    </>;
};

const Date = styled.p`
  font-size: ${styles.fonts.size.regular};
  line-height: 21px;
`;

const Img = styled.img``;

const Header = styled.div`
  display: flex;
  align-items: center;

  ${Date} {
    flex-grow: 1;
    font-size: ${styles.fonts.size.regular};
    line-height: 21px;
    color: #8A96BA;
  }

  img {
    visibility: hidden;
    width: 5px;
    height: 9px;
  }

  ${Img} {
    width: 9px;
    visibility: visible;
    height: 15px;
    margin-right: 9px;
  }
`;

const Container = styled.div`
  padding: 15px;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover, &.selected {
    background-color: #F4F7FE;

    ${Header} img {
      visibility: visible;
    }
  }
`;

const Title = styled.p`
  color: #5F6B8C;
  font-size: ${styles.fonts.size.small};
  line-height: 18px;
  font-weight: ${styles.fonts.weight.regular};
`;

interface Props {
    item: AnnouncementDetailVM;
    onClick: (id: number) => void;
    selected: boolean;
    className?: string;
    icon: string;
}
