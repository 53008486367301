import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../../styles';

export const DetailField: React.FC<Props> = (props) => (
    <>
        <Title>{props.title}</Title>
        <Value className={classNames(props.className, { amount: props.isAmount })}>{props.value}</Value>
    </>
);

const Title = styled.p`
  font-family: "Nunito", sans-serif;
  font-size: ${styles.fonts.size.small};
  font-weight: ${styles.fonts.weight.bold};
  color: #8A96BA;
  line-height: 18px;
`;

const Value = styled.p`
  color: #4D556F;
  font-size: ${styles.fonts.size.big};
  font-weight: ${styles.fonts.weight.regular};
  
  &.amount {
    font-size: 22px;
  }
`;

interface Props {
    title: string,
    value: string,
    className?: string,
    isAmount?: boolean,
}
