import React from 'react';
import { AnnouncementDetailVM } from './AnnouncementsVM';
import { AttachmentList } from '../../components/attachmentList/AttachmentList';
import { ScrollableList } from '../../components/ScrollableList';
import { HtmlContent } from '../../components/HtmlContent';

export const AnnouncementDetail: React.FC<Props> = (props) => {
    return <ScrollableList>
        <HtmlContent content={props.announcement.content}/>
        <AttachmentList attachments={props.announcement.attachments} />
    </ScrollableList>;
};

interface Props {
    announcement: AnnouncementDetailVM;
}
