import React from 'react';
import { DownloadApplicationsPage } from './DownloadApplicationsPage';
import * as platformDetector from 'react-device-detect';

export class PlatformGate extends React.Component {
    render() {
        if (!platformDetector.isMobile) return <>{this.props.children}</>;
        return <DownloadApplicationsPage isIOS={platformDetector.isIOS}>{this.props.children}</DownloadApplicationsPage>;
    }
}
