import React, { HTMLAttributeAnchorTarget } from 'react';
import styled from 'styled-components';
import arrowRightImage from './arrow_right.png';
import classNames from 'classnames';

export const CircularArrowButton: React.FC<Props> = (props) => (
    <RoundButton {...props} className={classNames(props.className, { disabled: props.isDisabled })}>
        <img src={arrowRightImage} />
    </RoundButton>
);

const RoundButton = styled.a.attrs<{ color: string }>({})`
  background-color: ${props => props.color};
  border-radius: 100%;
  height: 36px;
  padding: 10px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #5D7198;
  }

  &.disabled {
    background-color: #cccccc;
    cursor: auto;
  }
`;

interface Props {
    color: string;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    className?: string;
    isDisabled?: boolean;
}
