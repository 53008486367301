import { Nullable } from '../../../_base/lang/Nullable';

export class AmenitiesVM {
    amenities: AmenityInfo[] = [];
    disabledAmenities: number[] = [];
    isLoading = false;
    selectedAmenity: Nullable<AmenityInfo> = null;
}

export interface AmenityInfo {
    id: number;
    name: string;
    general: string;
    availability: string;
    slots: string;
    iconPath: string;
}
