import React, { KeyboardEventHandler } from 'react';
import { FormTextInput } from './FormTextInput';
import { FormField } from '../FormField';

export const FormTextField: React.FC<Props> = (props) => {
    return <FormField label={props.label} error={props.error}>
        <FormTextInput
            hasError={!!props.error}
            {...props}
        />
    </FormField>;
};

interface Props {
    label?: string;
    error?: string|null;
    value?: string;
    isPassword?: boolean;
    onChangeText?: ((text: string) => void);
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}
