import { Nullable } from '../../_base/lang/Nullable';

export class Address {
    constructor(
        public street: string,
        public city: string,
        public countryCode: string,
        public zipCode: Nullable<string> = null,
    ) {}

    static fromJson(json: any): Address {
        return new Address(json.street, json.city, json.countryCode, json.zipCode);
    }
}
