import React from 'react';
import styled from 'styled-components';
import AppContext from '../../lib/AppContext';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { toast } from 'react-toastify';
import { FormGeneralError } from '../form/FormGeneralError';
import { ResetPasswordView } from '@eroman/common/src/views/app/Password/ResetPasswordPresenter';
import { ResetPasswordVM } from '@eroman/common/src/views/app/Password/ResetPasswordVM';
import { PasswordField } from '../form/Password/PasswordField';
import { FormField } from '../form/FormField';
import { styles } from '../../styles';
import { LinkButton } from '../buttons/LinkButton';

export class ResetPasswordModal extends React.Component<Props, State> implements ResetPasswordView {
    private presenter = AppContext.presenters.resetPassword(this);

    state = {
        model: new ResetPasswordVM(),
    };

    showSuccessMessage() {
        this.props.closeModal();
        toast.success('Contraseña cambiada con éxito');
    }

    async cancel() {
        if (this.state.model.dontSuggestPasswordReset) await this.presenter.dontSuggestPasswordReset();
        this.props.closeModal();
    }


    modelChanged(model: ResetPasswordVM) {
        this.setState({ model });
    }

    async submit() {
        await this.presenter.submit();
    }

    render() {
        return <Container>
            <FormGeneralError error={ this.state.model.errors[''] }/>
            <Form>
                <Title>Cambiar contraseña</Title>
                <Text>Para proteger tu experiencia en Portal Expensas te recomendamos crear una contraseña personalizada (minimo 6 caracteres).</Text>
                <InputContainer>
                    <_FormField
                        label="Contraseña:"
                        error={this.state.model.errors['password']}
                    >
                        <PasswordField
                            value={this.state.model.password}
                            onChangeText={(password) => this.presenter.set({ password })}
                            hasError={!!this.state.model.errors['password']}/>
                    </_FormField>

                    <_FormField
                        label="Repetir contraseña:"
                        error={this.state.model.errors['confirmationPassword']}
                    >
                        <PasswordField
                            value={this.state.model.confirmationPassword}
                            onChangeText={(confirmationPassword) => this.presenter.set({ confirmationPassword })}
                            hasError={!!this.state.model.errors['confirmationPassword']}/>
                    </_FormField>
                </InputContainer>
                { this.props.showCheckbox && (
                    <CheckboxContainer>
                        <CheckBox type={'checkbox'} onChange={() => this.presenter.set({ dontSuggestPasswordReset: !this.state.model.dontSuggestPasswordReset })}/>
                        <p>No volver a mostrar este mensaje</p>
                    </CheckboxContainer>
                )}
                <ButtonContainer>
                    <LinkButton onClick={() => this.cancel()}>Ahora no</LinkButton>
                    <_PrimaryButton onClick={() => this.presenter.submit()}>Enviar</_PrimaryButton>
                </ButtonContainer>
            </Form>
        </Container>;
    }
}

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
` ;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -20px;
  padding-left: 10px;
` ;

const CheckBox = styled.input`
`;

const Container = styled.div`
  font-family: 'DM Sans', sans-serif;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  border-radius: 15px;
`;

const InputContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
`;

const Text = styled.p`
  width: 350px;
  text-align: justify;
  margin: auto;
`;

const _FormField = styled(FormField)`
    label {
      font-size: ${styles.fonts.size.regular};
      margin-bottom: 4px;
    }
`;

const FormBody = styled.div``;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  margin-bottom: 25px;
  gap: 25px;
  background-color: #FFFFFF;
  min-width: 400px;
  min-height: 380px;
  padding: 20px;
  border-radius: 15px;

  ${FormBody} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      flex-basis: 262px;
    }

    > .large {
      flex-basis: 100%;
    }
  }
`;

const _PrimaryButton = styled(PrimaryButton)`
  width: fit-content;
`;


interface Props {
    showCheckbox: boolean,
    closeModal: () => void,
}

interface State {
    model: ResetPasswordVM;
}
