import { PrimaryButton } from '../buttons/PrimaryButton';
import React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';
import { confirmAlert } from 'react-confirm-alert';

export const ConfirmModal: React.FC<Props> = (props) => {
    const cancel = () => {
        props.onCancel?.();
        props.close();
    };

    return (
        <ModalContainer>
            <Title>{props.title}</Title>
            <Description>{props.message}</Description>
            <ButtonContainer>
                <PrimaryButton
                    onClick={async () => {
                        props.onConfirm?.();
                        props.close();
                    }}
                >
                    Sí
                </PrimaryButton>
                <PrimaryButton onClick={cancel}>No</PrimaryButton>
            </ButtonContainer>
        </ModalContainer>
    );
};

export const showConfirm = (title: string, message: string, onConfirm: () => void) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <ConfirmModal
                    title={title}
                    message={message}
                    onConfirm={onConfirm}
                    close={onClose}
                />
            );
        },
    });
};

const Title = styled.div``;

const Description = styled.p``;

const ButtonContainer = styled.div``;

const ModalContainer = styled.div`
  border: 1px solid rgba(117, 117, 117, .2);
  border-radius: 10px;
  padding: 2rem;
  background-color: white;

  ${Title} {
    margin-bottom: 1rem;
    font-size: ${styles.fonts.size.xxbig};
  }

  ${Description} {
    margin-bottom: 2rem;
    font-size: ${styles.fonts.size.regular};
  }

  ${ButtonContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
`;

interface Props {
    title: string;
    message?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    close: () => void;
}
