import { NotFound } from './views/errorPages/NotFound';
import { LoginPage } from './views/public/Login/LoginPage';
import { ExpensesPage } from './views/app/Expenses/ExpensesPage';
import { Layout } from './views/app/Layout/Layout';
import { PaymentsPage } from './views/app/Payments/PaymentsPage';
import { AnnouncementsPage } from './views/app/Announcements/AnnouncementsPage';
import { FeatureConfigClient } from '@eroman/common/src/lib/featureConfig/FeatureConfigClient';
import { ClaimsPage } from './views/app/Claims/ClaimsPage';
import { DocumentsPage } from './views/app/Documents/DocumentsPage';
import { AmenitiesPage } from './views/app/Amenities/AmenitiesPage';
import { UsefulContactsPage } from './views/app/UsefulContacts/UsefulContactsPage';
import { DownloadApplicationsPage } from './views/public/DownloadApplicationsPage';
import { Downloader } from './views/components/downloader/Downloader';
import { UnitPage } from './views/app/Unit/UnitPage';
import { PublicAnnouncementPage } from './views/public/Announcement/PublicAnnouncementPage';
import { PasswordPage } from './views/public/Password/PasswordPage';
import { PollsPage } from './views/app/Polls/PollsPage';
import { ChatPage } from './views/app/Chat/ChatPage';

export function createRoutes(featureConfigClient: FeatureConfigClient, showClaimAssociation: boolean) {
    return {
        notFoundComponent: NotFound,
        authRouteCall: () => ({
            name: 'login',
        }),
        routes: [
            {
                name: 'download-platforms',
                public: true,
                path: '/downloadPlatforms:id(\\d+)',
                component: DownloadApplicationsPage,
            },
            {
                name: 'download-file',
                public: false,
                path: '/download',
                component: Downloader,
            },
            {
                name: 'login',
                public: true,
                path: '/login',
                component: LoginPage,
            },
            {
                name: 'password',
                public: true,
                path: '/password',
                component: PasswordPage,
            },
            {
                name: 'unit',
                public: false,
                path: '/units/:id(\\d+)',
                prefix: true,
                component: UnitPage,
            },
            {
                name: 'payment-detail',
                public: false,
                path: '/payments/:id(\\d+)',
                meta: { title: 'Mis pagos' },
                component: PaymentsPage,
                layout: Layout,
            },
            {
                name: 'payments',
                public: false,
                path: '/payments',
                meta: { title: 'Mis pagos' },
                component: PaymentsPage,
                layout: Layout,
            },
            {
                name: 'announcement-detail',
                public: false,
                path: '/announcements/:id(\\d+)',
                meta: { title: 'Comunicados' },
                component: AnnouncementsPage,
                layout: Layout,
            },
            {
                name: 'announcements',
                public: false,
                path: '/announcements',
                meta: { title: 'Comunicados' },
                component: AnnouncementsPage,
                layout: Layout,
            },
            {
                name: 'phone-numbers',
                public: false,
                path: '/usefulPhoneNumbers',
                meta: { title: 'Teléfonos útiles' },
                component: UsefulContactsPage,
                layout: Layout,
            },
            ...featureConfigClient.hasFeature('claims') ? [{
                name: 'claim-detail',
                public: false,
                path: '/claims/:id(\\d+)',
                meta: { title: 'Reclamos' },
                component: ClaimsPage,
                layout: Layout,
            }] : [],
            ...featureConfigClient.hasFeature('claims') ? [{
                name: 'claims',
                public: false,
                path: '/claims',
                meta: { title: 'Reclamos' },
                component: ClaimsPage,
                layout: Layout,
            }] : [],
            ...featureConfigClient.hasFeature('documents') ? [{
                name: 'documents',
                public: false,
                path: '/documents',
                meta: { title: 'Documentos' },
                component: DocumentsPage,
                layout: Layout,
            }] : [],
            ...featureConfigClient.hasFeature('amenities') ? [{
                name: 'amenities',
                public: false,
                path: '/amenities',
                meta: { title: 'Amenities' },
                component: AmenitiesPage,
                layout: Layout,
            }] : [],
            {
                name: 'expenses',
                public: false,
                path: '/',
                meta: { title: 'Expensas' },
                component: ExpensesPage,
                layout: Layout,
            },
            {
                name: 'public-announcement',
                public: true,
                path: '/announcements/:token([a-zA-Z-\\d]+)/view',
                component: PublicAnnouncementPage,
            },
            {
                name: 'votaciones',
                public: false,
                path: '/polls',
                meta: { title: 'Votaciones' },
                component: PollsPage,
                layout: Layout,
            },
            {
                name: 'chat',
                public: false,
                path: '/chat',
                meta: { title: 'Chat' },
                component: ChatPage,
                layout: Layout,
            },
        ],
    };
}
