import { SelectUnit } from './SelectUnit';
import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { Navigator } from '../../lib/navigation/Navigator';
import { EventBus } from '../../_base/eventBus/EventBus';
import { CurrentUnitChanged } from '../../events/CurrentUnitChanged';

export class SelectUnitHandler implements CommandHandler<SelectUnit> {
    constructor(private sessionStorage: SessionStorage, private navigator: Navigator, private eventBus: EventBus) {}

    async handle(command: SelectUnit) {
        await this.sessionStorage.do(session => session.selectUnit(command.unitId));
        this.navigator.navigateToHome();
        await this.eventBus.post(new CurrentUnitChanged(command.unitId));
    }
}
