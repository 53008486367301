import { QueryHandler } from '../../../_base/actionBus/QueryHandler';
import { GetAmenities } from './GetAmenities';
import { Amenity } from '../../../models/amenities/Amenity';
import { AmenitiesService } from '../../../models/amenities/AmenitiesService';
import { SessionStorage } from '../../../models/session/SessionStorage';

export class GetAmenitiesHandler implements QueryHandler<GetAmenities, Amenity[]> {
    constructor(private amenitiesService: AmenitiesService, private sessionStorage: SessionStorage) {}

    async handle(query: GetAmenities): Promise<Amenity[]> {
        const session = await this.sessionStorage.get();
        return this.amenitiesService.getAmenities(session.unit!!.association.id);
    }
}
