import { HttpClient } from '../../_base/http/HttpClient';
import { PollService } from '../../models/polls/PollService';
import { Poll } from '../../models/polls/Poll';

export class HttpPollService implements PollService {
    constructor(private httpClient: HttpClient) {}

    async vote (pollId: number, option: string, unitId: number): Promise<void> {
        const body = { pollId, option, unitId };
        await this.httpClient.put (`/polls/${pollId}`, body);
    }

    async getUnitPolls(unitId: number): Promise<Poll[]> {
        const response = await this.httpClient.get(`/units/${unitId}/polls`);
        return response.body.polls.map(json => Poll.fromJson(json));
    }
}
