import { QueryHandler } from '../../../_base/actionBus/QueryHandler';
import { AmenitiesService } from '../../../models/amenities/AmenitiesService';
import { GetAvailableBookingDays } from './GetAvailableBookingDays';
import { LocalDate } from '../../../_base/time/LocalDate';

export class GetAvailableBookingDaysHandler implements QueryHandler<GetAvailableBookingDays, LocalDate[]> {
    constructor(private amenitiesService: AmenitiesService) {}

    async handle(query: GetAvailableBookingDays): Promise<LocalDate[]> {
        return this.amenitiesService.getAvailableBookingDays(query.amenityId);
    }
}
