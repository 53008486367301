import { YearMonth } from '../../_base/time/YearMonth';
import { LocalDate } from '../../_base/time/LocalDate';
import { Money } from '../general/Money';
import { FileInfo } from '../general/FileInfo';
import { Nullable } from '../../_base/lang/Nullable';

export class Expense {
    constructor(
        public period: YearMonth,
        public dueDate1: LocalDate,
        public amount1: Money,
        public dueDate2: LocalDate,
        public amount2: Money,
        public dueDateNumberToPay: number,
        public file: Nullable<FileInfo> = null,
    ) {}

    get amountToPay() {
        return this.dueDateNumberToPay === 1 ? this.amount1 : this.amount2;
    }

    get dueDateToPay() {
        return this.dueDateNumberToPay === 1 ? this.dueDate1 : this.dueDate2;
    }

    static fromJson(json: any): Expense {
        return new Expense(
            YearMonth.fromISO8601String(json.period),
            LocalDate.fromISO8601String(json.dueDate1),
            Money.of(json.amount1),
            LocalDate.fromISO8601String(json.dueDate2),
            Money.of(json.amount2),
            json.dueDateToPay,
            json.file,
        );
    }
}
