import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(17,24,35, .6)',
        animation: 'fadeIn 200ms',
    },
    content: {
        height: '100%',
        width: '650px',
        padding: '28px 50px',
        top: '0',
        right: '0',
        bottom: 'auto',
        left: 'auto',
        animation: 'slideIn 200ms',
    },
};


export const RightModal: React.FC<Props> = (props) => {
    return(
        <Modal
            isOpen={props.isOpen}
            style={customStyles}
            onRequestClose={props.closeModal}
        >
            <Container>
                {props.children}
            </Container>
        </Modal>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}
