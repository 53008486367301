import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { EventBus } from '@eroman/common/src/_base/eventBus/EventBus';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { GetUnitPayments } from '@eroman/common/src/actions/payments/GetUnitPayments';
import { UnitPayment } from '@eroman/common/src/models/payments/UnitPayment';
import { PaymentDetailVM, PaymentsVM } from './PaymentsVM';
import { MoneyFormatter } from '@eroman/common/src/views/lib/formatters/MoneyFormatter';
import { DatesFormatter } from '@eroman/common/src/views/lib/formatters/DatesFormatter';
import { PaymentInformed } from '@eroman/common/src/events/PaymentInformed';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { Session } from '@eroman/common/src/models/session/Session';
import { Config } from '../../../infrastructure/Config';

export interface PaymentsView {
    modelChanged(model: PaymentsVM);
}

export class PaymentsPresenter {
    private model = new PaymentsVM();
    private unitId: null|number = null;

    constructor(private view: PaymentsView, private actionBus: ActionBus, private session: SessionStorage, private eventBus: EventBus, private navigator: WebNavigator) {
        this.eventBus.subscribe(this, PaymentInformed, this.refresh.bind(this));
        this.navigator.currentRouteChanged.subscribe(this, this.onCurrentRouteChanged.bind(this));
        this.session.sessionChanged.subscribe(this, this.updateUnitId.bind(this));
    }

    updateUnitId(session: Session) {
        this.unitId = session.unit!!.id;
        this.notifyModelChanged();
    }

    private notifyModelChanged() {
        this.view.modelChanged(this.model);
    }

    async start(isModalOpen: boolean = false) {
        await this.loadPayments(isModalOpen);
        await this.selectPaymentFromCurrentRoute();
        let session = await this.session.get();
        this.unitId = session.unit!!.id;
        this.set({ isModalOpen });
    }

    private async loadPayments(isModalOpen: boolean = false) {
        this.set({ isLoading: true });
        const payments = await this.actionBus.query(new GetUnitPayments());
        this.set({ payments: payments.map(p => this.toVM(p)), isLoading: false });
        this.navigateToPaymentDetail(isModalOpen);
    }

    private navigateToPaymentDetail(isModalOpen: boolean) {
        if (this.navigator.currentRoute?.name === 'payments' && this.model.payments.length > 0) {
            this.navigator.navigate('payment-detail', { id: this.model.payments.first().id, isModalOpen });
        }
    }

    private selectPaymentFromCurrentRoute() {
        const id = parseInt(this.navigator.currentRoute?.params?.id, 10);
        let selectedPayment = this.model.payments.singleOrNull(p => p.id === id);
        this.set({ selectedPayment });
        this.set({ isModalOpen: false });
    }

    generatePaymentReceiptUrl(): string {
        const paymentId = parseInt(this.navigator.currentRoute?.params?.id, 10);
        return `${Config.get('apiBaseUrl')}/receipts?paymentId=${paymentId}&unitId=${this.unitId}`;
    }

    onCurrentRouteChanged() {
        this.selectPaymentFromCurrentRoute();
    }

    private set<K extends keyof PaymentsVM>(changes: Pick<PaymentsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    private toVM(payment: UnitPayment): PaymentDetailVM {
        return {
            id: payment.id,
            period: payment.period ? DatesFormatter.yearMonth(payment.period) : null,
            transactionNumber: payment.transactionNumber,
            status: payment.status,
            source: payment.source,
            date: DatesFormatter.fullDate(payment.date),
            amount: MoneyFormatter.format(payment.amount),
        };
    }

    selectPayment(id: number) {
        this.navigator.navigate('payment-detail', { id });
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }

    async refresh() {
        await this.loadPayments();
    }
}
