import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../../styles';
import { findIcon } from './icons/IconFinder';

export const AmenityBox: React.FC<Props> = (props) => {
    let icon = findIcon(props.iconPath);
    return (
        <Container
            onClick={() => props.onClick ? props.onClick(props.id) : () => {}}
            className={ classNames({ disabled: props.isDisabled }) }
        >
            <Icon src={icon}/>
            { props.isDisabled && <Name>NO DISPONIBLE POR DEUDA</Name> || <Name>{props.name.toUpperCase()}</Name> }
        </Container>
    );
};

const Container = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 25px;
  display: inline-flex;
  background-color: #85b8eb;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #1B98EB;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  &.disabled {
    background-color: #C8D0E1;
    cursor: default;
    
    &:hover {
      background-color: #C8D0E1;
      box-shadow: none;
    }
  }
  
`;

const Icon = styled.img`
  flex-grow: 0;
  height: 60px;
  width: 60px;
`;

const Name = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-weight: ${styles.fonts.weight.bold};
  font-size: 15px;
  line-height: 18px;
  color: #f4f7fe;
  margin-top: 15px;
  text-align: center;
  letter-spacing: 0.5px;
`;

interface Props {
    id: number,
    name: string,
    iconPath: string,
    onClick?: (id: number) => void,
    isClickable?: boolean,
    className?: string,
    isDisabled?: boolean,
}
