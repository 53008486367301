import { LoginVM } from './LoginVM';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { handleValidationErrors } from '@eroman/common/src/_base/validation/catchValidationErrors';
import { Login, RegularCredentials, TokenCredentials } from '@eroman/common/src/actions/auth/Login';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';

export interface LoginView {
    modelChanged(model: LoginVM);
}

export class LoginPresenter {
    private model = new LoginVM();

    constructor(private view: LoginView, private actionBus: ActionBus, private navigation: WebNavigator) {}

    set<K extends keyof LoginVM>(changes: Pick<LoginVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async login(token: Nullable<string> = null, nextRoute: Nullable<string> = null) {
        try {
            this.set({ isLoading: true });
            const credentials = this.credentialsWith(token);
            await this.actionBus.exec(new Login(credentials));
            this.navigateToNextRoute(nextRoute);
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages(), isLoading: false }));
        }
    }

    navigateToPassword() {
        this.navigation.navigateToPath('/password');
    }

    private navigateToNextRoute(route: Nullable<string>) {
        if (route != null) return this.navigation.navigateToPath(route);
        this.navigation.navigateToHome();
    }

    private credentialsWith(token: Nullable<string>) {
        if (token) return new TokenCredentials(token);
        return new RegularCredentials(this.model.email, this.model.password.replaceAll(' ', ''));
    }
}
