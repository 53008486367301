import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetClaimActivity } from './GetClaimActivity';
import { ClaimActivity } from '../../models/claims/activity/ClaimActivity';
import { ClaimService } from '../../models/claims/ClaimService';

export class GetClaimActivityHandler implements QueryHandler<GetClaimActivity, ClaimActivity[]> {
    constructor(private claimService: ClaimService) {}

    async handle(query: GetClaimActivity): Promise<ClaimActivity[]> {
        return await this.claimService.getClaimActivity(query.claimId);
    }
}
