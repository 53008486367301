import React, { useState } from 'react';
import styled from 'styled-components';
import logo from './logo.png';
import appleStore from './appleStore.png';
import googleStore from './googleStore.png';
import mobileImage from './mobileImage.png';
import { styles } from '../styles';

interface Props { isIOS: boolean; }

export const DownloadApplicationsPage: React.FC<Props> = (props) => {
    const [dismiss, setDismiss] = useState(false);
    return dismiss
        ? <>{props.children}</>
        : <Root>
            <Container>
                <Logo src={logo} alt="logo" />
                <Text>Para utilizar <strong>Portal Expensas</strong> desde tu celular descargá nuestra aplicación</Text>
                { props.isIOS
                    ? <a href="https://apps.apple.com/ar/app/portal-expensas/id1598126110" rel="noreferrer"><AppStoreImage src={appleStore} alt="apple store" /></a>
                    : <a href="https://play.google.com/store/apps/details?id=com.portalexpensas"><GooglePlayImage src={googleStore} alt="google store" /></a>
                }
                <MobileImageContainer>
                    <img src={mobileImage} alt="image" />
                    <p>Fácil, rápido y transparente</p>
                </MobileImageContainer>
                <Button onClick={() => { setDismiss(true); }}>
                    continuar en versión web
                    <br />
                    (no optimizado para celulares)
                </Button>
            </Container>
        </Root>;
};

const Root = styled.div`
  align-items: center;
  background-color: ${styles.primaryColor};
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 5%;
  width: 100vw;
`;

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 70%;
  min-height: 450px;
  padding: 5%;
  width: 93%;
`;

const Text = styled.p`
  color: #0050bc;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  max-width: 400px;
  text-align: center;
  margin-top: 15px;
`;

const MobileImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  img { height: 123px; width: 227px; }

  p {
    color: #0050bc;
    font-size: 14px;
    text-align: center;
  }
`;

const Logo = styled.img`
  height: 64px; 
  width: 230px;
`;

const AppStoreImage = styled.img`
  height: 50px; 
  width: 150px;
  margin-top: 15px;
`;

const GooglePlayImage = styled.img`
  height: 49px;
  width: 150px;
  margin-top: 25px;
`;

const Button = styled.a`
  color: #0050bc;
  margin-top: 25px;
  font-size: 12px;
  opacity: 0.7;
  text-align: center;
`;
