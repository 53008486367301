import React from 'react';
import AppContext from '../../lib/AppContext';
import styled from 'styled-components';
import { Loader } from '../../components/Loader';
import { ChatVM } from '@eroman/common/src/views/app/Chat/ChatVM';
import { ChatView } from '@eroman/common/src/views/app/Chat/ChatPresenter';
import { Message } from './Message';
import TextArea from 'antd/es/input/TextArea';
import { SendOutlined } from '@ant-design/icons';
import { ChatBotHeader } from './ChatBotHeader';
import { Button } from 'antd';
import { WaitingIndicator } from './WaitingIndicator';

export class ChatPage extends React.Component implements ChatView {
    private presenter = AppContext.presenters.chat(this);
    private messagesEndRef = React.createRef();

    state = {
        model: new ChatVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    componentDidUpdate() {
        // @ts-ignore
        this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    modelChanged(model: ChatVM) {
        this.setState({ model });
    }

    handleEnterPress(event: any) {
        event.key === 'Enter' && this.presenter.sendMessage();
    }

    render() {
        return (
            <Container>
                {this.state.model.isLoading ? <Loader/> :
                    <Box>

                        <ChatBotHeader/>

                        <MessagesContainer>
                            {this.state.model.messages.map((message, index)=> (<Message key={index} message={message} recipient={this.state.model.author}/>))}
                            {/*@ts-ignore*/}

                            <div ref={this.messagesEndRef} />

                            <WaitingIndicator isWaiting={this.state.model.isWriting} />

                        </MessagesContainer>


                        <TextPrompt>
                            <_TextArea
                                placeholder={'Escribi tu pregunta'}
                                style={{ resize: 'none',  overflow: 'hidden', padding: '20px' }}
                                value={this.state.model.prompt}
                                onChange={(e) => this.presenter.setPromptText(e.target.value)}
                                rows={4}
                                maxLength={256}
                                onKeyPress={(e) => this.handleEnterPress(e)}
                                bordered={false}
                            />
                            <ButtonContainer>
                                <SendButton onClick={() => this.presenter.sendMessage()} icon={<SendOutlined/>} disabled={this.isSendButtonDisabled()}>Enviar</SendButton>
                            </ButtonContainer>
                        </TextPrompt>
                    </Box>
                }
            </Container>
        );
    }

    private isSendButtonDisabled() {
        return this.state.model.prompt.length < 1 || this.state.model.isWriting;
    }
}

const SendButton = styled(Button)`
  background-color: #1565d8;
  margin: 13px;
  padding: 13px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  text-align: center;
  align-items: center;
  
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

const _TextArea = styled(TextArea)`
  font-size: 16px;
  width: 100%;
  padding: 15px;
`;

const TextPrompt = styled.div`
  width: 100%;
  background-color: #fff;
  height: 100px;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif !important;
  padding-left:10px;
  border-top: solid 1px #cecdcd;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const MessagesContainer = styled.div`
  height: 75%;
  display: block;
  overflow-y: auto;
  max-height: 80vh;
  max-width: 100%;
  padding: 15px;
  background-color: #f4f4f4;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 900px;
  max-height: 77vh;
  flex-grow: 1;
  max-width: 1210px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
  font-size: small;
`;
