import React, { KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../../styles';

export const FormTextInput: React.FC<Props> = (props) => (
    <_Input
        type={'text'}
        autoCapitalize={props.autocapitalize || 'none'}
        maxLength={200}
        className={classNames(props.className, { 'has-error': props.hasError })}
        onChange={(e) => props.onChangeText?.(e.target.value)}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeHolder}
        value={props.value}
        autoComplete={props.autocomplete ?? 'on'}
    />
);

const _Input = styled.input`
  color: #494949;
  font-family: 'Nunito', sans-serif;
  font-size: ${styles.fonts.size.xsmall};
  line-height: 16px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #8692A6;

  &:focus { border-color: #1565D8; }

  &.has-error {
    color: #E15201;
    border-color: #E15201;
  }
`;

interface Props {
    hasError?: boolean;
    value?: string;
    placeHolder?: string;
    autocapitalize?: 'none' | 'off' | 'on' | 'sentences' | 'words' | 'characters';
    onChangeText?: ((text: string) => void);
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    className?: string;
    autocomplete?: string;
}
