export class PublicAnnouncementVM {
    isLoading = true;
    title = '';
    content = '';
    date = '';
    attachments: FileVM[] = [];
}

export class FileVM {
    url = '';
    label = '';
}
