import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetUnitCVUNumber } from './GetUnitCVUNumber';
import { PaymentService } from '../../models/payments/PaymentService';
import { SessionStorage } from '../../models/session/SessionStorage';

export class GetUnitCVUNumberHandler implements QueryHandler<GetUnitCVUNumber, string> {
    constructor(private paymentService: PaymentService, private sessionStorage: SessionStorage) {}

    async handle(query: GetUnitCVUNumber): Promise<string> {
        const session = await this.sessionStorage.get();
        return this.paymentService.getUnitCVUTransferNumber(session.unit!.id);
    }
}

