import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface Props {
    isWaiting: boolean
}

export const WaitingIndicator: React.FC<Props> = ({ isWaiting }) => {
    return (
        <Container>
            {isWaiting &&
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}
                />
            }
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    
    
  &.Spin {
    margin: 0;
    padding: 0;
  }
`;

