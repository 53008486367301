import { Nullable } from '../../../_base/lang/Nullable';

export class DocumentsVM {
    isLoading = false;
    breadcrumbs: DocumentInfo[] = [];
    documents: DocumentInfo[] = [];
}

export interface DocumentInfo {
    id: number,
    parentId: Nullable<number>,
    name: string,
    physicalPath: Nullable<string>,
    type: string,
    url: Nullable<string>,
    createdAt: string,
    updatedAt: string
}
