import { CommandHandler } from '../../../../_base/actionBus/CommandHandler';
import { BookAmenity } from './BookAmenity';
import { AmenitiesService } from '../../../../models/amenities/AmenitiesService';
import { SessionStorage } from '../../../../models/session/SessionStorage';

export class BookAmenityHandler implements CommandHandler<BookAmenity> {
    constructor(private amenitiesService: AmenitiesService, private sessionStorage: SessionStorage) {}

    async handle(command: BookAmenity): Promise<void> {
        const session = await this.sessionStorage.get();
        const { amenityId, date, startAt, durationInMinutes } = command;
        return this.amenitiesService.bookAmenity(amenityId, date, startAt, durationInMinutes, session.unit!!.id);
    }
}
