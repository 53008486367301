import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetDocuments } from './GetDocuments';
import { Document } from '../../models/documents/Document';
import { SessionStorage } from '../../models/session/SessionStorage';
import { DocumentService } from '../../models/documents/DocumentService';

export class GetDocumentsHandler implements QueryHandler<GetDocuments, Document[]> {
    constructor(private documentService: DocumentService, private sessionStorage: SessionStorage) {}

    async handle(query: GetDocuments): Promise<Document[]> {
        const session = await this.sessionStorage.get();
        return await this.documentService.getDocuments(session.unit!.association.id, query.parentId);
    }
}
