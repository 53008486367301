import { FileUploader } from './FileUploader';
import { HttpClient } from '../../_base/http/HttpClient';
import { FileData } from './FileData';
import { HttpRequest } from '../../_base/http/HttpRequest';
import { HttpMethod } from '../../_base/http/HttpMethod';

export class HttpFileUploader implements FileUploader {
    constructor(private httpClient: HttpClient) {}

    async upload(file: FileData, onProgress: (number) => void): Promise<string> {
        const response = await this.httpClient.send(this.requestFor(file), onProgress);
        return response.body.name;
    }

    private requestFor(file: FileData) {
        return new HttpRequest(HttpMethod.POST, '/upload', file.getData());
    }
}
