import { QueryHandler } from '../../../../_base/actionBus/QueryHandler';
import { GetBookings } from './GetBookings';
import { Booking } from '../../../../models/bookings/Booking';
import { AmenitiesService } from '../../../../models/amenities/AmenitiesService';
import { SessionStorage } from '../../../../models/session/SessionStorage';

export class GetBookingsHandler implements QueryHandler<GetBookings, Booking[]> {
    constructor(private amenitiesService: AmenitiesService, private sessionStorage: SessionStorage) {}

    async handle(query: GetBookings): Promise<Booking[]> {
        const session = await this.sessionStorage.get();
        return this.amenitiesService.getBookings(query.amenityId, session.unit!!.id);
    }
}
