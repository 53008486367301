import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { NameAndLabelCannotBeEmptyError } from '@eroman/common/src/views/lib/NameAndLabelCannotBeEmptyError';

export interface DownloaderView {
    showErrorMessage(message: string);
    download(url: string);
}

export class DownloaderPresenter {
    constructor(
        private view: DownloaderView,
        private navigation: WebNavigator,
        private downloadUrlBuilder: DownloadUrlBuilder
    ) {}

    async start(name: string, label: string) {
        try {
            let documentUrl = this.downloadUrlBuilder.buildFor({ name, label });
            this.view.download(documentUrl);
            this.navigation.navigateToHome();
        } catch (e) {
            if (e instanceof NameAndLabelCannotBeEmptyError) {
                this.view.showErrorMessage('No se encontró el archivo');
            } else throw e;
        }
    }
}
