import Select from 'react-select';
import React from 'react';
import classNames from 'classnames';

export class FormItemPickerInput extends React.Component<Props> {
    state = {
        selectedOption: this.props.options.singleOrNull(it => it.value === this.props.value),
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        this.props.onChangeSelected!!(selectedOption.value);
    };

    customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid #1565D8' : '1px solid #8692A6',
            boxShadow: 'none',

            '&.has-error': {
                color: '#E15201',
                borderColor: '#E15201',
            },
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: '2px',

        }),
        option: (provided) => ({
            ...provided,
            borderRadius: '5px',
        }),
    };

    render() {
        return <Select
            value={this.state.selectedOption}
            onChange={this.handleChange}
            options={this.props.options}
            styles={this.customStyles}
            placeholder={'Seleccione una opción'}
            className={classNames(this.props.className, { 'has-error': this.props.hasError })}
        />;
    }
}


interface Props {
    options: {value: any, label: string}[];
    className?: string;
    onChangeSelected?: ((id: string) => void);
    hasError?: boolean;
    value: string;
}
