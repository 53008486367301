import { Nullable } from '../../_base/lang/Nullable';
import { LocalDateTime } from '../../_base/time/LocalDateTime';
import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';
import { VirtualFileTypes } from './VirtualFileTypes';

export class Document {
    constructor(
        public id: number,
        public name: string,
        public parentId: Nullable<number> = null,
        public physicalPath: Nullable<string> = null,
        public type: VirtualFileTypes,
        public createdAt: LocalDateTime,
        public updatedAt: LocalDateTime
    ) {}

    static fromJson(json: any, jsonDateSerializer: JsonDateSerializer) {
        return new Document(
            json.id,
            json.name,
            json.parentId,
            json.physicalPath,
            VirtualFileTypes[json.type],
            jsonDateSerializer.fromIso8601(json.createdAt),
            jsonDateSerializer.fromIso8601(json.updatedAt)
        );
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            parentId: this.parentId,
            physicalPath: this.physicalPath,
            type: this.type,
            createdAt: this.createdAt.toISO8601String(),
            updatedAt: this.updatedAt.toISO8601String(),
        };
    }
}
