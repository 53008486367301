export  class ClaimSubject {
    constructor(
        public id: number,
        public title: string,
    ) {
    }

    static fromJson(json: any) {
        return new ClaimSubject(
            json.id,
            json.title,
        );
    }
}
