import { HttpClient } from '../../_base/http/HttpClient';
import { Feature } from './Feature';
import { FeatureConfigClient } from './FeatureConfigClient';

export class HttpFeatureConfigClient implements FeatureConfigClient {
    private features: Map<string, Feature> = new Map();
    private timer: any;

    constructor(private httpClient: HttpClient) {}

    async init(pollRateMs: number = 300000) {
        await this.fetch();
        this.startPolling(pollRateMs);
    }

    private startPolling(pollRateMs: number) {
        this.timer = setInterval(() => this.fetch(), pollRateMs);
    }

    private async fetch() {
        try {
            const response = await this.httpClient.get('/features');
            for (const featureName of response.body) {
                const feature = new Feature(featureName.toLowerCase(), 'true');
                this.features.set(feature.name, feature);
            }
        } catch(e) {
            // Do Nothing
        }
    }

    hasFeature(name: string) {
        return this.isTrue(this.features.get(name.toLowerCase())?.value);
    }

    private isTrue(value: string|undefined) {
        return value?.toLowerCase() == 'true' || value == '1';
    }

    stop() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
        this.timer = null;
    }
}
