import { ErrorMap } from '../../../../_base/validation/ErrorMap';
import { Nullable } from '../../../../_base/lang/Nullable';
import { FileInfo } from '../../../../models/general/FileInfo';

export class CreateClaimVM {
    isLoading = false;
    subjects: SubjectInfo[] = [];
    subjectId: Nullable<number> = null;
    description = '';
    errors: ErrorMap = {};
    attachments: FileInfo[] = [];
}

export interface SubjectInfo {
    id: number;
    title: string;
}
