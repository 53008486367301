import { HttpClient } from '../../_base/http/HttpClient';
import { ChatService } from '../../models/chat/ChatService';
import { ChatMessage, MessageDirections } from '../../models/chat/ChatMessage';
import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';

export class HttpChatService implements ChatService {
    constructor(private httpClient: HttpClient, public jsonDateSerializer: JsonDateSerializer) {}

    async sendMessage(managerId: number, associationId: number, unitId: number, message: string, email: string): Promise<ChatMessage> {
        const body = { managerId, associationId, unitId, message, email };
        const response = await this.httpClient.post('/messages', body);
        return new ChatMessage(response.body.body.text, MessageDirections.Sent);
    }

    async getMessages(recipient: string): Promise<ChatMessage[]> {
        const response = await this.httpClient.get(`/messages?recipient=${recipient}`);
        let chatMessages: ChatMessage[] = [];
        response.body.items.map(message => {
            const chatMessage = ChatMessage.fromJson(message, this.jsonDateSerializer);
            chatMessages.push(chatMessage);
        });
        return chatMessages;
    }
}
