export class YearMonth {
    private readonly _year: number;
    private readonly _month: number;

    private constructor(year: number, month: number) {
        this._year = year;
        this._month = month;
    }

    get year(): number {
        return this._year;
    }

    get month(): number {
        return this._month;
    }

    toISO8601String(): string {
        return this._year + '-' + this.month.toString().padZeros(2);
    }

    static of(year: number, month: number) {
        return new YearMonth(year, month);
    }

    static fromISO8601String(value: string) {
        const parts = value.split('-');
        return new YearMonth(parseInt(parts[0], 10), parseInt(parts[1], 10));
    }
}
