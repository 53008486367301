import { Command } from '../../_base/actionBus/Command';
import { FileInfo } from '../../models/general/FileInfo';

export class CommentClaim extends Command {
    constructor(
        public claimId: number,
        public message: string,
        public attachments: FileInfo[] = [],
    ) {
        super();
    }
}
