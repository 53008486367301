import React from 'react';
import styled from 'styled-components';
import { styles } from '../styles';

export const SectionHeader: React.FC<Props> = (props) => (
    <Container>
        <img src={props.icon} alt={props.title}/>
        <p>{props.title}</p>
    </Container>
);


const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  
  & p {
    font-weight: ${styles.fonts.weight.regular};
    font-size: ${styles.fonts.size.big};
    color: #42557B;
  }
  
  & img {
    width: 34px;
  }
`;

interface Props {
    icon: string,
    title: string,
    color?: string,
}
