import React from 'react';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const ActivityIcon: React.FC<Props> = (props) => {
    return (
        <Icon backgroundColor={props.color}><p>{props.letter}</p></Icon>
    );
};

const Icon = styled.div.attrs((props: { backgroundColor: string }) => props)`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  color: #ffffff;
  margin-right: 5px;

  p {
    font-size: 10px;
    line-height: 20px;
    font-weight: ${styles.fonts.weight.bold};
    text-align: center;
  }
`;

interface Props {
    color: string;
    letter?: string;
}
