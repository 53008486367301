import React from 'react';
import AppContext from '../../lib/AppContext';
import { CreateClaimModal } from '../CreateClaim/CreateClaimModal';
import { NoClaims } from './NoClaims';
import claimsIcon from './claimsIcon.png';
import detailIcon from './detailIcon.png';
import { SectionHeader } from '../../components/SectionHeader';
import { Loader } from '../../components/Loader';
import { ClaimRow } from './ClaimRow/ClaimRow';
import { ClaimDetail } from './ClaimDetail';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { ClaimsVM } from './ClaimsVM';
import { ClaimsView } from './ClaimsPresenter';
import { ScrollableList } from '../../components/ScrollableList';

export class ClaimsPage extends React.Component implements ClaimsView {
    private presenter = AppContext.presenters.claims(this);

    state = {
        isOpen: false,
        model: new ClaimsVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    componentWillUnmount() {
        this.presenter.stop();
    }

    modelChanged(model: ClaimsVM) {
        this.setState({ model });
    }

    private toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    private renderClaims() {
        return <>
            <ScrollableList>
                {this.state.model.claims.map(claim => (
                    <ClaimRow
                        key={claim.id}
                        item={claim}
                        onClick={this.presenter.selectClaim}
                        selected={this.state.model.selectedClaim?.id === claim.id}
                    />
                ))}
            </ScrollableList>
        </>;
    }

    render() {
        return <>
            {this.state.isOpen && <CreateClaimModal isOpen={this.state.isOpen} closeModal={() => this.toggleModal()}/>}
            {this.state.model.isLoading ? <Loader/> :
                (this.state.model.claims.length === 0) ?
                    <Box>
                        <NoClaims toggleModal={this.toggleModal.bind(this)}/>
                    </Box> :
                    <>
                        <PrimaryButton onClick={() => this.toggleModal()}>
                            Nuevo reclamo
                        </PrimaryButton>

                        <Box>
                            <Claims>
                                <SectionHeader icon={claimsIcon} title={'Reclamos'}/>
                                {this.renderClaims()}
                            </Claims>
                            <Detail>
                                <SectionHeader icon={detailIcon} title={'Detalle'}/>
                                {this.state.model.selectedClaim &&
                                <ClaimDetail
                                    claim={this.state.model.selectedClaim}
                                    activities={this.state.model.activities}
                                />}
                            </Detail>
                        </Box>
                    </>
            }
        </>;
    }
}

const Box = styled.div`
  display: flex;
  height: 500px;
  flex-grow: 1;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Claims = styled.li`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  padding: 20px 9px 20px 20px;
  border-right: 1px solid rgba(146, 162, 195, 0.5);
`;

const Detail = styled.div`
  width: 50%;
  padding: 20px;
`;
