import { QueryHandler } from '../../../_base/actionBus/QueryHandler';
import { AmenitiesService } from '../../../models/amenities/AmenitiesService';
import { GetDisabledAmenities } from './GetDisabledAmenities';
import { SessionStorage } from '../../../models/session/SessionStorage';

export class GetDisabledAmenitiesHandler implements QueryHandler<GetDisabledAmenities, number[]> {
    constructor(private amenitiesService: AmenitiesService, private sessionStorage: SessionStorage) {}

    async handle(query: GetDisabledAmenities): Promise<number[]> {
        const session = await this.sessionStorage.get();
        return this.amenitiesService.getDisabledAmenities(session.unit!!.id);
    }
}
