import React from 'react';
import styled from 'styled-components';

export const Loader: React.FC<Props> = (props) => (
    <Container {...props}>
        <StyledLoader />
    </Container>
);

interface Props {
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLoader = styled.div`
  flex: none;
  border-radius: 50%;
  border-style: solid;
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite;
  height: 64px;
  width: 64px;  
  border-color: #178BFF transparent;
  border-width: 6px;
  margin: 0 auto;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  } 
`;
