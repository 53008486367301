import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../styles';

export const LinkButton: React.FC<Props> = (props) => {
    const onClick = (e) => {
        e.preventDefault();
        if (props.isDisabled) return;
        props.onClick?.(e);
    };
    return <Button onClick={onClick} className={classNames(props.className, { disabled: props.isDisabled })}>
        {props.children}
    </Button>;
};

const Button = styled.a`
  text-decoration: underline;
  background-color: transparent;
  padding: 10px 45px;
  cursor: pointer;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
`;

interface Props {
    onClick?: (e: MouseEvent) => void;
    isDisabled?: boolean;
    className?: string;
}
