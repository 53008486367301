import React from 'react';
import styled from 'styled-components';
import { WebNavigator } from '../../../lib/navigation/WebNavigator';
import { CurrentUnit } from '../../../components/CurrentUnit/CurrentUnit';
import { styles } from '../../../styles';
import icon from './icon.png';
import { ResetPasswordModal } from '../../../components/modals/ResetPasswordModal';
import { HeaderView } from './HeaderPresenter';
import AppContext from '../../../lib/AppContext';
import { HeaderVM } from './HeaderVM';

export class Header extends React.Component<Props, any> implements HeaderView {
    private presenter = AppContext.presenters.header(this);
    private suggestPasswordReset = true;

    state = {
        model: new HeaderVM(),
        isModalOpen: false,
    };

    async componentDidMount() {
        this.suggestPasswordReset = await this.presenter.showResetPasswordModal();
        if (this.suggestPasswordReset) this.setState( { isModalOpen: true });
        await this.presenter.dontShowResetPasswordModal();
    }

    modelChanged(model: any) {
        this.setState({ model });
    }

    openModal() {
        this.setState({ isModalOpen: true, model: { ... this.state.model, showCheckbox: false } });
    }

    closeModal() {
        this.setState({ isModalOpen: false });
    }

    render() {
        return (
            <Container>
                <Title>{this.presenter.getTitle()}</Title>
                <UnitContainer>
                    <CurrentUnit />
                    <PasswordIconWrapper>
                        <PasswordIcon src={icon} alt="Cambiar contraseña" onClick={() => this.openModal()} />
                        <Tooltip>Cambiar contraseña</Tooltip>
                    </PasswordIconWrapper>
                </UnitContainer>
                {this.state.isModalOpen && <ResetPasswordModal showCheckbox={this.state.model.showCheckbox} closeModal={() => this.closeModal()}/>}
            </Container>
        );
    }
}

const PasswordIconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const PasswordIcon = styled.img`
  padding-left: 20px;
  height: 46px;
  cursor: pointer;
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;

  ${PasswordIconWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  height: 90px;
`;

const UnitContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
`;

const Title = styled.h1`
  color: #2B3674;
  font-size: 36px;
  font-weight: ${styles.fonts.weight.bold};
`;

interface Props {
    navigation: WebNavigator;
}
