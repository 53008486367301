import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { SendMessage } from './SendMessage';
import { ChatService } from '../../models/chat/ChatService';
import { ChatMessage } from '../../models/chat/ChatMessage';

export class SendMessageHandler implements CommandHandler<SendMessage, ChatMessage> {
    constructor(private sessionStorage: SessionStorage, private chatService: ChatService) {}

    async handle(command: SendMessage) {
        return await this.sendMessage(command);
    }

    private async sendMessage(command: SendMessage) {
        const session = await this.sessionStorage.get();
        return await this.chatService.sendMessage(command.managerId, session.unit!!.association.id, session.unit!!.id, command.message, session.user!!.email);
    }
}
