import { Nullable } from '../../../_base/lang/Nullable';

export class BookingsVM {
    bookings: BookingInfo[] = [];
    isLoading = false;
    amenityId: Nullable<number> = null;
}

export interface BookingInfo {
    id: number;
    date: string;
    startAt: string;
    durationInMinutes: number;
    fee: number;
    status: string;
}
