import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { Upload } from './Upload';
import { FileUploader } from '../../infrastructure/uploader/FileUploader';

export class UploadHandler implements CommandHandler<Upload, string> {
    private maxSizeInBytes = 50 * 1024 * 1024;

    constructor(private uploader: FileUploader) {}

    async handle(command: Upload): Promise<string> {
        this.validate(command);
        return this.uploader.upload(command.file, command.onProgress);
    }

    private validate(command: Upload) {
        if (command.file.sizeInBytes > this.maxSizeInBytes) throw new Error('El archivo no puede superar los 50mb');
    }
}
