import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetBookings } from '@eroman/common/src/actions/amenities/booking/getBookings/GetBookings';
import { BookingsVM } from '@eroman/common/src/views/app/Bookings/BookingsVM';
import { CancelBooking } from '@eroman/common/src/actions/amenities/booking/cancelBooking/CancelBooking';
import { CannotCancelError } from '@eroman/common/src/models/bookings/CannotCancelError';

export interface AmenitiesInfoView {
    modelChanged(model);
    showErrorMessage(message: string);
    showSuccessMessage(message: string);
}

export class AmenityInfoPresenter {
    private model = new BookingsVM();

    constructor(private view: AmenitiesInfoView, private actionBus: ActionBus) {}

    async start(amenityId: number) {
        this.set({ amenityId });
        await this.loadBookings(amenityId);
    }

    private async loadBookings(amenityId: number) {
        const bookings = await this.actionBus.query(new GetBookings(amenityId));
        this.set({ bookings });
    }

    set<K extends keyof BookingsVM>(changes: Pick<BookingsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async cancel(bookingId: number) {
        this.set({ isLoading: true });
        try {
            await this.actionBus.exec(new CancelBooking(bookingId));
            await this.loadBookings(this.model.amenityId!!);
            this.set({ isLoading: false });
            this.view.showSuccessMessage('¡Reserva cancelada!');
        } catch (error: any) {
            if (error instanceof CannotCancelError) {
                this.view.showErrorMessage('El tiempo de cancelación se ha excedido');
            } else {
                throw error;
            }
        } finally {
            this.set({ isLoading: false });
        }
    }
}
