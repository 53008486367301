import { ClaimActivity } from './ClaimActivity';
import { JsonDateSerializer } from '../../../_base/time/JsonDateSerializer';

export class ClaimClosedActivity extends ClaimActivity {
    static fromJson(json: any, jsonDateSerializer: JsonDateSerializer) {
        return new ClaimClosedActivity(
            json.type,
            json.id,
            jsonDateSerializer.fromIso8601(json.date),
            json.claimId,
            json.actorType,
            json.actorId,
            json.actorName,
        );
    }
}
