import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetAnnouncements } from './GetAnnouncements';
import { Announcement } from '../../models/announcements/Announcement';
import { AnnouncementService } from '../../models/announcements/AnnouncementService';
import { SessionStorage } from '../../models/session/SessionStorage';

export class GetAnnouncementsHandler implements QueryHandler<GetAnnouncements, Announcement[]> {
    constructor(private announcementService: AnnouncementService, private sessionStorage: SessionStorage) {}

    async handle(query: GetAnnouncements): Promise<Announcement[]> {
        const session = await this.sessionStorage.get();
        return this.announcementService.getAnnouncements(session.unit!!.association.id);
    }
}
