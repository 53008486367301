import { Command } from '../../_base/actionBus/Command';

interface AuthCredentials {
    type: string;
}

export class RegularCredentials implements AuthCredentials {
    readonly type = 'RegularCredentials';
    constructor(public email: string, public password: string) {}
}

export class TokenCredentials implements AuthCredentials {
    readonly type = 'TokenCredentials';
    constructor(public token: string) {}
}

export class Login extends Command {
    constructor(public credentials: AuthCredentials) {
        super();
    }
}
