import React from 'react';
import styled from 'styled-components';
import { OptionVM, PollVM } from '@eroman/common/src/views/app/Polls/PollsVM';
import Progress from 'antd/es/progress/progress';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { ScrollableList } from '../../components/ScrollableList';
import { PollStates } from '@eroman/common/src/models/polls/Poll';

interface Props {
    item: PollVM;
    onClick: (option: OptionVM) => any;
    onSubmit: () => any;
    isEnabled: boolean;
    isEnded: boolean;
    text: string;
}

export const PollInfo: React.FC<Props> = (props) => {
    return (
        <Container>
            <_ScrollableList>
                <Row>
                    <h2>{props?.item?.title}</h2>
                    {
                        props?.item?.status === PollStates.CLOSED &&
                        <ClosedHeader>Esta votación ha sido cerrada por la administración</ClosedHeader>
                    }
                    <Description>{props?.item?.description}</Description>
                    <h5>La encuesta termina el {props?.item?.endDate}</h5>
                </Row>
                <form>
                    <OptionsContainer>
                        {props.item?.options.map((option, index) => (
                            <OptionContainer key={index}>
                                <p>{option.label}</p>
                                <ProgressContainer>
                                    <RadioInput
                                        type={'radio'}
                                        name={'option'}
                                        onClick={() => props.onClick(option)}
                                        checked={props.item?.selectedOption?.label === option.label || props.item.unitHasAlreadyVoted === option.label }
                                        disabled={props.isEnded || props.item.unitHasAlreadyVoted !== null || props.item.status === PollStates.CLOSED}
                                    />
                                    <_Progress
                                        percent={ props.item.unitHasAlreadyVoted !== null ? option.percentage : 0 }
                                        size={[220, 15]}
                                        status="active"
                                        showInfo={props.item.unitHasAlreadyVoted !== null || props.isEnded }
                                    />
                                    {
                                        props.isEnded && <p style={{ height: '25px', paddingTop: '3.5px', fontWeight: 600, fontSize: '14px' }}> {option.votes} </p>
                                    }
                                </ProgressContainer>
                            </OptionContainer>
                        ))}
                    </OptionsContainer>
                    <ButtonContainer>
                        <_PrimaryButton onClick={props.onSubmit} isDisabled={!props.isEnabled}> {props.text} </_PrimaryButton>
                    </ButtonContainer>
                </form>
            </_ScrollableList>
        </Container>
    );
};

const ClosedHeader = styled.h4`
    color: #DB640F;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
`;

const Description = styled.p`
  margin-top: 10px;    
`;

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const RadioInput = styled.input`
  border: 0;
  width: 25px;
  height: 25px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;

const _Progress = styled(Progress)`
    margin-bottom: 0 !important;
    height: 25px;
`;

const _PrimaryButton = styled(PrimaryButton)`
  text-align: center;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
`;

const Container = styled.div`
  height: 100%;
  padding: 10px 12px;
`;

const OptionContainer = styled.div`
  width: 100%;
  padding: 5px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h2 {
    text-align: center;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  
  input {
    font-size: larger;
  }
`;

const _ScrollableList = styled(ScrollableList)`
    height: 100% !important;
`;
