import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetUnitPaymentStatus, GetUnitPaymentStatusResult } from './GetUnitPaymentStatus';
import { UnitService } from '../../models/units/UnitService';
import { SessionStorage } from '../../models/session/SessionStorage';

export class GetUnitPaymentStatusHandler implements QueryHandler<GetUnitPaymentStatus, GetUnitPaymentStatusResult> {
    constructor(private unitService: UnitService, private sessionStorage: SessionStorage) {}

    async handle(query: GetUnitPaymentStatus): Promise<GetUnitPaymentStatusResult> {
        const session = await this.sessionStorage.get();
        return await this.unitService.getUnitPaymentStatus(session.unit!.id);
    }
}
