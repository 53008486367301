import { CreateClaimVM } from './CreateClaimVM';
import { ActionBus } from '../../../../_base/actionBus/ActionBus';
import { GetClaimSubjects } from '../../../../actions/claims/GetClaimSubjects';
import { CreateClaim } from '../../../../actions/claims/CreateClaim';
import { handleValidationErrors } from '../../../../_base/validation/catchValidationErrors';
import { FileInfo } from '../../../../models/general/FileInfo';
import { Nullable } from '../../../../_base/lang/Nullable';
import { EventBus } from '../../../../_base/eventBus/EventBus';
import { ClaimCreated } from '../../../../events/claims/ClaimCreated';

export interface CreateClaimView {
    modelChanged(model: CreateClaimVM);
    showSuccessMessage();
}

export class CreateClaimPresenter {
    private model = new CreateClaimVM();

    constructor(private view: CreateClaimView, private actionBus: ActionBus, private eventBus: EventBus) {}

    async start() {
        this.set({ isLoading: true });
        const subjects = await this.actionBus.query(new GetClaimSubjects());
        this.set({ subjects: subjects, isLoading: false });
    }

    set<K extends keyof CreateClaimVM>(changes: Pick<CreateClaimVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async submit() {
        try {
            this.set({ isLoading: true });
            let command = new CreateClaim(this.model.subjectId, this.model.description, this.model.attachments || []);
            await this.actionBus.exec(command);
            await this.eventBus.post(new ClaimCreated());
            this.view.showSuccessMessage();
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages(), isLoading: false }));
        }
    }

    addAttachment(newFile: Nullable<FileInfo>) {
        if (newFile !== null) {
            this.set({ attachments: [...this.model.attachments, newFile!!] });
        }
    }

    removeAttachment(name: string) {
        const attachment = this.model.attachments.filter(at => at.name === name);
        const index = this.model.attachments.indexOf(attachment[0]);
        this.model.attachments.splice(index, 1);
        this.set({ attachments: this.model.attachments });
    }
}
