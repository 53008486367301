import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { Session } from '@eroman/common/src/models/session/Session';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { SelectUnit } from '@eroman/common/src/actions/units/SelectUnit';
import { UserInfo } from '@eroman/common/src/models/auth/UserInfo';

export class UnitPresenter {
    constructor(private navigation: WebNavigator, private sessionStorage: SessionStorage, private actionBus: ActionBus) {}

    async start(currentPath: string) {
        const unitId = parseInt(this.navigation.currentRoute?.params?.id);
        await this.selectUnit(unitId);
        this.navigateToResolvedRoute(currentPath);
    }

    async selectUnit(unitId: number) {
        const session = await this.sessionStorage.get();
        this.validateUserUnit(session, unitId);
        await this.actionBus.exec(new SelectUnit(unitId));
    }

    private navigateToResolvedRoute(path: string) {
        const resolvedPath = path.replace(/\/units\/\d+/, '');
        this.navigation.navigateToPath(resolvedPath);
    }

    private validateUserUnit(session: Session, unitId: number) {
        if (!this.isOwnerOrTenant(session.user!, unitId)) this.navigation.navigateToAuth();
    }

    private isOwnerOrTenant(user: UserInfo, unitId: number): boolean {
        return user.units.some(unit => unit.id === unitId);
    }
}
