import { LocalDate } from '../../_base/time/LocalDate';
import { Money } from '../general/Money';

export class PaymentData {
    constructor(
        public id: number,
        public date: LocalDate,
        public amount: Money,
    ) {}

    static fromJson(json: any): PaymentData {
        return new PaymentData(
            json.id,
            LocalDate.fromISO8601String(json.date),
            Money.of(json.amount),
        );
    }
}
