import React from 'react';
import styled from 'styled-components';
import { UsefulContactsVM } from '@eroman/common/src/views/app/UsefulContacts/UsefulContactsVM';
import AppContext from '../../lib/AppContext';
import { Loader } from '../../components/Loader';
import { styles } from '../../styles';
import { ScrollableList } from '../../components/ScrollableList';
import { SectionHeader } from '../../components/SectionHeader';
import phoneIcon from './phonesIcon.png';
import { HtmlContent } from '../../components/HtmlContent';
import { UsefulContactsView } from '@eroman/common/src/views/app/UsefulContacts/UsefulContactsPresenter';

export class UsefulContactsPage extends React.Component implements UsefulContactsView {
    private presenter = AppContext.presenters.usefulPhoneNumbers(this);

    state = {
        model: new UsefulContactsVM(),
        isLoading: false,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    modelChanged(model: UsefulContactsVM) {
        this.setState({ model });
    }

    renderUsefulContacts() {
        return <>
            {
                this.state.model.content.length > 0 &&
                <ScrollableList>
                    <SectionHeader icon={phoneIcon} title={'Información de contacto'}/>
                    <HtmlContent content={this.state.model.content}/>
                </ScrollableList> ||
                <NoUsefulContacts>No hay teléfonos útiles</NoUsefulContacts>
            }
        </>;

    }

    render() {
        return (
            <Box>
                {this.state.model.isLoading ? <Loader/> : this.renderUsefulContacts()}
            </Box>
        );
    }
}

const Box = styled.div`
  display: flex;
  height: 500px;
  flex-grow: 1;
  max-width: 50vw;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const NoUsefulContacts = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;
