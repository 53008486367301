import { LocalDateTime } from '../../_base/time/LocalDateTime';

export class Poll {
    constructor(
        public id: number,
        public title: string,
        public description: string,
        public status: PollStates,
        public associationId: number,
        public options: Map<string, number[]>,
        public endDate: LocalDateTime
    ) {
    }

    getTotalVotes(): number {
        let totalVotes = 0;
        this.options.forEach((userIds) => {
            totalVotes += userIds.length;
        });
        return totalVotes;
    }

    static fromJson(json: any): Poll {
        const options = new Map<string, number[]>();
        for (const key in json.options) {
            options.set(key, json.options[key]);
        }

        return new Poll(
            json.id,
            json.title,
            json.description,
            json.status as PollStates,
            json.associationId,
            options,
            LocalDateTime.fromISO8601String(json.endDate),
        );
    }
}

export enum PollStates {
    ONGOING = 'Ongoing',
    CLOSED = 'Closed'
}
