import { PaymentProvider } from './PaymentProvider';
import { PaymentProviders } from './PaymentProviders';
import { Nullable } from '../../../_base/lang/Nullable';

export class BankTransferProvider extends PaymentProvider {
    constructor(
        public accountHolder: string,
        public taxId: string,
        public transferNumber: string,
        public transferAlias: Nullable<string> = null,
    ) {
        super(PaymentProviders.BankTransfer);
    }

    static fromJson(json: any) {
        return new BankTransferProvider(json.accountHolder, json.taxId, json.transferNumber, json.transferAlias);
    }
}
