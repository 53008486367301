import { Command } from './Command';
import { Query } from './Query';
import { QueryHandler } from './QueryHandler';
import { CommandHandler } from './CommandHandler';

export class ActionBus {
    private commandHandlers: Map<any, CommandHandler<any, any>> = new Map();
    private queryHandlers: Map<any, QueryHandler<any, any>> = new Map();

    registerCommand<T extends Command<TResult>, TResult>(commandType: new (...args:any[]) => T, commandHandler: CommandHandler<T, TResult>) {
        this.commandHandlers.set(commandType, commandHandler);
    }

    registerQuery<T extends Query<TResult>, TResult>(queryType: new (...args:any[]) => T, queryHandler: QueryHandler<T, TResult>) {
        this.queryHandlers.set(queryType, queryHandler);
    }

    async exec<T extends Command<any>>(command: T): Promise<CommandResult<T>> {
        const handler = this.commandHandlers.get(command.constructor);
        if (!handler) {
            throw new Error('Command handler not registered for command ' + command.constructor.name);
        }
        return await handler.handle(command);
    }

    async query<T extends Query<any>>(query: T): Promise<QueryResult<T>> {
        const handler = this.queryHandlers.get(query.constructor);
        if (!handler) {
            throw new Error('Command handler not registered for query ' + query.constructor.name);
        }
        return await handler.handle(query);
    }
}

export type QueryResult<T> = T extends Query<infer TResult> ? TResult : any;

export type CommandResult<T> = T extends Command<infer TResult> ? TResult : any;
