import { AmenitiesService } from '../../models/amenities/AmenitiesService';
import { Amenity } from '../../models/amenities/Amenity';
import { HttpClient } from '../../_base/http/HttpClient';
import { LocalDate } from '../../_base/time/LocalDate';
import { Slot } from '../../models/amenities/Slot';
import { Booking } from '../../models/bookings/Booking';
import { CannotCancelError } from '../../models/bookings/CannotCancelError';
import { MaxWeekdayBookingsReachedError } from '../../models/bookings/MaxWeekdayBookingsReachedError';
import { MaxWeekendBookingsReachedError } from '../../models/bookings/MaxWeekendBookingsReachedError';

export class HttpAmenitiesService implements AmenitiesService {
    constructor(private httpClient: HttpClient) {}

    async getAmenities(associationId: number): Promise<Amenity[]> {
        const response = await this.httpClient.get(`/amenities/${associationId}`);
        return response.body.amenities.map(amenity => Amenity.fromJson(amenity));
    }

    async getDisabledAmenities(unitId: number): Promise<number[]> {
        const response = await this.httpClient.get(`/units/${unitId}/amenities/disabled`);
        return response.body.amenities;
    }

    async getAvailableBookingDays(amenityId: number): Promise<LocalDate[]> {
        const response = await this.httpClient.get(`/amenities/${amenityId}/availability`);
        return response.body.availableBookingDays.map(date => LocalDate.fromISO8601String(date));
    }

    async searchDateAvailableSlots(amenityId: number, date: string): Promise<Slot[]> {
        const response = await this.httpClient.get(`/amenities/${amenityId}/searchSlots?date=${date}`);
        return response.body.slots.map(slot => {
            slot.startAt = this.withFixedStartAtTimeZone(slot.startAt as string);
            return Slot.fromJson(slot);
        });
    }

    private withFixedStartAtTimeZone(startAt: string): string {
        const tzOffset = -(new Date()).getTimezoneOffset() / 60;
        const parts = startAt.split(':');
        const [h, m] = parts.map((part: string) => Number(part));
        return (h + tzOffset).toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0');
    }

    async bookAmenity(amenityId: number, date: string, startAt: string, durationInMinutes: number, unitId: number) {
        try {
            return await this.httpClient.post(`/amenities/${amenityId}/book`, { date, startAt, durationInMinutes, unitId });
        } catch (e: any) {
            if (e.status === 400 && e.data.type == 'MaxWeekdayBookingsReachedError') { throw new MaxWeekdayBookingsReachedError();}
            if (e.status === 400 && e.data.type == 'MaxWeekendBookingsReachedError') { throw new MaxWeekendBookingsReachedError();}
            throw e;
        }
    }

    async getBookings(amenityId: number, unitId: number): Promise<Booking[]> {
        const response = await this.httpClient.get(`/bookings/${amenityId}/${unitId}`);
        return response.body.bookings.map(booking => Booking.fromJson(booking));
    }

    async cancelBooking(bookingId: number): Promise<number> {
        try {
            const response = await this.httpClient.post(`booking/${bookingId}/cancel`, {});
            return response.body.bookingId;
        } catch (e: any) {
            if (e.status === 400 && e.data.type === 'CannotCancelError') { throw new CannotCancelError('CannotCancelError'); }
            throw e;
        }
    }
}
