import React from 'react';
import styled from 'styled-components';
import arrow from './arrow_right.png';
import { styles } from '../../../../styles';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { showAlert } from '../../../../components/modals/AlertModal';

export const MonthHeader: React.FC<Props> = (props) => {
    const showUnavailableDownload = (e) => {
        e.preventDefault();
        showAlert('Descargar liquidación', 'La liquidación aún no se encuentra disponible para descargar');
    };
    return (
        <Container>
            <MonthName>Vto. {props.month}</MonthName>
            <StatementDetailButton>
                {props.expenseDownloadUrl && <a href={props.expenseDownloadUrl!!} target="_blank" rel="noreferrer">Ver liquidaci&oacute;n<i/></a>}
                {!props.expenseDownloadUrl && <a href="#" onClick={showUnavailableDownload}>Ver liquidaci&oacute;n<i/></a>}
            </StatementDetailButton>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 15px 12px;
  border-bottom: 1px solid #DDE4F0;
`;

const MonthName = styled.div`
  font-weight: ${styles.fonts.weight.bold};
  font-size: 22px;
  color: #42557B;
`;

const StatementDetailButton = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: #178BFF;
    font-size: ${styles.fonts.size.small};
    font-weight: ${styles.fonts.weight.bold};

    i {
      width: 10px;
      height: 9px;
      background-image: url(${arrow});
      background-position-y: 0;
      background-size: 10px 18px;
      display: inline-block;
      margin-left: 3px;
    }
  }


  &:hover a {
    color: #0038BC;
  }

  &:hover i {
    background-position-y: 9px;
  }

`;

interface Props {
    month: string,
    expenseDownloadUrl: Nullable<string>,
}
