import { HttpClient } from '../../_base/http/HttpClient';
import { ClaimService } from '../../models/claims/ClaimService';
import { ClaimSubject } from '../../models/claims/ClaimSubject';
import { Claim } from '../../models/claims/Claim';
import { JsonDateSerializer } from '../../_base/time/JsonDateSerializer';
import { ClaimActivity } from '../../models/claims/activity/ClaimActivity';
import { claimActivityFromJson } from '../../models/claims/activity/claimActivityFromJson';
import { FileInfo } from '../../models/general/FileInfo';

export class HttpClaimService implements ClaimService {
    constructor(private httpClient: HttpClient, private jsonDateSerializer: JsonDateSerializer) {
    }

    async getSubjects(): Promise<ClaimSubject[]> {
        const response = await this.httpClient.get('/claimSubjects');
        return response.body.subjects.map(json => ClaimSubject.fromJson(json));
    }

    async createClaim(currentUnitId: number, subjectId: number, message: string, attachments: FileInfo[]): Promise<void> {
        const body = {
            subjectId: subjectId,
            description: message,
            unitId: currentUnitId,
            attachments: attachments,
        };

        await this.httpClient.post('/claims', body);
    }

    async getClaims(unitId: number): Promise<Claim[]> {
        const response = await this.httpClient.get(`/claims/${unitId}`);
        return response.body.claims.map(claim => Claim.fromJson(claim, this.jsonDateSerializer));
    }

    async getClaimActivity(claimId: number): Promise<ClaimActivity[]> {
        const response = await this.httpClient.get(`/claims/${claimId}/activity`);
        return response.body.activities.map(a => claimActivityFromJson(a, this.jsonDateSerializer));
    }

    async commentClaim(claimId:number, message:string, attachments: FileInfo[]): Promise<void>{
        const body = {
            claimId: claimId,
            message: message,
            attachments: attachments,
        };
        await this.httpClient.post(`/claims/${claimId}/comment`, body);
    }
}
