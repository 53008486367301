import { ErrorMap } from '@eroman/common/src/_base/validation/ErrorMap';

export class PasswordVM {
    email = '';
    validationCode = '';
    password = '';
    confirmationPassword = '';
    isLoading = false;
    isValidationCodeSent = false;
    errors: ErrorMap = {};
}
