import { ManagerInfoVM } from './ManagerInfoVM';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { GetUnitManager } from '../../../actions/units/GetUnitManager';
import { Manager } from '../../../models/managers/Manager';

export interface ManagerInfoView {
    modelChanged(model: ManagerInfoVM);
}

export class ManagerInfoPresenter {
    private model = new ManagerInfoVM();

    constructor(private view: ManagerInfoView, private actionBus: ActionBus) {}

    async start() {
        await this.getAssociationManagerInfo();
    }

    private async getAssociationManagerInfo() {
        this.set({ isLoading: true });
        const manager = await this.actionBus.query(new GetUnitManager());
        this.show(manager);
    }

    private show(manager: Manager) {
        this.set({
            name: manager.name,
            website: manager.website ?? '',
            workingHours: manager.workingHours ?? '',
            address: manager.contactInfo.address ? manager.contactInfo.address.street + ', ' + manager.contactInfo.address.city : '',
            emails: manager.contactInfo.emails.join(', ') ?? '',
            phones: manager.contactInfo.phones.join(', ') ?? '',
            whatsapp: manager.contactInfo.whatsappNumber ?? '',
            isLoading: false,
        });
    }

    set<K extends keyof ManagerInfoVM>(changes: Pick<ManagerInfoVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
