import { Nullable } from '../../_base/lang/Nullable';
import { ContactInfo } from '../general/ContactInfo';
import { ManagerInfo } from './ManagerInfo';

export class Manager {
    constructor(
        public id: number,
        public name: string,
        public workingHours: Nullable<string>,
        public website: Nullable<string>,
        public contactInfo: ContactInfo,
    ) {
    }

    info() {
        return new ManagerInfo(this.id, this.name);
    }

    static fromJson(json: any): Manager {
        return new Manager(
            json.id,
            json.name,
            json.workingHours,
            json.website,
            ContactInfo.fromJson(json.contactInfo),
        );
    }
}
