import { Money } from '../general/Money';
import { LocalDate } from '../../_base/time/LocalDate';
import { YearMonth } from '../../_base/time/YearMonth';
import { PaymentStatuses } from './PaymentStatuses';
import { Nullable } from '../../_base/lang/Nullable';
import { PaymentSources } from './PaymentSources';

export class UnitPayment {
    constructor(
        public id: number,
        public amount: Money,
        public date: LocalDate,
        public period: Nullable<YearMonth>,
        public transactionNumber: Nullable<string>,
        public status: PaymentStatuses,
        public source: PaymentSources,
    ) {}

    static fromJson(json: any) {
        return new UnitPayment(
            json.id,
            Money.of(json.amount),
            LocalDate.fromISO8601String(json.date),
            json.period ? YearMonth.fromISO8601String(json.period) : null,
            json.transactionNumber || null,
            json.status,
            json.source,
        );
    }

    toJson() {
        return {
            id: this.id,
            amount: this.amount.plainString(),
            date: this.date.toISO8601String(),
            period: this.period?.toISO8601String(),
            transactionNumber: this.transactionNumber,
            status: this.status,
            source: this.source,
        };
    }
}
