import React from 'react';
import styled from 'styled-components';
import redepagosIcon from './redepagos.png';
import { SectionHeader } from '../../../../components/SectionHeader';
import { CircularArrowButton } from '../CircularArrow/CircularArrowButton';
import { styles } from '../../../../styles';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';

export const Redepagos: React.FC<Props> = (props) => {
    return (
        <>
            <Header>
                <SectionHeader icon={redepagosIcon} title='Pagar con REDEPAGOS' color='#119611' />
                {(props.url != null) ?
                    <CircularArrowButton color="#119611" href={props.url} target="_blank" /> :
                    <CircularArrowButton color="#119611" isDisabled={true}/>
                }
            </Header>
            <Description>
                Mercadopago, Pagofacil, Rapipago, Pagomiscuentas, Redlink o D&eacute;bito directo. F&aacute;cil y seguro.
                <span> Recibo electr&oacute;nico autom&aacute;tico.</span>
            </Description>
        </>
    );
};

const Header = styled.div`
  display: flex;
  margin: 20px 20px 0;
  align-items: center;
  justify-content: space-between;
`;

const Description = styled.p`
  margin: 0 60px;
  font-size: ${styles.fonts.size.regular};
  font-weight: ${styles.fonts.weight.regular};
  
  span {
    font-weight: 400;
  }
`;

interface Props {
    url: Nullable<string>;
}
