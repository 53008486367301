import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { styles } from '../../../styles';

export const FormTextAreaInput: React.FC<Props> = (props) => {
    return <_TextArea
        placeholder={props.placeholder}
        onChange={(e) => props.onChangeText?.(e.target.value)}
        value={props.value}
        className={classNames(props.className)}
        maxLength={500}
    />;
};

const _TextArea = styled.textarea`
  color: #494949;
  font-family: 'Nunito', sans-serif;
  font-size: ${styles.fonts.size.xsmall};
  line-height: 16px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #8692A6;
  width: 100%;
  resize: none;
  
  &.small {
    height: 100px;
  }

  &:focus { border-color: #1565D8; }

  &.has-error {
    color: #E15201;
    border-color: #E15201;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D9E4FF;
  }
`;

interface Props {
    hasError?: boolean;
    value?: string;
    placeholder?: string;
    onChangeText?: ((text: string) => void);
    className?: string;
}
