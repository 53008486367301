import { WebNavigator } from '../../../lib/navigation/WebNavigator';
import { MainMenuVM } from './MainMenuVM';
import { FeatureConfigClient } from '@eroman/common/src/lib/featureConfig/FeatureConfigClient';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { Logout } from '@eroman/common/src/actions/auth/Logout';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';

export interface MainMenuView {
    modelChanged(model: MainMenuVM)
}

export class MainMenuPresenter {
    private items!: MenuItem[];

    constructor(
        private view: MainMenuView,
        private navigator: WebNavigator,
        private featureConfigClient: FeatureConfigClient,
        private actionBus: ActionBus,
        private sessionStorage: SessionStorage
    ) {
        this.sessionStorage.sessionChanged.subscribe(this, this.updateMenuOnSessionChange.bind(this));
    }

    async updateMenuOnSessionChange() {
        await this.start();
    }

    async start() {
        const session = await this.sessionStorage.get();
        const showClaim = session?.user?.units.filter(u => u.id === session.currentUnitId)[0].association.showClaim || false;
        const showChat = session?.user?.units.filter(u => u.id === session.currentUnitId)[0].association.showChat || false;
        this.initializeItems(showClaim, showChat);
        this.view.modelChanged({ items: this.getItemsVM() });
    }

    initializeItems(showClaim: boolean, showChat: boolean = false) {
        this.items = [
            { label: 'Expensas', routes: ['expenses'] },
            { label: 'Mis pagos', routes: ['payments', 'payment-detail'] },
            ...this.featureConfigClient.hasFeature('amenities') ? [
                { label: 'Amenities', routes: ['amenities'] },
            ] : [],
            ...this.featureConfigClient.hasFeature('claims') && showClaim ? [
                { label: 'Reclamos', routes: ['claims', 'claim-detail'] },
            ] : [],
            { label: 'Comunicados', routes: ['announcements', 'announcement-detail'] },
            ...this.featureConfigClient.hasFeature('documents') ? [
                { label: 'Documentos', routes: ['documents'] },
            ] : [],
            { label: 'Votaciones', routes: ['votaciones'] },
            ... showChat ? [
                { label: 'Chat', routes: ['chat'] },
            ] : [],
            { label: 'Teléfonos útiles', routes: ['phone-numbers'] },
            { label: 'Cerrar sesión', routes: ['login'] },
        ];
    }

    getItemsVM() {
        return this.items.map(i => (
            { label: i.label, selected: this.isSelected(i.routes) }
        ));
    }

    private isSelected(routes) {
        const currentRoute = this.navigator.currentRoute?.name;
        return routes && routes.contains(currentRoute);
    }

    async select(itemLabel: string) {
        const item = this.items.single(i => i.label === itemLabel);
        if (item.label === 'Cerrar sesión') await this.actionBus.exec(new Logout());
        if (!item.routes) return;
        this.navigator.navigate(item.routes.first());
    }
}

interface MenuItem {
    label: string;
    routes?: string[];
}
