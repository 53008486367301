import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { EventBus } from '../../../_base/eventBus/EventBus';
import { CurrentUnitChanged } from '../../../events/CurrentUnitChanged';
import { GetUnitPaymentStatus, GetUnitPaymentStatusResult } from '../../../actions/units/GetUnitPaymentStatus';
import { PaymentProvider } from '../../../models/associations/paymentProviders/PaymentProvider';
import { PaymentProviders } from '../../../models/associations/paymentProviders/PaymentProviders';
import { Nullable } from '../../../_base/lang/Nullable';
import { BankTransferProvider } from '../../../models/associations/paymentProviders/BankTransferProvider';
import { Expense } from '../../../models/expenses/Expense';
import { Money } from '../../../models/general/Money';
import { PaymentData } from '../../../models/payments/PaymentData';
import { BalanceTypes, ExpensesVM, ViewStates } from './ExpensesVM';
import { DatesFormatter } from '../../lib/formatters/DatesFormatter';
import { MoneyFormatter } from '../../lib/formatters/MoneyFormatter';
import { GetRedepagosPayUrl } from '../../../actions/payments/GetRedepagosPayUrl';
import { DownloadUrlBuilder } from '../../lib/DownloadUrlBuilder';
import { GetUnitCVUNumber } from '../../../actions/payments/GetUnitCVUNumber';
import { SessionStorage } from '../../../models/session/SessionStorage';

export interface ExpensesView {
    modelChanged(model: ExpensesVM);
}

export class ExpensesPresenter {
    private model = new ExpensesVM();

    constructor(private view: ExpensesView, private actionBus: ActionBus, private eventBus: EventBus, private downloadUrlBuilder: DownloadUrlBuilder, private session: SessionStorage) {
        this.eventBus.subscribe(this, CurrentUnitChanged, this.getUnitPaymentStatus.bind(this));
    }

    async start() {
        await this.getUnitPaymentStatus();
    }

    async showResetPasswordModal() {
        const session = await this.session.get();
        return session.suggestPasswordReset;
    }

    private async getUnitPaymentStatus() {
        this.set({ state: ViewStates.Loading });
        const result = await this.actionBus.query(new GetUnitPaymentStatus());
        if (result.expense == null) return this.showNoExpenses();
        await this.showExpenseInfo(result);
    }

    private async showExpenseInfo(result: GetUnitPaymentStatusResult) {
        const redepagosPayUrl = await this.getRedepagosPayUrl(result.paymentProviders);
        const CVUNumber = await this.getCVUNumber();
        const hasCVUTransfer = this.hasCVUTransfer(result.paymentProviders);

        this.set({
            state: this.getViewPaymentState(result),
            expense: this.expenseVM(result.expense!!),
            paymentInfo: this.paymentInfoVM(result),
            showRedepagos: this.hasRedepagos(result.paymentProviders),
            showCVUTransfer: hasCVUTransfer,
            redepagosPayUrl,
            showCash: this.hasCashProvider(result.paymentProviders),
            bankTransferProvider: this.getBankTransfer(result.paymentProviders),
            CVUNumber,
            showOtherPaymentMethods: !hasCVUTransfer,
        });
    }

    private showNoExpenses() {
        this.set({
            state: ViewStates.NoExpenses,
            expense: null,
            paymentInfo: null,
            showRedepagos: false,
            redepagosPayUrl: null,
            bankTransferProvider: null,
        });
    }

    private async getRedepagosPayUrl(paymentProviders: PaymentProvider[]): Promise<Nullable<string>> {
        if (!this.hasRedepagos(paymentProviders)) return null;
        try {
            return await this.actionBus.query(new GetRedepagosPayUrl());
        } catch (e: any) {
            return null;
        }
    }

    private async getCVUNumber(): Promise<Nullable<string>> {
        return await this.actionBus.query(new GetUnitCVUNumber());
    }

    private getViewPaymentState(result: GetUnitPaymentStatusResult) {
        return result.payments.length > 0 ? ViewStates.Payments : ViewStates.NoPayments;
    }

    private hasRedepagos(paymentProviders: PaymentProvider[]) {
        return paymentProviders.any(p => p.type === PaymentProviders.Redepagos);
    }

    private hasCVUTransfer(paymentProviders: PaymentProvider[]) {
        return paymentProviders.any(p => p.type === PaymentProviders.CVUTransfer);
    }

    private getBankTransfer(paymentProviders: PaymentProvider[]) {
        return paymentProviders.firstOrNull(p => p.type === PaymentProviders.BankTransfer) as Nullable<BankTransferProvider>;
    }

    private hasCashProvider(paymentProviders: PaymentProvider[]) {
        return paymentProviders.any(p => p.type === PaymentProviders.Cash);
    }

    private expenseVM(expense: Expense) {
        return {
            period: DatesFormatter.yearMonth(expense.period).toLowerCase(),
            dueDate1: DatesFormatter.shortDate(expense.dueDate1),
            dueDate2: DatesFormatter.shortDate(expense.dueDate2),
            amount1: MoneyFormatter.format(expense.amount1),
            amount2: MoneyFormatter.format(expense.amount2),
            downloadUrl: expense.file ? this.downloadUrlBuilder.buildFor(expense.file) : null,
        };
    }

    set<K extends keyof ExpensesVM>(changes: Pick<ExpensesVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }

    private paymentInfoVM(result: GetUnitPaymentStatusResult) {
        if (result.expense === null || result.payments.length === 0) return null;
        return {
            dueDateNumberToPay: result.expense.dueDateNumberToPay === 1 ? '1er' : '2do',
            dueDate: DatesFormatter.shortDate(result.expense.dueDateToPay),
            amount: MoneyFormatter.format(result.expense.amountToPay),
            balanceType: this.getBalanceType(result.balance),
            balance: MoneyFormatter.format(result.balance.abs()),
            payments: result.payments.map(p => this.toPaymentVM(p)),
        };
    }

    private getBalanceType(balance: Money) {
        if (balance.isZero()) return BalanceTypes.Zero;
        return balance.isPositive() ? BalanceTypes.Positive : BalanceTypes.Negative;
    }

    private toPaymentVM(payment: PaymentData) {
        return {
            id: payment.id,
            date: DatesFormatter.shortDate(payment.date),
            amount: MoneyFormatter.format(payment.amount.negated()),
        };
    }
}
