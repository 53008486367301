import { BookingStatus } from './BookingStatus';

export class Booking {
    constructor(
        public id: number,
        public date: string,
        public startAt: string,
        public durationInMinutes: number,
        public fee: number,
        public status: BookingStatus
    ) {}

    static fromJson(json: any) {
        const [h, m] = json.startAt.split(':').map(p => parseInt(p));
        const d = new Date(Date.UTC(2000, 5, 12, h, m));
        const startAt = d.getHours().toString().padZeros(2) + ':' + d.getMinutes().toString().padZeros(2);
        return new Booking(json.id, json.date, startAt, json.durationInMinutes, json.fee, json.status);
    }
}
