import { YearMonth } from '@eroman/common/src/_base/time/YearMonth';
import { LocalDate } from '@eroman/common/src/_base/time/LocalDate';
import { LocalDateTime } from '../../../_base/time/LocalDateTime';

export class DatesFormatter {

    static yearMonth(value: YearMonth): string {
        const monthName = this.monthName(value.month);
        return `${monthName} ${value.year}`;
    }

    static fullDate(value: LocalDate): string {
        return `${this.format(value.day)}/${this.format(value.month)}/${value.year}`;
    }

    static fullDateTime(value: LocalDateTime): string {
        return `${this.format(value.day)}/${this.format(value.month)}/${this.format(value.year)} ${this.format(value.hours)}:${this.format(value.minutes)}:${this.format(value.seconds)}`;
    }

    static fullDateTimeWithoutSeconds(value: LocalDateTime): string {
        return `${this.format(value.day)}/${this.format(value.month)}/${this.format(value.year)} ${this.format(value.hours)}:${this.format(value.minutes)}`;
    }

    static shortDate(value: LocalDate): string {
        return `${this.format(value.day)}/${this.format(value.month)}`;
    }

    static fileDate(value: LocalDate): string {
        return `${value.year}${this.format(value.month)}${this.format(value.day)}`;
    }

    static hourMinutesAndSecondTime(value: LocalDateTime): string {
        return `${value.hours - 3}:${this.format(value.minutes)}:${this.format(value.seconds)}`;
    }

    static hourAndMinutes(value: LocalDateTime): string {
        return `${value.hours - 3}:${this.format(value.minutes)}`;
    }

    static monthName(month: number): string {
        switch (month) {
            case 1: return 'Enero';
            case 2: return 'Febrero';
            case 3: return 'Marzo';
            case 4: return 'Abril';
            case 5: return 'Mayo';
            case 6: return 'Junio';
            case 7: return 'Julio';
            case 8: return 'Agosto';
            case 9: return 'Septiembre';
            case 10: return 'Octubre';
            case 11: return 'Noviembre';
            case 12: return 'Diciembre';
            default: throw new Error(`Invalid month: ${month}`);
        }
    }

    private static format(value: number) {
        return value.toString().padZeros(2);
    }
}
