import { AuthService, LoginResponse, ResetPasswordResponse } from '../../models/auth/AuthService';
import { HttpClient } from '../../_base/http/HttpClient';
import { InvalidCredentialsError } from '../../models/auth/InvalidCredentialsError';
import { httpErrorDecorator } from '../../_base/http/errors/httpErrorDecorator';
import { UserInfo } from '../../models/auth/UserInfo';
import { HttpResponse } from '../../_base/http/HttpResponse';
import { UserNotFoundError } from '../../models/auth/UserNotFoundError';

export class HttpAuthService implements AuthService {
    private httpClient: HttpClient;
    private readonly errorMappings = {
        'InvalidCredentialsError': InvalidCredentialsError,
        'UserNotFoundError': UserNotFoundError,
    };

    constructor(httpClient: HttpClient) {
        this.httpClient = httpErrorDecorator(httpClient, this.errorMappings);
    }

    async updateDeviceToken(deviceToken: string): Promise<void> {
        await this.httpClient.put('/users', { deviceToken });
    }

    async login(email: string, password: string): Promise<LoginResponse> {
        const loginResponse = await this.httpClient.post('/login', {
            credentials: {
                type: 'RegularCredentials',
                email,
                password,
            },
        });
        return await this.create(loginResponse);
    }

    async resetPassword(password: string): Promise<ResetPasswordResponse> {
        const resetPasswordResponse = await this.httpClient.post('/resetPassword', { password });
        return {
            apiSessionToken: resetPasswordResponse.body.sessionToken,
        };
    }

    async changePassword(code: string, password: string): Promise<void> {
        await this.httpClient.post('/changePassword', { code, password });
    }

    async forgotPassword(email: string): Promise<void> {
        await this.httpClient.post('/forgotPassword', { email });
    }

    async dontSuggestPasswordReset(): Promise<void> {
        await this.httpClient.post('users/passwordReset', {});
    }

    async loginWithToken(token: string): Promise<LoginResponse> {
        const loginResponse = await this.httpClient.post('/login', { credentials: { type: 'TokenCredentials', token } });
        return await this.create(loginResponse);
    }

    private async create(loginResponse: HttpResponse<any>): Promise<LoginResponse> {
        const apiSessionToken = loginResponse.body.sessionToken;
        const user = await this.fetchMe(apiSessionToken);
        return {
            apiSessionToken,
            user,
            unitId: loginResponse.body.unitId,
            suggestPasswordReset: !!loginResponse.body.suggestPasswordReset,
        };
    }

    async me(): Promise<UserInfo> {
        return this.fetchMe();
    }

    private async fetchMe(sessionToken?): Promise<UserInfo> {
        const headers = {};
        if (sessionToken) {
            headers['Authorization'] = `Bearer ${sessionToken}`;
        }
        const meResponse = await this.httpClient.get('/me', headers);
        return UserInfo.fromJson(meResponse.body);
    }
}
