import { Address } from '../general/Address';

export class Association {
    constructor(
        public id: number,
        public name: string,
        public address: Address,
        public showClaim: boolean,
        public showChat: boolean
    ) {}

    static fromJson(json: any): Association {
        return new Association(json.id, json.name, Address.fromJson(json.address), json.showClaim, json.showChat);
    }
}
