import cashIcon from './cashIcon.png';
import React from 'react';
import { SectionHeader } from '../../../../components/SectionHeader';
import styled from 'styled-components';
import { styles } from '../../../../styles';

export const Cash: React.FC = () => {
    return <Container>
        <SectionHeader icon={cashIcon} title={'Pagar en efectivo'}/>
        <Description>Pago en efectivo en la administraci&oacute;n</Description>
    </Container>;
};

const Container = styled.div`
  margin: 20px 20px;
`;

const Description = styled.p`
  margin: 0 40px;
  font-size: ${styles.fonts.size.regular};
`;