import { ClaimActivity } from './ClaimActivity';
import { ClaimOpenedActivity } from './ClaimOpenedActivity';
import { JsonDateSerializer } from '../../../_base/time/JsonDateSerializer';
import { ClaimCommentedActivity } from './ClaimCommentedActivity';
import { ClaimClosedActivity } from './ClaimClosedActivity';

export function claimActivityFromJson(json: any, jsonDateSerializer: JsonDateSerializer): ClaimActivity {
    switch(json.type) {
        case 'ClaimOpenedActivity': return ClaimOpenedActivity.fromJson(json, jsonDateSerializer);
        case 'ClaimCommentedActivity': return ClaimCommentedActivity.fromJson(json, jsonDateSerializer);
        case 'ClaimClosedActivity': return ClaimClosedActivity.fromJson(json, jsonDateSerializer);
        default: throw new Error(`Invalid claim activity with type: ${json.type}`);
    }
}
