import React from 'react';
import styled from 'styled-components';
import plusIcon from './plus.png';
import minusIcon from './minus.png';
import { RoundButton } from '../../buttons/RoundButton';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import AppContext from '../../../lib/AppContext';
import { FileChanged, FormFileFieldView, StateChanged } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldPresenter';
import { FormFileFieldStates, FormFileFieldVM } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldVM';
import { FileNameFormatter } from '@eroman/common/src/views/lib/formatters/FileNameFormatter';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';
import { DomFileData } from '../../../../infrastructure/upload/DomFileData';
import { styles } from '../../../styles';

export class FormFileField extends React.Component<Props, State> implements FormFileFieldView {
    private presenter = AppContext.presenters.formFileField(this);
    private inputFileRef = React.createRef<HTMLInputElement>();

    state = {
        model: new FormFileFieldVM(),
    };

    componentDidMount() {
        this.presenter.start(this.props.onChangeFile, this.props.onChangeState);
        this.presenter.selectFile(this.props.value ?? null);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.value != this.props.value) {
            this.presenter.selectFile(this.props.value ?? null);
        }
    }

    selectFile(e) {
        e.preventDefault();
        this.inputFileRef.current?.click();
    }

    unselectFile(e) {
        e.preventDefault();
        this.presenter.unselectFile();
    }

    async uploadFile(e) {
        const file = e.target.files[0];
        await this.presenter.uploadFile(new DomFileData(file));
    }

    modelChanged(model: FormFileFieldVM) {
        this.setState({ model });
    }

    renderSelectFile() {
        return <>
            <label>{this.props.label}:</label>
            <span>Seleccione el archivo</span>
            <_RoundButton icon={plusIcon} href="#" onClick={this.selectFile.bind(this)} />
            <input type="file" ref={this.inputFileRef} onChange={this.uploadFile.bind(this)} />
        </>;
    }

    renderUploading() {
        const progress = Number(this.state.model.uploadProgress).toFixed(2) + '%';
        return <>
            <span>Subiendo archivo...</span><ProgressBar><Progress style={{ width: progress }}/></ProgressBar>
        </>;
    }

    renderUploaded() {
        const fileLabel = FileNameFormatter.formatFile(this.state.model.fileInfo!.label, 30);
        return <>
            <label>{this.props.label}:</label>
            <span>{fileLabel}</span>
            <_RoundButton icon={minusIcon} href="#" onClick={this.unselectFile.bind(this)} />
        </>;
    }

    render() {
        const error = this.props.error || this.state.model.error;
        return (
            <Container className={this.props.className}>
                <Content>
                    {this.state.model.state === FormFileFieldStates.SelectFile && this.renderSelectFile()}
                    {this.state.model.state === FormFileFieldStates.Uploading && this.renderUploading()}
                    {this.state.model.state === FormFileFieldStates.Uploaded && this.renderUploaded()}
                </Content>
                {error && <Error>*{error}</Error>}
            </Container>
        );
    }
}

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  margin-bottom: 22px;
`;

const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  font-size: ${styles.fonts.size.small};
  color: #696F79;

  label {
    font-weight: ${styles.fonts.weight.bold};
    padding-right: 5px;
  }

  input[type=file] { display: none; }
`;

const Error = styled.div`
  font-size: ${styles.fonts.size.xsmall};
  color: #E15201;
  margin-top: 4px;
`;

const _RoundButton = styled(RoundButton)`
  margin-left: 30px;
`;

const ProgressBar = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  background-color: #F4F7FE;
  border-radius: 5px;
`;

const Progress = styled.div`
  background-color: #178BFF;
  margin: 0 2px;
  height: 10px;
  border-radius: 4px;
`;

interface Props {
    label: string;
    value?: Nullable<FileInfo>;
    onChangeFile?: FileChanged;
    onChangeState?: StateChanged;
    error?: Nullable<string>;
    className?: string;
}

interface State {
    model: FormFileFieldVM;
}
