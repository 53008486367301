import React from 'react';
import styled from 'styled-components';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { styles } from '../../styles';

export const FormField: React.FC<Props> = (props) => (
    <Container className={props.className}>
        {props.label && <label className={props.error ? 'has-error' : ''}>{props.label}</label>}
        {props.children}
        {props.error && <div className="error-message">*{props.error}</div>}
    </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  
  > label {
    font-family: 'Nunito', sans-serif;
    font-size: ${styles.fonts.size.regular};
    margin-bottom: 2px;
    color: #696F79;
    
    &.has-error { color: #E15201; }
  }
  
  > .error-message {
    font-family: 'Nunito', sans-serif;
    font-size: ${styles.fonts.size.xsmall};
    color: #E15201;
    margin-top: 4px;
  }
`;

interface Props {
    label?: string;
    error?: Nullable<string>;
    className?: string;
}
