import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export const RouteMatchDetector: React.FC<Props> = (props) => {
    const match = useRouteMatch();
    props.onChange(match);
    return null;
};

interface Props {
    onChange: (match) => void;
}
