import { LocalDateTime } from '../../../_base/time/LocalDateTime';

export abstract class ClaimActivity {
    constructor(
        public type: string,
        public id: number,
        public date: LocalDateTime,
        public claimId: number,
        public actorType: string,
        public actorId: number,
        public actorName: string,
    ) {}
}
