import { LocalDateTime } from '../../_base/time/LocalDateTime';
import { FileInfo } from '../general/FileInfo';

export class PublicAnnouncement {
    constructor(
        public title: string,
        public content: string,
        public date: LocalDateTime,
        public attachments: FileInfo[] = [],
    ) {}

    static fromJson(json: any) {
        return new PublicAnnouncement(
            json.title,
            json.content,
            LocalDateTime.fromISO8601String(json.date),
            json.attachments,
        );
    }

    toJson() {
        return {
            title: this.title,
            content: this.content,
            date: this.date.toISO8601String(),
            attachments: this.attachments,
        };
    }
}
