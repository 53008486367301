import { InformPaymentVM } from './InformPaymentVM';
import { Clock } from '../../../_base/time/Clock';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { DatesFormatter } from '../../lib/formatters/DatesFormatter';
import { handleValidationErrors } from '../../../_base/validation/catchValidationErrors';
import { InformPayment } from '../../../actions/payments/InformPayment';
import { PaymentInformed } from '../../../events/PaymentInformed';
import { EventBus } from '../../../_base/eventBus/EventBus';

export interface InformPaymentView {
    modelChanged(model: InformPaymentVM);
    showSuccessMessage();
}

export class InformPaymentPresenter {
    private model = new InformPaymentVM();

    constructor(
        private view: InformPaymentView,
        private actionBus: ActionBus,
        private eventBus: EventBus,
        private clock: Clock) {}

    start() {
        this.set({ date: this.getCurrentDate() });
    }

    private getCurrentDate() {
        return DatesFormatter.fullDate(this.clock.today());
    }

    set<K extends keyof InformPaymentVM>(changes: Pick<InformPaymentVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async submit() {
        try {
            this.set({ isLoading: true });
            let command = new InformPayment(
                this.model.date,
                this.model.amount.replace(',', '.'),
                this.model.transactionNumber,
                this.model.receiptFile
            );
            await this.actionBus.exec(command);
            this.view.showSuccessMessage();
            await this.eventBus.post(new PaymentInformed());
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages(), isLoading: false }));
        }
    }
}
