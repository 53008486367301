import { FileInfo } from '../../models/general/FileInfo';
import { NameAndLabelCannotBeEmptyError } from './NameAndLabelCannotBeEmptyError';

export class DownloadUrlBuilder {
    private readonly downloadUrl;
    private _file = '';
    private _name = '';

    constructor(baseUrl: string) { this.downloadUrl = `${baseUrl.replace(/\/$/, '')}/download`; }

    file = (value: string): DownloadUrlBuilder => { this._file = value; return this; };
    name = (value: string): DownloadUrlBuilder => { this._name = value; return this; };

    build(): string {
        this.validate();
        return `${this.downloadUrl}?file=${this._file}&name=${this._name}`;
    }

    private validate() {
        if (this._file == '' || this._name == '') throw new NameAndLabelCannotBeEmptyError();
    }

    buildFor(file: FileInfo): string {
        return this.file(file.name).name(file.label).build();
    }
}
