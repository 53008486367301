import React, { HTMLAttributeAnchorTarget, MouseEventHandler } from 'react';
import styled from 'styled-components';

export const RoundButton: React.FC<Props> = (props) => {
    const onClick = (e) => {
        e.preventDefault();
        props.onClick?.(e);
    };

    return <Container
        onClick={onClick}
        className={props.className}
        target={props.target}
        href={props.href}
        color={props.color}
        hoverColor={props.hoverColor}
    >
        <img src={props.icon} />
    </Container>;
};

const Container = styled.a.attrs({})<{ color?: string; hoverColor?: string }>`
  background-color: ${props => props.color || '#178BFF'};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.hoverColor || '#0050BC'};
  }
`;

interface Props {
    color?: string;
    hoverColor?: string;
    icon: string;
    href: string;
    target?: HTMLAttributeAnchorTarget;
    className?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}
