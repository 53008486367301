import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { Validator } from '../../_base/validation/Validator';
import { ResetPassword } from './ResetPassword';
import { AuthService } from '../../models/auth/AuthService';
import { SessionStorage } from '../../models/session/SessionStorage';

export class ResetPasswordHandler implements CommandHandler<ResetPassword> {
    constructor(private authService: AuthService, private sessionStorage: SessionStorage) {
    }

    async handle(command: ResetPassword): Promise<void> {
        this.validate(command);
        await this.resetPassword(command);
    }

    private async resetPassword(command: ResetPassword) {
        await this.authService.resetPassword(command.password);
        const session = await this.sessionStorage.get();
        if (session.user) {
            const response = await this.authService.login(session.user.email, command.password);
            await this.sessionStorage.do(currentSession => {
                currentSession.authenticate(response.user);
                currentSession.selectUnit(parseInt(response.unitId));
                currentSession.suggestPasswordReset = response.suggestPasswordReset;
            });
        }
    }

    private validate(command: ResetPassword) {
        const validator = new Validator();
        validator.check('password', command.password)
            .minLength(6, 'Debe tener al menos 6 caracteres');
        validator.check('confirmationPassword', command.confirmationPassword)
            .equals(command.password, 'Las contraseñas deben ser iguales');
        validator.throwIfHasErrors();
    }
}
