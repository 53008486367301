import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import calendar from './calendar.png';
import { styles } from '../../../styles';

export const FormDateInput: React.FC<Props> = (props) => {
    return <StyledInputMask
        mask="99/99/9999"
        alwaysShowMask={true}
        spellCheck="false"
        className={props.hasError ? 'hasError' : ''}
        value={props.value}
        onChange={(e) => props.onChangeDate?.(e.target.value)}
    />;
};

const StyledInputMask = styled(InputMask)`
  color: #494949;
  font-family: 'Nunito', sans-serif;
  font-size: ${styles.fonts.size.xsmall};
  line-height: 16px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #8692A6;
  background-image: url(${calendar});
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: 17px;
  
  &:focus, &:hover { border-color: #1565D8; }

  &.hasError {
    color: #E15201;
    border-color: #E15201;
  }
`;

interface Props {
    hasError?: boolean;
    value?: string;
    onChangeDate?: ((text: string) => void);
}
