import { ClaimActivity } from './ClaimActivity';
import { JsonDateSerializer } from '../../../_base/time/JsonDateSerializer';
import { LocalDateTime } from '../../../_base/time/LocalDateTime';
import { FileInfo } from '../../general/FileInfo';

export class ClaimCommentedActivity extends ClaimActivity {
    constructor(
        public type: string,
        public id: number,
        public date: LocalDateTime,
        public claimId: number,
        public actorType: string,
        public actorId: number,
        public actorName: string,
        public commentId: number,
        public commentMessage: string,
        public attachments: FileInfo[]
    ) {
        super(type, id, date, claimId, actorType, actorId, actorName);
        this.commentId = commentId;
        this.commentMessage = commentMessage;
    }

    static fromJson(json: any, jsonDateSerializer: JsonDateSerializer) {
        return new ClaimCommentedActivity(
            json.type,
            json.id,
            jsonDateSerializer.fromIso8601(json.date),
            json.claimId,
            json.actorType,
            json.actorId,
            json.actorName,
            json.commentId,
            json.commentMessage,
            json.attachments
        );
    }
}
