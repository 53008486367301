import React from 'react';
import { AmenityBox } from '../Amenities/AmenityBox/AmenityBox';
import { Info } from './Info';
import { styles } from '../../styles';
import { BookAmenityModal } from '../BookAmenity/BookAmenityModal';
import { AmenityModalHeader } from '../Amenities/AmenityModalHeader/AmenityModalHeader';
import { BookingDetail } from './BookingDetail';
import { BookingsVM } from '@eroman/common/src/views/app/Bookings/BookingsVM';
import AppContext from '../../lib/AppContext';
import { AmenitiesInfoView } from './AmenityInfoPresenter';
import styled from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { ScrollableList } from '../../components/ScrollableList';
import { AmenityInfo } from '@eroman/common/src/views/app/Amenities/AmenitiesVM';
import { toast } from 'react-toastify';
import { RightModal } from '../../components/modals/RightModal';
import { showConfirm } from '../../components/modals/ConfirmModal';

export class AmenityInfoModal extends React.Component<Props> implements AmenitiesInfoView {
    private presenter = AppContext.presenters.amenityInfo(this);

    async componentDidMount() {
        await this.presenter.start(this.props.amenity.id);
    }

    state = {
        model: new BookingsVM(),
        isBookingModalOpen: false,
    };

    render() {
        return <>
            {this.state.isBookingModalOpen && <BookAmenityModal
                isOpen={true}
                closeModal={this.props.closeModal}
                amenity={this.props.amenity}
            />} :
            <RightModal
                isOpen={this.props.isOpen}
                closeModal={this.props.closeModal}
            >
                <AmenityModalHeader amenityName={this.props.amenity.name} closeModal={this.props.closeModal}/>
                <Container>
                    <Amenity>
                        <AmenityBox id={this.props.amenity.id} name={this.props.amenity.name} iconPath={this.props.amenity.iconPath}/>
                        <_PrimaryButton onClick={() => this.setState({ isBookingModalOpen: true })}>Reservar
                        </_PrimaryButton>
                    </Amenity>
                    <Detail>
                        <Detail>{this.props.amenity.general}</Detail>
                        <Info label={'Horarios:'} text={this.props.amenity.availability}/>
                        <Info label={'Tiempo de uso:'} text={this.props.amenity.slots}/>
                    </Detail>
                </Container>
                {
                    this.state.model.bookings.isNotEmpty() &&
                    <Bookings>
                        <p>Reservas realizadas</p>
                        <_ScrollableList>
                            {
                                this.state.model.bookings.map(b =>
                                    <BookingDetail
                                        key={b.id}
                                        booking={b}
                                        onCancel={(e) => this.cancelBooking(e, b.id) }/>
                                )
                            }
                        </_ScrollableList>
                    </Bookings>
                }
            </RightModal>
        </>;
    }

    cancelBooking(e: React.MouseEvent<HTMLAnchorElement>, bookingId: number) {
        e.preventDefault();
        this.confirmCancellation(bookingId);
    }

    confirmCancellation = (bookingId: number) => {
        showConfirm('¿Desea cancelar la reserva?', '', () => this.presenter.cancel(bookingId));
    };

    modelChanged(model) {
        this.setState({ model });
    }

    showErrorMessage(message: string) {
        toast.error(message);
    }

    showSuccessMessage(message: string) {
        toast.success(message);
    }
}

const Container = styled.div`
  font-family: 'DM Sans', sans-serif;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 25px 0;
`;

const Amenity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
`;

const _PrimaryButton = styled(PrimaryButton)`
  margin-top: 20px;
`;

const Detail = styled.div`
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
  line-height: 20px;
  color: #8A96BA;
  padding: 4px;
  align-self: flex-start;
`;

const Bookings = styled.div`
  margin-top: 40px;
  margin-bottom: 45px;
  max-height: 300px;

  p {
    font-size: ${styles.fonts.size.big};
    font-family: ${styles.fonts.dmSansMedium};
    color: #666F89;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

const _ScrollableList = styled(ScrollableList)`
  max-height: 310px;
`;

interface Props {
    isOpen: boolean,
    closeModal: () => void,
    amenity: AmenityInfo
}
