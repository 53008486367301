import { Nullable } from '../../../_base/lang/Nullable';
import { AmenityInfo } from '../../../models/amenities/AmenityInfo';

export class BookAmenityVM {
    isLoading = false;
    slots: SlotInfo[] = [];
    selectedSlot: Nullable<SlotInfo> = null;
    dates: string[] = [];
    selectedDate: Nullable<string> = null;
    amenity: AmenityInfo = { id: 0, name: '' };
}

export interface SlotInfo {
    startAt: string;
    durationInMinutes: number;
    fee: string;
    isSelected: boolean;
}
