import React, { useState } from 'react';
import icon from './bank_transfer_icon.png';
import styled from 'styled-components';
import { CopyableInfo } from './CopyableInfo';
import { InformPaymentButton } from './InformPaymentButton';
import { SectionHeader } from '../../../../components/SectionHeader';
import { BankTransferProvider } from '@eroman/common/src/models/associations/paymentProviders/BankTransferProvider';
import { styles } from '../../../../styles';

export const BankTransfer: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return <Container>
        <HeaderContainer>
            <SectionHeader icon={icon} title="Pagar con transferencia bancaria" color="#1151AD"/>
        </HeaderContainer>
        <GeneralInfo>
            <Info>{props.provider.accountHolder}</Info>
            <Info>CUIT: {props.provider.taxId}</Info>
        </GeneralInfo>
        <CopyableInfo title="CBU" info={props.provider.transferNumber}/>
        {props.provider.transferAlias &&
        <CopyableInfo title="Alias" info={props.provider.transferAlias!.toUpperCase()}/>}
        <InformPaymentButton isModalOpen={isOpen} toggleModal={toggleModal}/>
    </Container>;
};

const Container = styled.div`
  margin-top: 35px;
`;

const HeaderContainer = styled.div`
  margin: 20px 20px 0;
`;

const Info = styled.p`
  margin-left: 60px;
  font-size: ${styles.fonts.size.regular};
  color: #666F89;
`;

const GeneralInfo = styled.div``;

interface Props {
    provider: BankTransferProvider;
}
