import { AnnouncementService } from '../../models/announcements/AnnouncementService';
import { HttpClient } from '../../_base/http/HttpClient';
import { Announcement } from '../../models/announcements/Announcement';

export class HttpAnnouncementService implements AnnouncementService{
    constructor(private httpClient: HttpClient){}

    async getAnnouncements(associationId: number): Promise<Announcement[]> {
        const response = await this.httpClient.get(`/announcements/${associationId}`);
        return response.body.announcements.map(announcement => Announcement.fromJson(announcement));
    }
}
