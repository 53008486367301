import React from 'react';
import styled from 'styled-components';
import { InformPaymentModal } from '../../../InformPayment/InformPaymentModal';
import { CircularArrowButton } from '../CircularArrow/CircularArrowButton';
import { styles } from '../../../../styles';

export const InformPaymentButton: React.FC<Props> = (props) => {
    return <>
        <InformPaymentModal isOpen={props.isModalOpen} closeModal={() => props.toggleModal()}/>
        <Container onClick={() => props.toggleModal()}>
            <Text>Informar pago</Text>
            <CircularArrowButton color="#307FE2" href="#" />
        </Container>
    </>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;

  &:hover {
    a { background-color: #5D7198; }

    p { color: #5D7198; }
  }
`;

const Text = styled.p`
  color: #307FE2;
  cursor: pointer;
  font-size: ${styles.fonts.size.regular};
  font-weight: ${styles.fonts.weight.bold};
  margin-right: 15px;
`;

interface Props {
    isModalOpen: boolean;
    toggleModal: () => void;
}
