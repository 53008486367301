import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { Claim } from '../../models/claims/Claim';
import { GetUnitClaims } from './GetUnitClaims';
import { SessionStorage } from '../../models/session/SessionStorage';
import { ClaimService } from '../../models/claims/ClaimService';

export class GetUnitClaimsHandler implements QueryHandler<GetUnitClaims, Claim[]> {
    constructor(private sessionStorage: SessionStorage, private claimService: ClaimService) {
    }

    async handle(query: GetUnitClaims): Promise<Claim[]> {
        const session = await this.sessionStorage.get();
        return await this.claimService.getClaims(session.unit!.id);
    }
}
