import { Announcement } from '@eroman/common/src/models/announcements/Announcement';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetAnnouncements } from '@eroman/common/src/actions/announcements/GetAnnouncements';
import { DatesFormatter } from '@eroman/common/src/views/lib/formatters/DatesFormatter';
import { EventBus } from '@eroman/common/src/_base/eventBus/EventBus';
import { AnnouncementDetailVM, AnnouncementsVM, FileVM } from './AnnouncementsVM';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { HtmlSanitizer } from '@eroman/common/src/infrastructure/htmlSanitizer/HtmlSanitizer';
import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';

export interface AnnouncementsView {
    modelChanged(model: AnnouncementsVM);
}

export class AnnouncementsPresenter {
    private model = new AnnouncementsVM();
    private announcements: Announcement[] = [];

    constructor(
        private view: AnnouncementsView,
        private actionBus: ActionBus,
        private eventBus: EventBus,
        private navigator: WebNavigator,
        private sanitizer: HtmlSanitizer,
        private downloadUrlBuilder: DownloadUrlBuilder
    ) {
        this.navigator.currentRouteChanged.subscribe(this, this.onCurrentRouteChanged.bind(this));
    }

    async start() {
        await this.loadAnnouncements();
        await this.selectAnnouncementFromCurrentRoute();
        this.sanitizeContent();
    }

    private async loadAnnouncements() {
        this.set({ isLoading: true });
        this.announcements = await this.actionBus.query(new GetAnnouncements());
        this.set({
            announcements: this.announcements.map(a => this.toAnnouncementInfo(a)),
            isLoading: false,
        });
        if (this.navigator.currentRoute?.name === 'announcements' && this.model.announcements.length > 0) {
            this.navigator.navigate('announcement-detail', { id: this.model.announcements.first().id });
        }
    }

    private toAnnouncementInfo(announcement: Announcement): AnnouncementDetailVM {
        return {
            id: announcement.id,
            associationId: announcement.associationId,
            title: announcement.title,
            content: announcement.content,
            date: DatesFormatter.fullDateTime(announcement.date).split(' ')[0],
            attachments: announcement.attachments.map(this.toFileVM.bind(this)),
        };
    }

    private toFileVM(file: FileInfo): FileVM {
        return {
            url: this.downloadUrlBuilder.buildFor(file),
            label: file.label,
        };
    }

    onCurrentRouteChanged() {
        this.selectAnnouncementFromCurrentRoute();
    }

    private set<K extends keyof AnnouncementsVM>(changes: Pick<AnnouncementsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }

    selectAnnouncement(id: number) {
        this.navigator.navigate('announcement-detail', { id });
    }

    private selectAnnouncementFromCurrentRoute() {
        const id = parseInt(this.navigator.currentRoute?.params?.id, 10);
        let selectedAnnouncement = this.model.announcements.singleOrNull(p => p.id === id);
        this.set({ selectedAnnouncement });
    }

    private sanitizeContent() {
        if (this.model.selectedAnnouncement) {
            this.model.selectedAnnouncement!.content = this.sanitizer.sanitize(this.model.selectedAnnouncement!.content);
            this.set({
                selectedAnnouncement: this.model.selectedAnnouncement,
            });
        }
    }
}
