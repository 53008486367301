import { ResetPasswordVM } from './ResetPasswordVM';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { ResetPassword } from '../../../actions/auth/ResetPassword';
import { handleValidationErrors } from '../../../_base/validation/catchValidationErrors';
import { DontSuggestPasswordReset } from '../../../actions/users/DontSuggestPasswordReset';

export interface ResetPasswordView {
    modelChanged(model: ResetPasswordVM);
    showSuccessMessage();
}

export class ResetPasswordPresenter {
    private model = new ResetPasswordVM();

    constructor(private view: ResetPasswordView, private actionBus: ActionBus) {}

    set<K extends keyof ResetPasswordVM>(changes: Pick<ResetPasswordVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    async submit() {
        try {
            let command = new ResetPassword(this.model.password, this.model.confirmationPassword);
            await this.actionBus.exec(command);
            this.view.showSuccessMessage();
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages() }));
        }
    }

    async dontSuggestPasswordReset() {
        await this.actionBus.exec(new DontSuggestPasswordReset());
    }
}
