export class ManagerInfoVM {
    isLoading = false;
    name = '';
    address = '';
    website = '';
    emails = '';
    phones = '';
    whatsapp = '';
    workingHours = '';
}
