import { Association } from '../associations/Association';
import { ManagerInfo } from '../managers/ManagerInfo';
import { Nullable } from '../../_base/lang/Nullable';

export class Unit {
    constructor(
        public id: number,
        public number: string,
        public apartment: Nullable<string>,
        public association: Association,
        public manager: ManagerInfo,
    ) {}

    get fullAddress() {
        const identifier = this.apartment ?? 'UF ' + this.number;
        return this.association.address.street + ' ' + identifier;
    }

    static fromJson(json: any): Unit {
        return new Unit(
            json.id,
            json.number,
            json.apartment,
            Association.fromJson(json.association),
            ManagerInfo.fromJson(json.manager),
        );
    }
}
