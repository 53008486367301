import React from 'react';
import { ReactRouterNavigation } from './lib/navigation/reactRouter/ReactRouterNavigation';
import 'react-toastify/dist/ReactToastify.min.css';
import { Toast } from './components/Toast';
import { PlatformGate } from './public/PlatformGate';

export const AppRoot: React.FC<Props> = (props) => <>
    <PlatformGate>
        {props.navigation.render()}
        <Toast/>
    </PlatformGate>
</>;

interface Props {
    navigation: ReactRouterNavigation;
}
