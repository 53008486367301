import React from 'react';
import styled from 'styled-components';
import titleFile from './titleFile.png';
import lightGrayFolder from './lightGrayFolder.png';
import openFolder from './openFolder.png';
import { styles } from '../../styles';
import { Loader } from '../../components/Loader';
import AppContext from '../../lib/AppContext';
import { DocumentRow } from './DocumentRow';
import { ScrollableList } from '../../components/ScrollableList';
import { DocumentsVM } from '@eroman/common/src/views/app/Documents/DocumentsVM';
import { DocumentsView } from '@eroman/common/src/views/app/Documents/DocumentsPresenter';

export class DocumentsPage extends React.Component implements DocumentsView {
    private presenter = AppContext.presenters.documents(this);

    state = {
        model: new DocumentsVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    modelChanged(model: DocumentsVM) {
        this.setState({ model });
    }

    renderDocuments() {
        return <>
            {this.state.model.documents.map(document => {
                return (
                    (document.type == 'Directory') ?
                        <DocumentRow key={document.id} document={document} onClick={this.presenter.openFolder}/>
                        : <DocumentRow key={document.id} document={document}/>
                );
            })}
        </>;
    }

    render() {
        return <Box>
            {
                this.state.model.isLoading
                    ? <Loader></Loader>
                    :
                    this.state.model.documents.isEmpty() ?
                        <NoDocuments>Aún no hay documentos disponibles</NoDocuments>
                        :
                        <Documents>
                            <SectionHeader>
                                <img src={titleFile} alt={'Documentos'}/>
                                {
                                    this.state.model.breadcrumbs.isEmpty() ? <p>Documentos</p> :
                                        <p>/{this.state.model.breadcrumbs.map(it => it.name.toUpperCase().concat('/'))}</p>
                                }
                            </SectionHeader>
                            <ScrollableList>
                                <Header>
                                    <FolderName>NOMBRE</FolderName>
                                    <LastUpdate>ÚLTIMA MODIFICACIÓN</LastUpdate>
                                </Header>
                                <BackButton onClick={() => this.presenter.goBackInFileSystem()}>
                                    <Icon src={lightGrayFolder}/>
                                    <Path>...</Path>
                                </BackButton>
                                {!this.state.model.breadcrumbs.isEmpty() &&
                                    <>
                                        <CurrentFolder>
                                            <Icon src={openFolder}/>
                                            <Folder>{this.state.model.breadcrumbs.last().name}</Folder>
                                        </CurrentFolder>
                                        <DocumentsContainer>
                                            {this.state.model.isLoading ? <Loader/> : this.renderDocuments()}
                                        </DocumentsContainer>
                                    </> || this.renderDocuments()
                                }
                            </ScrollableList>
                        </Documents>
            }
        </Box>;
    }
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  flex-grow: 1;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 20px 9px 20px 20px;
`;

const NoDocuments = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  font-size: ${styles.fonts.size.small};
  font-weight: ${styles.fonts.weight.regular};
  color: #8A96BA;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #CED6E5;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  & p {
    font-weight: ${styles.fonts.weight.regular};
    font-size: ${styles.fonts.size.small};
    color: #8A96BA;
  }

  & img {
    width: 34px;
  }
`;


const Icon = styled.img`
  margin-right: 5px;
  max-width: 20px;
  max-height: 15px;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const CurrentFolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const LastUpdate = styled.div``;
const FolderName = styled.div``;
const Path = styled.div``;
const Folder = styled.div``;

const DocumentsContainer = styled.div`
  padding-left: 25px;
`;
