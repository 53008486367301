import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { DataStorage } from '@eroman/common/src/infrastructure/storage/DataStorage';

export class BrowserDataStorage implements DataStorage {
    async get(key: string): Promise<Nullable<string>> {
        return sessionStorage.getItem(key);
    }

    async set(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }
}
