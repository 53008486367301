import React from 'react';
import styled from 'styled-components';
import arrow from './view_detail.png';
import classNames from 'classnames';
import { styles } from '../../../styles';
import { PollVM } from '@eroman/common/src/views/app/Polls/PollsVM';

interface Props {
    item: PollVM;
    onClick: (id: number) => void;
    selected: boolean;
    className? : string;
    ongoing: boolean;
}

export const PollRow: React.FC<Props> = (props) => {
    let statusColor = '#4D556F';
    let selectedColor = '#1565D8';
    let lineColor = '#1565D8';
    if (props.ongoing) {
        statusColor = 'grey';
        lineColor = '#1565D8';
    } else {
        statusColor = 'grey';
        selectedColor = '#DB640F';
        lineColor = '#DB640F';
    }

    return <Item className={classNames(props.className, { selected: props.selected })} onClick={() => props.onClick(props.item.id)} selectedColor={selectedColor}>
        <Container statusColor={statusColor} lineColor={lineColor}>
            <p>{props.item.title}</p>
        </Container>
        <Arrow src={arrow} alt="Ver detalle"/>
    </Item>;
};


const Arrow = styled.img`
  flex-shrink: 0;
`;

const Container = styled.div.attrs((props: { statusColor: string, lineColor:string }) => props)`
  color: ${(props) => props.statusColor};
  flex-grow: 1;
  display: flex;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.regular};
  line-height: 40px;
  margin-right: 5px;
  padding-left: 39px;
  padding-right: 3px;
  border-radius: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: ' ';
    display: block;
    background-color: ${(props) => props.lineColor};
    width: 5px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    left: 15px;
    top: 10px;
  }
`;

const Item = styled.li.attrs((props: { selectedColor: string }) => props)`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  ${Arrow} { visibility: hidden; }
  
  &:hover, &.selected {
    
    ${Container} { background-color: #F4F7FE; }
    
    ${Arrow} { visibility: visible; }
  }
`;

