import React, { KeyboardEventHandler, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { styles } from '../../../styles';
import eye from './eye.png';
import eyeOff from './eye-off.png';

export const PasswordField: React.FC<Props> = (props) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return <Container>
        <_Input
            type={passwordVisible ? 'text' : 'password'}
            maxLength={200}
            className={classNames(props.className, { 'has-error': props.hasError })}
            onChange={(e) => props.onChangeText?.(e.target.value)}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeHolder}
            value={props.value}
            autoComplete={props.autocomplete ?? 'on'}
        />
        <Button onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? <Icon src={eyeOff} /> : <Icon src={eye} />}
        </Button>
    </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #8692A6;
  overflow: hidden;
  &:focus { border-color: #1565D8; }
  &.has-error {
    color: #E15201;
    border-color: #E15201;
  }
`;

const _Input = styled.input`
  flex-grow: 1;
  flex-shrink: 1;
  font-family: 'Nunito', sans-serif;
  font-size: ${styles.fonts.size.xsmall};
  padding: 9px;
  width: 300px;
  border: none;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
`;

const Button = styled.a`
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
`;

interface Props {
    hasError?: boolean;
    value?: string;
    placeHolder?: string;
    onChangeText?: ((text: string) => void);
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    className?: string;
    autocomplete?: string;
}
