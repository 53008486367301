import React from 'react';
import AppContext from '../../lib/AppContext';
import styled from 'styled-components';
import { ExpensesView } from '@eroman/common/src/views/app/Expenses/ExpensesPresenter';
import { ExpensesVM, ViewStates } from '@eroman/common/src/views/app/Expenses/ExpensesVM';
import { NoExpensesTitle } from './PaymentsInfo/NoExpensesTitle';
import { ExpenseInfo } from './PaymentsInfo/ExpenseInfo';
import { PaymentsInfo } from './PaymentsInfo/PaymentsInfo';
import { Redepagos } from './paymentMethods/Redepagos/Redepagos';
import { BankTransfer } from './paymentMethods/BankTransfer/BankTransfer';
import { Loader } from '../../components/Loader';
import { ManagerInfoBox } from './ManagerInfo/ManagerInfoBox';
import Masonry from 'react-masonry-css';
import { Cash } from './paymentMethods/Cash/Cash';
import { CVUTransfer } from './paymentMethods/CVUTransfer/CVUTransfer';
import { ShowOtherPaymentMethodsButton } from './paymentMethods/ShowOtherPaymentMethodsButton';

export class ExpensesPage extends React.Component implements ExpensesView {
    private presenter = AppContext.presenters.expenses(this);

    state = {
        model: new ExpensesVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    modelChanged(model: ExpensesVM) {
        this.setState({ model });
    }

    renderExpense() {
        const model = this.state.model;
        switch (model.state) {
            case ViewStates.NoExpenses:
                return <NoExpensesTitle/>;
            case ViewStates.NoPayments:
                return <ExpenseInfo expense={model.expense!}/>;
            case ViewStates.Payments:
                return <PaymentsInfo
                    expense={model.expense!}
                    info={model.paymentInfo!}
                />;
        }
    }

    get hasPaymentMethods() {
        return this.state.model.showRedepagos || this.state.model.bankTransferProvider !== null;
    }

    renderCVUPaymentMethod() {
        return <>
            <CVUTransfer CVU={this.state.model.CVUNumber}/>
            <ShowOtherPaymentMethodsButton
                onClick={() => this.presenter.set({ showOtherPaymentMethods: !this.state.model.showOtherPaymentMethods })}
                isClicked={this.state.model.showOtherPaymentMethods}
            />
        </>;
    }
    
    render() {
        return (
            <Container>
                {this.state.model.state === ViewStates.Loading ? (
                    <Loader/>
                ) : (
                    <_Masonry
                        className="grid"
                        columnClassName="grid_column"
                        breakpointCols={{
                            default: 2,
                            1650: 2,
                            1300: 1,
                        }}
                    >
                        <ExpenseContainer>
                            {this.renderExpense()}
                        </ExpenseContainer>
                        {this.hasPaymentMethods && (
                            <PaymentMethods>
                                {this.state.model.showCVUTransfer && this.renderCVUPaymentMethod()}
                                {this.state.model.showRedepagos && this.state.model.showOtherPaymentMethods && <Redepagos url={this.state.model.redepagosPayUrl}/>}
                                {this.state.model.bankTransferProvider && this.state.model.showOtherPaymentMethods &&
                                    <BankTransfer provider={this.state.model.bankTransferProvider}/>}
                                {this.state.model.showCash && this.state.model.showOtherPaymentMethods && <Cash />}
                            </PaymentMethods>
                        )}
                        <ManagerInfoBox/>
                    </_Masonry>
                )}
            </Container>
        );
    }
}

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
`;

const PaymentMethods = styled.div`
  width: 474px;
  min-height: 135px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
`;

const ExpenseContainer = styled.div`
  width: 474px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  padding-bottom: 26px;
`;

const _Masonry = styled(Masonry)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;

  & .grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  & .grid_column > div {
    margin-bottom: 30px;
  }
`;
