import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { InformPayment } from './InformPayment';
import { SessionStorage } from '../../models/session/SessionStorage';
import { PaymentService } from '../../models/payments/PaymentService';
import { LocalDate } from '../../_base/time/LocalDate';
import { Money } from '../../models/general/Money';
import { Validator } from '../../_base/validation/Validator';
import { isNullOrBlank } from '../../_base/validation/validationFunctions';

export class InformPaymentHandler implements CommandHandler<InformPayment> {
    constructor(private sessionStorage: SessionStorage, private paymentService: PaymentService) {}

    async handle(command: InformPayment) {
        this.validate(command);
        await this.createPayment(command);
    }

    private async createPayment(command: InformPayment) {
        const session = await this.sessionStorage.get();
        const currentUnitId = session.unit!!.id;
        const date = LocalDate.fromDDMMYYYYString(command.date);
        const amount = Money.of(command.amount);
        await this.paymentService.createPayment(currentUnitId, date, amount, command.transactionNumber, command.receiptFile);
    }

    private validate(command: InformPayment) {
        const validator = new Validator();
        validator.check('date', command.date)
            .notNullOrBlank('Debe ingresar la fecha')
            .date('DD/MM/YYYY', 'La fecha es inválida');
        validator.check('amount', command.amount)
            .notNullOrBlank('Debe ingresar el importe')
            .number('El importe debe ser un número válido');
        if (isNullOrBlank(command.transactionNumber) && command.receiptFile === null) {
            validator.addGeneralError('Debe ingresar un número de transacción o un comprobante');
        }
        validator.throwIfHasErrors();
    }
}
