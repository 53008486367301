import React from 'react';
import styled from 'styled-components';
import arrowDown from './arrow-down.png';
import arrowUp from './arrow-up.png';

export const ShowOtherPaymentMethodsButton: React.FC<Props> = (props) => {
    return (
        <ActionContainer onClick={props.onClick}>
            <Button>Ver otras formas de pago</Button>
            {props.isClicked ? <Img src={arrowUp}/> : <Img src={arrowDown}/>}
        </ActionContainer>
    );
};

const Img = styled.img`
  width: 11px;
  height: 12px;
`;

const Button = styled.p`
  color: #5F6B8C;
  font-weight: 500;
  text-align: center;
  margin: 20px;
`;

const ActionContainer = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 25%;
`;

interface Props {
    onClick: () => void;
    isClicked: boolean;
}
