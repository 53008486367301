import { FormFileFieldStates, FormFileFieldVM } from './FormFileFieldVM';
import { ActionBus } from '../../../../_base/actionBus/ActionBus';
import { Upload } from '../../../../actions/upload/Upload';
import { Nullable } from '../../../../_base/lang/Nullable';
import { FileInfo } from '../../../../models/general/FileInfo';
import { FileData } from '../../../../infrastructure/uploader/FileData';

export interface FormFileFieldView {
    modelChanged(model: FormFileFieldVM);
}

export type FileChanged = (fileInfo: Nullable<FileInfo>) => void;
export type StateChanged = (state: FormFileFieldStates) => void;

export class FormFileFieldPresenter {
    private model = new FormFileFieldVM();
    private onChangeFile?: FileChanged;
    private onChangeState?: StateChanged;

    constructor(private view: FormFileFieldView, private actionBus: ActionBus) {}

    start(onChangeFile?: FileChanged, onChangeState?:StateChanged) {
        this.onChangeFile = onChangeFile;
        this.onChangeState = onChangeState;
        this.set({ state: FormFileFieldStates.SelectFile });
    }

    async uploadFile(fileData: Nullable<FileData>) {
        if (fileData === null) { this.showError('Debe seleccionar un archivo'); return; }
        try {
            await this.tryUploadFile(fileData);
        } catch(e: any) {
            this.showError(e.message);
        }
    }

    private showError(message: string) {
        this.set({ state: FormFileFieldStates.SelectFile, error: message });
    }

    private async tryUploadFile(fileData: FileData) {
        this.set({ state: FormFileFieldStates.Uploading });
        const generatedName = await this.actionBus.exec(new Upload(fileData, this.onUploadProgress.bind(this)));
        const fileInfo = { name: generatedName, label: fileData.name };
        this.set({ state: FormFileFieldStates.Uploaded, fileInfo });
    }

    private onUploadProgress(progress: number) {
        this.set({ uploadProgress: progress });
    }

    set<K extends keyof FormFileFieldVM>(changes: Pick<FormFileFieldVM, K>) {
        const oldState = this.model.state;
        const oldFileInfo = this.model.fileInfo;
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
        if (oldState != this.model.state) { this.onChangeState?.(this.model.state); }
        if (oldFileInfo != this.model.fileInfo) { this.onChangeFile?.(this.model.fileInfo); }
    }

    selectFile(fileInfo: Nullable<FileInfo>) {
        if (fileInfo === null) { this.unselectFile(); return; }
        this.set({ state: FormFileFieldStates.Uploaded, fileInfo });
    }

    unselectFile() {
        this.set({ state: FormFileFieldStates.SelectFile, fileInfo: null });
    }
}
