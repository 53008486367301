import React from 'react';
import { MonthHeader } from './MonthHeader/MonthHeader';
import { MonthDetailRow } from './MonthDetailRow';
import { ExpenseInfoVM } from '@eroman/common/src/views/app/Expenses/ExpensesVM';

export const ExpenseInfo: React.FC<Props> = (props) => (
    <>
        <MonthHeader expenseDownloadUrl={props.expense.downloadUrl} month={props.expense.period} />
        <MonthDetailRow title={`1er vto. ${props.expense.dueDate1}`} value={props.expense.amount1}/>
        <MonthDetailRow title={`2do vto. ${props.expense.dueDate2}`} value={props.expense.amount2} />
    </>
);

interface Props {
    expense: ExpenseInfoVM,
}
