import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetPublicAnnouncement } from '@eroman/common/src/actions/publicAnnouncements/GetPublicAnnouncement';
import { FileVM, PublicAnnouncementVM } from './PublicAnnouncementVM';
import { DatesFormatter } from '@eroman/common/src/views/lib/formatters/DatesFormatter';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';
import { PublicAnnouncement } from '@eroman/common/src/models/publicAnnouncements/PublicAnnouncement';
import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { SafeHtmlSanitizer } from '@eroman/common/src/infrastructure/htmlSanitizer/SafeHtmlSanitizer';

export interface PublicAnnouncementView {
    modelChanged(model: PublicAnnouncementVM);
}

export class PublicAnnouncementPresenter {
    private model = new PublicAnnouncementVM();
    private sanitizer = new SafeHtmlSanitizer();

    constructor(
        private view: PublicAnnouncementView,
        private navigator: WebNavigator,
        private actionBus: ActionBus,
        private downloadUrlBuilder: DownloadUrlBuilder,
    ) {}

    async start() {
        const token = this.navigator.currentRoute?.params?.token;
        let announcement = await this.actionBus.query(new GetPublicAnnouncement(token));
        this.set(this.generateVM(announcement));
    }

    private generateVM(announcement: PublicAnnouncement): PublicAnnouncementVM {
        return {
            isLoading: false,
            title: announcement.title,
            content: this.sanitizer.sanitize(announcement.content),
            date: DatesFormatter.fullDateTime(announcement.date).split(' ')[0],
            attachments: announcement.attachments.map(this.fileVMFrom.bind(this)),
        };
    }

    private fileVMFrom(file: FileInfo): FileVM {
        return { url: this.downloadUrlBuilder.buildFor(file), label: file.label };
    }

    private set<K extends keyof PublicAnnouncementVM>(changes: Pick<PublicAnnouncementVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}

