import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { ClaimActivityInfo, FileVM } from '@eroman/common/src/views/app/Claims/ClaimActivityInfo';

export class ClaimsVM {
    isLoading = false;
    claims: ClaimInfo[] = [];
    selectedClaim: Nullable<ClaimInfo> = null;
    activities: ClaimActivityInfo[] = [];
}

export class ClaimInfo {
    id = 0;
    subjectTitle = '';
    description = '';
    isClosed = false;
    createdAt = '';
    attachments: FileVM[] = [];
}
