import { HttpClient } from '../_base/http/HttpClient';
import { SessionStorage } from '../models/session/SessionStorage';
import { EventBus } from '../_base/eventBus/EventBus';
import { HttpUnitService } from './http/HttpUnitService';
import { HttpAuthService } from './http/HttpAuthService';
import { HttpPaymentService } from './http/HttpPaymentService';
import { HttpAnnouncementService } from './http/HttpAnnouncementService';
import { HttpAssociationService } from './http/HttpAssociationService';
import { HttpFileUploader } from './uploader/HttpFileUploader';
import { ActionBus } from '../_base/actionBus/ActionBus';
import { Login } from '../actions/auth/Login';
import { InformPayment } from '../actions/payments/InformPayment';
import { SelectUnit } from '../actions/units/SelectUnit';
import { Upload } from '../actions/upload/Upload';
import { GetUnitPaymentStatus } from '../actions/units/GetUnitPaymentStatus';
import { GetUnitPayments } from '../actions/payments/GetUnitPayments';
import { GetRedepagosPayUrl } from '../actions/payments/GetRedepagosPayUrl';
import { GetAnnouncements } from '../actions/announcements/GetAnnouncements';
import { GetUnitManager } from '../actions/units/GetUnitManager';
import { LoginHandler } from '../actions/auth/LoginHandler';
import { InformPaymentHandler } from '../actions/payments/InformPaymentHandler';
import { SelectUnitHandler } from '../actions/units/SelectUnitHandler';
import { UploadHandler } from '../actions/upload/UploadHandler';
import { GetUnitPaymentStatusHandler } from '../actions/units/GetUnitPaymentStatusHandler';
import { GetUnitPaymentsHandler } from '../actions/payments/GetUnitPaymentsHandler';
import { GetRedepagosPayUrlHandler } from '../actions/payments/GetRedepagosPayUrlHandler';
import { GetAnnouncementsHandler } from '../actions/announcements/GetAnnouncementsHandler';
import { GetUnitManagerHandler } from '../actions/units/GetUnitManagerHandler';
import { LogoutHandler } from '../actions/auth/LogoutHandler';
import { Logout } from '../actions/auth/Logout';
import { HttpAnalyticsService } from './http/HttpAnalyticsService';
import { DeviceInfo } from '../models/DeviceInfo';
import { GetClaimSubjects } from '../actions/claims/GetClaimSubjects';
import { GetClaimSubjectsHandler } from '../actions/claims/GetClaimSubjectsHandler';
import { HttpClaimService } from './http/HttpClaimService';
import { CreateClaim } from '../actions/claims/CreateClaim';
import { CreateClaimHandler } from '../actions/claims/CreateClaimHandler';
import { GetUnitClaims } from '../actions/claims/GetUnitClaims';
import { GetUnitClaimsHandler } from '../actions/claims/GetUnitClaimsHandler';
import { JsonDateSerializer } from '../_base/time/JsonDateSerializer';
import { GetClaimActivity } from '../actions/claims/GetClaimActivity';
import { GetClaimActivityHandler } from '../actions/claims/GetClaimActivityHandler';
import { CommentClaim } from '../actions/claims/CommentClaim';
import { CommentClaimHandler } from '../actions/claims/CommentClaimHandler';
import { GetDocuments } from '../actions/documents/GetDocuments';
import { GetDocumentsHandler } from '../actions/documents/GetDocumentsHandler';
import { HttpDocumentService } from './http/HttpDocumentService';
import { HttpAmenitiesService } from './http/HttpAmenitiesService';
import { GetAmenities } from '../actions/amenities/getAmenities/GetAmenities';
import { GetAmenitiesHandler } from '../actions/amenities/getAmenities/GetAmenitiesHandler';
import { GetAvailableBookingDays } from '../actions/amenities/getAvailableBookingDays/GetAvailableBookingDays';
import { GetAvailableBookingDaysHandler } from '../actions/amenities/getAvailableBookingDays/GetAvailableBookingDaysHandler';
import { SearchDateAvailableSlots } from '../actions/amenities/search/SearchDateAvailableSlots';
import { SearchDateAvailableSlotsHandler } from '../actions/amenities/search/SearchDateAvailableSlotsHandler';
import { BookAmenity } from '../actions/amenities/booking/book/BookAmenity';
import { BookAmenityHandler } from '../actions/amenities/booking/book/BookAmenityHandler';
import { GetBookings } from '../actions/amenities/booking/getBookings/GetBookings';
import { GetBookingsHandler } from '../actions/amenities/booking/getBookings/GetBookingsHandler';
import { GetUsefulContacts } from '../actions/usefulContacts/GetUsefulContacts';
import { GetUsefulContactsHandler } from '../actions/usefulContacts/GetUsefulContactsHandler';
import { CancelBooking } from '../actions/amenities/booking/cancelBooking/CancelBooking';
import { CancelBookingHandler } from '../actions/amenities/booking/cancelBooking/CancelBookingHandler';
import { GetDisabledAmenities } from '../actions/amenities/getAmenities/GetDisabledAmenities';
import { GetDisabledAmenitiesHandler } from '../actions/amenities/getAmenities/GetDisabledAmenitiesHandler';
import { GetUnitCVUNumber } from '../actions/payments/GetUnitCVUNumber';
import { GetUnitCVUNumberHandler } from '../actions/payments/GetUnitCVUNumberHandler';
import { Navigator } from '../lib/navigation/Navigator';
import { GetPublicAnnouncement } from '../actions/publicAnnouncements/GetPublicAnnouncement';
import { GetPublicAnnouncementHandler } from '../actions/publicAnnouncements/GetPublicAnnouncementHandler';
import { HttpPublicAnnouncementService } from './http/HttpPublicAnnouncementService';
import { ResetPassword } from '../actions/auth/ResetPassword';
import { ResetPasswordHandler } from '../actions/auth/ResetPasswordHandler';
import { ForgotPassword } from '../actions/auth/ForgotPassword';
import { ForgotPasswordHandler } from '../actions/auth/ForgotPasswordHandler';
import { ChangePassword } from '../actions/auth/ChangePassword';
import { ChangePasswordHandler } from '../actions/auth/ChangePasswordHandler';
import { DontSuggestPasswordReset } from '../actions/users/DontSuggestPasswordReset';
import { DontSuggestPasswordResetHandler } from '../actions/users/DontSuggestPasswordResetHandler';
import { HttpPollService } from './http/HttpPollService';
import { GetUnitPolls } from '../actions/polls/GetUnitPolls';
import { GetUnitPollsHandler } from '../actions/polls/GetUnitPollsHandler';
import { VotePoll } from '../actions/polls/VotePoll';
import { VotePollHandler } from '../actions/polls/VotePollHandler';
import { SendMessage } from '../actions/chat/SendMessage';
import { SendMessageHandler } from '../actions/chat/SendMessageHandler';
import { HttpChatService } from './http/HttpChatService';
import { GetMessages } from '../actions/chat/GetMessages';
import { GetMessagesHandler } from '../actions/chat/GetMessagesHandler';

export interface BaseActionsConfig {
    httpClient: HttpClient;
    sessionStorage: SessionStorage;
    eventBus: EventBus;
    deviceInfo: DeviceInfo;
    jsonDateSerializer: JsonDateSerializer;
    navigator: Navigator;
}

export abstract class BaseActionsInitializer {
    protected config!: BaseActionsConfig;
    protected readonly amenitiesService: HttpAmenitiesService;
    protected readonly analyticsService: HttpAnalyticsService;
    protected readonly announcementService: HttpAnnouncementService;
    protected readonly associationService: HttpAssociationService;
    protected readonly authService: HttpAuthService;
    protected readonly claimService: HttpClaimService;
    protected readonly documentService: HttpDocumentService;
    protected readonly fileUploader: HttpFileUploader;
    protected readonly paymentsService: HttpPaymentService;
    protected readonly unitService: HttpUnitService;
    protected readonly publicAnnouncementService: HttpPublicAnnouncementService;
    protected readonly pollsService: HttpPollService;
    protected readonly chatService: HttpChatService;

    protected constructor(config: BaseActionsConfig) {
        this.config = config;
        this.amenitiesService = new HttpAmenitiesService(this.config.httpClient);
        this.analyticsService = new HttpAnalyticsService(this.config.httpClient);
        this.announcementService = new HttpAnnouncementService(this.config.httpClient);
        this.associationService = new HttpAssociationService(this.config.httpClient);
        this.authService = new HttpAuthService(this.config.httpClient);
        this.claimService = new HttpClaimService(this.config.httpClient, this.config.jsonDateSerializer);
        this.documentService = new HttpDocumentService(this.config.httpClient, this.config.jsonDateSerializer);
        this.fileUploader = new HttpFileUploader(this.config.httpClient);
        this.paymentsService = new HttpPaymentService(this.config.httpClient);
        this.unitService = new HttpUnitService(this.config.httpClient);
        this.publicAnnouncementService = new HttpPublicAnnouncementService(this.config.httpClient);
        this.pollsService = new HttpPollService(this.config.httpClient);
        this.chatService = new HttpChatService(this.config.httpClient, this.config.jsonDateSerializer);
    }

    initialize(actionBus: ActionBus) {
        this.registerCommands(actionBus);
        this.registerQueries(actionBus);
    }

    protected registerCommands(actionBus: ActionBus) {
        actionBus.registerCommand(Login, new LoginHandler(this.authService, this.analyticsService, this.config.deviceInfo, this.config.sessionStorage, actionBus));
        actionBus.registerCommand(Logout, new LogoutHandler(this.config.sessionStorage));
        actionBus.registerCommand(InformPayment, new InformPaymentHandler(this.config.sessionStorage, this.paymentsService));
        actionBus.registerCommand(SelectUnit, new SelectUnitHandler(this.config.sessionStorage, this.config.navigator, this.config.eventBus));
        actionBus.registerCommand(Upload, new UploadHandler(this.fileUploader));
        actionBus.registerCommand(CreateClaim, new CreateClaimHandler(this.config.sessionStorage, this.claimService));
        actionBus.registerCommand(CommentClaim, new CommentClaimHandler(this.claimService));
        actionBus.registerCommand(BookAmenity, new BookAmenityHandler(this.amenitiesService, this.config.sessionStorage));
        actionBus.registerCommand(CancelBooking, new CancelBookingHandler(this.amenitiesService));
        actionBus.registerCommand(ResetPassword, new ResetPasswordHandler(this.authService, this.config.sessionStorage));
        actionBus.registerCommand(ForgotPassword, new ForgotPasswordHandler(this.authService));
        actionBus.registerCommand(ChangePassword, new ChangePasswordHandler(this.authService, this.config.sessionStorage));
        actionBus.registerCommand(DontSuggestPasswordReset, new DontSuggestPasswordResetHandler(this.authService, this.config.sessionStorage));
        actionBus.registerCommand(VotePoll, new VotePollHandler(this.config.sessionStorage, this.pollsService));
        actionBus.registerCommand(SendMessage, new SendMessageHandler(this.config.sessionStorage, this.chatService));
    }

    protected registerQueries(actionBus: ActionBus) {
        actionBus.registerQuery(GetUnitPaymentStatus, new GetUnitPaymentStatusHandler(this.unitService, this.config.sessionStorage));
        actionBus.registerQuery(GetUnitPayments, new GetUnitPaymentsHandler(this.paymentsService, this.config.sessionStorage));
        actionBus.registerQuery(GetRedepagosPayUrl, new GetRedepagosPayUrlHandler(this.paymentsService, this.config.sessionStorage));
        actionBus.registerQuery(GetUnitCVUNumber, new GetUnitCVUNumberHandler(this.paymentsService, this.config.sessionStorage));
        actionBus.registerQuery(GetAnnouncements, new GetAnnouncementsHandler(this.announcementService, this.config.sessionStorage));
        actionBus.registerQuery(GetUnitManager, new GetUnitManagerHandler(this.associationService, this.config.sessionStorage));
        actionBus.registerQuery(GetClaimSubjects, new GetClaimSubjectsHandler(this.claimService));
        actionBus.registerQuery(GetUnitClaims, new GetUnitClaimsHandler(this.config.sessionStorage, this.claimService));
        actionBus.registerQuery(GetClaimActivity, new GetClaimActivityHandler(this.claimService));
        actionBus.registerQuery(GetDocuments, new GetDocumentsHandler(this.documentService, this.config.sessionStorage));
        actionBus.registerQuery(GetAmenities, new GetAmenitiesHandler(this.amenitiesService, this.config.sessionStorage));
        actionBus.registerQuery(GetDisabledAmenities, new GetDisabledAmenitiesHandler(this.amenitiesService, this.config.sessionStorage));
        actionBus.registerQuery(GetAvailableBookingDays, new GetAvailableBookingDaysHandler(this.amenitiesService));
        actionBus.registerQuery(GetBookings, new GetBookingsHandler(this.amenitiesService, this.config.sessionStorage));
        actionBus.registerQuery(SearchDateAvailableSlots, new SearchDateAvailableSlotsHandler(this.amenitiesService));
        actionBus.registerQuery(GetUsefulContacts, new GetUsefulContactsHandler(this.associationService, this.config.sessionStorage));
        actionBus.registerQuery(GetPublicAnnouncement, new GetPublicAnnouncementHandler(this.publicAnnouncementService));
        actionBus.registerQuery(GetUnitPolls, new GetUnitPollsHandler(this.pollsService, this.config.sessionStorage));
        actionBus.registerQuery(GetMessages, new GetMessagesHandler(this.chatService, this.config.sessionStorage));
    }
}
