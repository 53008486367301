import { HttpClient } from '../../_base/http/HttpClient';
import { PublicAnnouncementService } from '../../models/publicAnnouncements/PublicAnnouncementService';
import { PublicAnnouncement } from '../../models/publicAnnouncements/PublicAnnouncement';

export class HttpPublicAnnouncementService implements PublicAnnouncementService {
    constructor(private httpClient: HttpClient) {}

    async getAnnouncement(token: string): Promise<PublicAnnouncement> {
        const response = await this.httpClient.get(`/announcements/${token}/view`);
        return PublicAnnouncement.fromJson(response.body);
    }
}
