import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { GetUnitPayments } from './GetUnitPayments';
import { UnitPayment } from '../../models/payments/UnitPayment';
import { PaymentService } from '../../models/payments/PaymentService';

export class GetUnitPaymentsHandler implements QueryHandler<GetUnitPayments, UnitPayment[]> {
    constructor(private paymentService: PaymentService, private sessionStorage: SessionStorage) {}

    async handle(query: GetUnitPayments): Promise<UnitPayment[]> {
        const session = await this.sessionStorage.get();
        return this.paymentService.getUnitPayments(session.unit!.id);
    }
}
