import { EventsLogger } from '@eroman/common/src/infrastructure/EventsLogger';
import gtag from 'ga-gtag';

export class AnalyticsEventLogger implements EventsLogger {
    async logScreenViewEvent(screenName: string): Promise<void> {
        try {
            gtag('event', 'page_view', {
                page_title: screenName,
            });
        } catch (e) {
            console.log(e);
        }
    }

    logCustomEvent(eventName: string, payload: Record<string, any>): Promise<void> {
        return Promise.resolve(undefined);
    }
}
