import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { AuthService } from '../../models/auth/AuthService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { DontSuggestPasswordReset } from './DontSuggestPasswordReset';

export class DontSuggestPasswordResetHandler implements CommandHandler<DontSuggestPasswordReset> {
    constructor(private authService: AuthService, private sessionStorage: SessionStorage) {
    }

    async handle(command: DontSuggestPasswordReset): Promise<void> {
        await this.dontSuggestPasswordReset();
    }

    private async dontSuggestPasswordReset() {
        await this.authService.dontSuggestPasswordReset();
        const session = await this.sessionStorage.get();
        session.suggestPasswordReset = false;
    }
}
