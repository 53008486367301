import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetUsefulContacts } from './GetUsefulContacts';
import { SessionStorage } from '../../models/session/SessionStorage';
import { AssociationService } from '../../models/associations/AssociationService';

export class GetUsefulContactsHandler implements QueryHandler<GetUsefulContacts, string> {
    constructor(private service: AssociationService, private sessionStorage: SessionStorage) {}

    async handle(query: GetUsefulContacts): Promise<string> {
        const session = await this.sessionStorage.get();
        return await this.service.getUsefulContactsFor(session.unit!!.association.id);
    }
}
