import React from 'react';
import styled from 'styled-components';
import lastPaymentIcon from './last_payments.png';
import paymentDetailIcon from './payment_detail.png';
import { SectionHeader } from '../../components/SectionHeader';
import { PaymentRow } from './PaymentRow/PaymentRow';
import { PaymentInfo } from './paymentDetail/PaymentInfo';
import AppContext from '../../lib/AppContext';
import { PaymentsView } from './PaymentsPresenter';
import { PaymentsVM } from './PaymentsVM';
import { Loader } from '../../components/Loader';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { RouterProps } from '../../lib/navigation/reactRouter/RouterProps';
import { InformPaymentModal } from '../InformPayment/InformPaymentModal';
import { Toast } from '../../components/Toast';
import { styles } from '../../styles';
import { ScrollableList } from '../../components/ScrollableList';

export class PaymentsPage extends React.Component<RouterProps> implements PaymentsView {
    private presenter = AppContext.presenters.payments(this);

    state = {
        model: new PaymentsVM(),
    };

    modelChanged(model: PaymentsVM) {
        this.setState({ model });
    }

    async componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const isModalOpen = queryParams.get('isModalOpen') === 'true';
        await this.presenter.start(isModalOpen);
    }

    componentWillUnmount() {
        this.presenter.stop();
    }

    renderPayments() {
        let selectedPayment = this.state.model.selectedPayment;
        return <>
            <Payments>
                <Toast/>
                <_SectionHeader icon={lastPaymentIcon} title={'Últimos pagos'}/>
                {this.state.model.payments.length > 0 &&
                    <ScrollableList>
                        {this.state.model.payments.map((item) => (
                            <_PaymentRow
                                key={item.id}
                                item={item}
                                selected={selectedPayment?.id === item.id}
                                onClick={this.presenter.selectPayment}
                            />
                        ))}
                    </ScrollableList> ||
                    <NoPayments>No hay pagos registrados</NoPayments>
                }
            </Payments>
            <Detail>
                {
                    (this.state.model.payments.length > 0 && selectedPayment) &&
                    <>
                        <SectionHeader icon={paymentDetailIcon} title={'Información del pago'}/>
                        <PaymentInfo item={selectedPayment} url={this.presenter.generatePaymentReceiptUrl()}/>
                    </>
                }
            </Detail>
        </>;
    }

    render() {
        return (
            <Container>
                {this.state.model.isModalOpen && <InformPaymentModal isOpen={this.state.model.isModalOpen} closeModal={() => this.toggleModal()}/> }
                <Header>
                    <PrimaryButton onClick={() => this.toggleModal()}>
                        Informar pago
                    </PrimaryButton>
                    <RedepagosWarning>
                        Recuerde que no es necesario informar su pago si utiliza los servicios de RED DE PAGOS S.A.
                    </RedepagosWarning>
                </Header>
                <Box>
                    {this.state.model.isLoading ? <Loader/> : this.renderPayments()}
                </Box>
            </Container>
        );
    }

    private toggleModal() {
        this.setState({
            model: {
                ...this.state.model,
                isModalOpen: !this.state.model.isModalOpen,
            },
        });
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const RedepagosWarning = styled.p`
  max-width: 270px;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.xsmall};
  color: #666F89;
  text-align: center;
  margin-left: 20px;
`;

const Box = styled.div`
  display: flex;
  height: 500px;
  flex-grow: 1;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Payments = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  padding: 20px 9px 20px 20px;
  border-right: 1px solid rgba(146, 162, 195, 0.5);
`;

const Detail = styled.div`
  width: 50%;
  padding: 20px;
`;

const _SectionHeader = styled(SectionHeader)`
  flex-shrink: 0;
`;

const _PaymentRow = styled(PaymentRow)`
  margin-right: 17px;
  margin-bottom: 5px;
`;

const NoPayments = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;
