import React from 'react';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const Row: React.FC<Props> = (props) => {
    if (!props.content) return null;
    return <Container className={props.className}>
        <Header>
            <Title>{props.title}</Title>
            <Icon src={props.icon}/>
        </Header>
        <Content>{props.content}</Content>
    </Container>;
};


const Container = styled.div`
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 35px;
  flex-grow: 0;
  flex-shrink: 1;
`;

const Title = styled.p`
  color: #8A96BA;
  flex-grow: 1;
  font-size: ${styles.fonts.size.regular};
`;

const Content = styled.p`
  color: #666F89;
  flex-grow: 1;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.regular};
`;

interface Props {
    title: string,
    content: string,
    icon: string,
    className?: string;
}
