import { HttpClient } from '../../_base/http/HttpClient';
import { PaymentService } from '../../models/payments/PaymentService';
import { Money } from '../../models/general/Money';
import { LocalDate } from '../../_base/time/LocalDate';
import { UnitPayment } from '../../models/payments/UnitPayment';
import { Nullable } from '../../_base/lang/Nullable';
import { FileInfo } from '../../models/general/FileInfo';
import { ExpenseNotFoundError } from '../../models/expenses/ExpenseNotFoundError';

export class HttpPaymentService implements PaymentService {
    constructor(private httpClient: HttpClient) {}

    async createPayment(unitId: number, date: LocalDate, amount: Money, transactionNumber: string, receiptFile: Nullable<FileInfo>) {
        const body = {
            units: [{ id: unitId, amount: amount.plainString() }],
            date: date.toISO8601String(),
            transactionNumber,
            receiptFile,
        };
        await this.httpClient.post('/payments', body);
    }

    async getUnitPayments(unitId: number): Promise<UnitPayment[]> {
        const response = await this.httpClient.get(`/units/${unitId}/payments`);
        return response.body.payments.map(json => UnitPayment.fromJson(json));
    }

    async getUnitRedepagosPayUrl(unitId: number): Promise<string> {
        try {
            const response = await this.httpClient.get(`/redepagosPay?unitId=${unitId}`);
            return response.body.url;
        } catch (e: any) {
            if (e.status === 404 && e.data.type == 'ExpenseNotFoundError') { throw new ExpenseNotFoundError();}
            throw e;
        }
    }

    async getUnitCVUTransferNumber(unitId: number): Promise<string> {
        const response = await this.httpClient.get(`/units/${unitId}/CVUNumber`);
        return response.body.CVUNumber;
    }

    async generateReceipt(paymentId: number, unitId: number): Promise<Blob> {
        try {
            let response = await this.httpClient.get(`/receipts?paymentId=${paymentId}&unitId=${unitId}`);
            console.log(response);
            return response.body;
        } catch (error) {
            console.error('Error generating receipt:', error);
            throw error;
        }
    }

}
