import { AssociationService } from '../../models/associations/AssociationService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { GetUnitManager } from './GetUnitManager';
import { Manager } from '../../models/managers/Manager';

export class GetUnitManagerHandler implements QueryHandler<GetUnitManager, Manager> {
    constructor(private associationService: AssociationService, private sessionStorage: SessionStorage) {}

    async handle() {
        const session = await this.sessionStorage.get();
        const associationId = session.unit?.association.id;
        return await this.associationService.getManagerFor(associationId!);
    }
}
