import { HttpClient } from '../../_base/http/HttpClient';
import { Manager } from '../../models/managers/Manager';
import { AssociationService } from '../../models/associations/AssociationService';

export class HttpAssociationService implements AssociationService {
    constructor(private httpClient: HttpClient) {
    }

    async getManagerFor(associationId: number): Promise<Manager> {
        const response = await this.httpClient.get(`/associations/${associationId}/manager`);
        return Manager.fromJson(response.body);
    }

    async getUsefulContactsFor(associationId: number): Promise<string> {
        const response = await this.httpClient.get(`/associations/${associationId}/usefulContacts`);
        const content = response.body['content'];
        if (typeof content === 'undefined') return '';
        return content;
    }
}
