import React from 'react';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const MonthDetailRow: React.FC<Props> = (props) => (
    <Container>
        <Indicator/>
        <Title color={props.titleColor}>{props.title}</Title>
        <Value color={props.valueColor}>{props.value}</Value>
    </Container>
);

const Container = styled.div`
  padding: 12px 14px 7px;
  display: flex;
  align-items: center;
`;

const Indicator = styled.span`
  border-radius: 4px;
  width: 7px;
  height: 35px;
  background-color: #178bff;
`;

const Title = styled.div`
  font-size: ${styles.fonts.size.big};
  color: ${props => props.color};
  margin-left: 14px;
  flex-grow: 0;
`;

const Value = styled.div`
  font-size: 22px;
  font-weight: ${styles.fonts.weight.bold};
  color: ${props => props.color};
  text-align: right;
  flex-grow: 1;
`;

interface Props {
    title: string,
    value: string,
    titleColor?: string,
    valueColor?: string,
}
