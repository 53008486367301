import React from 'react';
import { CurrentUnitView } from '@eroman/common/src/views/components/CurrentUnit/CurrentUnitPresenter';
import { CurrentUnitVM } from '@eroman/common/src/views/components/CurrentUnit/CurrentUnitVM';
import AppContext from '../../lib/AppContext';
import styled from 'styled-components';
import user from './user.png';
import { styles } from '../../styles';

export class CurrentUnit extends React.Component<any, State> implements CurrentUnitView {
    private readonly unitRef: React.RefObject<HTMLAnchorElement>;
    private presenter = AppContext.presenters.currentUnit(this);

    constructor(props) {
        super(props);
        this.unitRef = React.createRef();
    }

    state = { model: new CurrentUnitVM, unitMenuOpened: false };

    modelChanged(model: CurrentUnitVM) {
        this.setState({ model });
    }

    async componentDidMount() {
        document.addEventListener('click', this.onClickOutside.bind(this), true);
        await this.presenter.start();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside.bind(this), true);
    }

    private onClickOutside(e) {
        if (this.unitRef.current?.contains(e.target)) return;

        this.setState({ unitMenuOpened: false });
    }

    private toggleUnitMenu(e) {
        e.preventDefault();
        this.setState((state) => ({ unitMenuOpened: !state.unitMenuOpened }));
    }

    render() {
        return <Container>
            <Manager>{this.state.model.managerName}</Manager>
            <UnitSelector className={this.state.unitMenuOpened ? 'open' : ''}>
                <ActiveUnit href="#" onClick={e => this.toggleUnitMenu(e)} ref={this.unitRef}>
                    <p>{this.state.model.unitFullAddress}</p>
                    <img src={user}/>
                </ActiveUnit>
                <ul>
                    {this.state.model.units.filter(it => it.fullAddress !== this.state.model.unitFullAddress).map(it => (
                        <li key={it.id} onClick={e => this.presenter.selectUnit(it.id)}>
                            <span>{it.fullAddress}</span>
                        </li>
                    ))}
                </ul>
            </UnitSelector>
        </Container>;
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.xsmall};
  line-height: 16px;
  position: relative;
`;

const Manager = styled.div`
  color: #8A96BA;
  width: 150px;
  border-right: 0.5px solid #8A96BA;
  padding: 7px 0;
`;

const ActiveUnit = styled.a``;

const UnitSelector = styled.div`
  ${ActiveUnit} {
    position: relative;
    color: #6C789C;
    width: 250px;
    margin-left: 10px;
    background-color: #DBE0ED;
    padding: 7px 7px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    text-decoration: none;
    align-items: center;
    
    &:hover {
      background-color: #EDF0F6;
      color: #064192;
    }

    &:visited, &:active {
      color: #6C789C;
    }
  }

  p {
    flex-grow: 1;
  }

  img {
    width: 28px;
  }

  ul {
    display: none;
    position: absolute;
    z-index: 1000;
    min-width: 250px;
    top: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    background-color: #DBE0ED;
    list-style: none;
    border-radius: 0 0 5px 5px;
    text-align: left;

    li {
      display: block;
      float: left;
      padding: 10px 7px;
      border-radius: 5px;
      cursor: pointer;
      align-items: center;

      &:hover {
        background-color: #EDF0F6;
        color: #064192;
        border-radius: 0;
        
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }

  &.open {
    ul {
        display: flex;
        flex-direction: column;
    }

    ${ActiveUnit} {
      border-radius: 5px 5px 0 0;
    }
  }
`;

interface State {
    model: CurrentUnitVM,
    unitMenuOpened: boolean,
}
