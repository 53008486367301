import React from 'react';
import closeIcon from './x.png';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const AmenityModalHeader: React.FC<Props> = (props) => {
    return <Header>
        <UpperBox>
            <Title>
                {props.amenityName}
            </Title>
            <Close onClick={props.closeModal}><img src={closeIcon} alt="Cerrar"/></Close>
        </UpperBox>
        <LowerBox>
            <Info>{props.info}</Info>
        </LowerBox>
    </Header>;
};

const Title = styled.p`
  font-size: ${styles.fonts.size.xxbig};
  font-weight: ${styles.fonts.weight.bold};
  color: #2B3674;
  margin-top: 0;
  line-height: 36px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 10px;
`;

const UpperBox = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: ${styles.fonts.size.regular};
    font-weight: ${styles.fonts.weight.regular};
    color: #666F89;
    margin-top: 10px;
  }

  ${Title} {
    font-size: ${styles.fonts.size.xxbig};
    font-weight: ${styles.fonts.weight.bold};
    color: #2B3674;
    margin-top: 0;
    line-height: 28px;
  }

  a {
    flex-shrink: 0;
    height: min-content;
  }
`;

const LowerBox = styled.div`
  margin-top: 10px;
`;

const Info = styled.p`
  font-size: ${styles.fonts.size.small};
  font-weight: ${styles.fonts.weight.regular};
  color: #666F89;
  line-height: 21px;
`;

const Close = styled.a`
  & img:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

interface Props {
    amenityName: string;
    closeModal: () => void;
    info?: string
}
