import React from 'react';
import closeIcon from '../InformPayment/x.png';
import styled from 'styled-components';
import { FormItemPickerField } from '../../components/form/FormItemPicker/FormItemPickerField';
import AppContext from '../../lib/AppContext';
import { FormTextAreaField } from '../../components/form/FormTextArea/FormTextAreaField';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { Loader } from '../../components/Loader';
import { toast } from 'react-toastify';
import { FormGeneralError } from '../../components/form/FormGeneralError';
import { CreateClaimView } from '@eroman/common/src/views/app/Claims/CreateClaim/CreateClaimPresenter';
import { CreateClaimVM } from '@eroman/common/src/views/app/Claims/CreateClaim/CreateClaimVM';
import { FormFileField } from '../../components/form/FormFileField/FormFileField';
import { FormFileFieldStates } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldVM';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';
import { UploadedFile } from './UploadedFile';
import { styles } from '../../styles';
import { RightModal } from '../../components/modals/RightModal';

export class CreateClaimModal extends React.Component<Props, State> implements CreateClaimView {
    private presenter = AppContext.presenters.createClaim(this);

    state = {
        model: new CreateClaimVM(),
        canSubmit: true,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    showSuccessMessage() {
        this.props.closeModal();
        toast.success('Reclamo iniciado con éxito');
    }

    parseSubjectsToOptions = () => {
        return this.state.model.subjects.map(it => ({ value: it.id, label: it.title }));
    };

    onAttachmentStateChanged(state) {
        this.setState({ canSubmit: state !== FormFileFieldStates.Uploading });
    }

    onChangeFile(model: Nullable<FileInfo>) {
        this.presenter.addAttachment(model);
    }

    renderForm() {
        return <>
            <FormGeneralError error={this.state.model.errors['']}/>
            <Form>
                <_DropDown
                    label="Asunto"
                    options={this.parseSubjectsToOptions()}
                    onChangeSelected={subjectId => this.presenter.set({ subjectId })}
                    error={this.state.model.errors['subjectId']}
                    value={this.state.model.subjectId}
                />
                <FormTextAreaField
                    label="Descripción"
                    onChangeText={description => this.presenter.set({ description })}
                    error={this.state.model.errors['description']}
                    value={this.state.model.description}
                />
                {
                    this.state.model.attachments.map(attachment => (
                        <UploadedFile
                            key={attachment.name}
                            attachment={attachment}
                            unSelectFile={name => this.presenter.removeAttachment(name)}
                        />
                    ))
                }
                <FormFileField
                    key={this.state.model.attachments.length}
                    className="large"
                    label="Adjunto"
                    onChangeFile={attachment => this.onChangeFile(attachment)}
                    onChangeState={state => this.onAttachmentStateChanged(state)}
                    error={this.state.model.errors['attachments']}
                />
                <_PrimaryButton onClick={() => this.presenter.submit()}>Iniciar reclamo</_PrimaryButton>
            </Form>
        </>;
    }

    modelChanged(model: CreateClaimVM) {
        this.setState({ model });
    }

    render() {
        return (
            <RightModal
                isOpen={this.props.isOpen}
                closeModal={this.props.closeModal}
            >
                <>
                    <Header>
                        <Column>
                            <Title>Nuevo reclamo</Title>
                            <p>Complete el formulario para enviar su reclamo a la Administración</p>
                        </Column>
                        <Close onClick={this.props.closeModal}><img src={closeIcon} alt="Cerrar"/></Close>
                    </Header>
                    {this.state.model.isLoading ? <Form><Loader/></Form> : this.renderForm()}
                </>
            </RightModal>
        );
    }
}

const Title = styled.p``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 10px;

  p {
    font-size: ${styles.fonts.size.regular};
    font-weight: ${styles.fonts.weight.regular};
    color: #666F89;
    margin-top: 10px;
  }

  ${Title} {
    font-size: ${styles.fonts.size.xxbig};
    font-weight: ${styles.fonts.weight.bold};
    color: #2B3674;
    margin-top: 0;
    line-height: 28px;
  }

  a {
    flex-shrink: 0;
    height: min-content;
  }
`;

const Close = styled.a`
  & img:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const Column = styled.div`
  width: 100%;
`;

const FormBody = styled.div``;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  margin-bottom: 25px;
  flex-grow: 1;
  gap: 25px;

  ${FormBody} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      flex-basis: 262px;
    }

    > .large {
      flex-basis: 100%;
    }
  }
`;

const _PrimaryButton = styled(PrimaryButton)`
  width: fit-content;
`;

const _DropDown = styled(FormItemPickerField)`
  padding-bottom: 25px;
`;

interface Props {
    isOpen: boolean,
    closeModal: () => void,
}

interface State {
    model: CreateClaimVM;
    canSubmit: boolean;
}
