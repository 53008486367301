import React from 'react';
import titleFile from './title-file.png';
import { ScrollableList } from '../../components/ScrollableList';
import styled from 'styled-components';
import { SectionHeader } from '../../components/SectionHeader';
import { AmenityBox } from './AmenityBox/AmenityBox';
import { AmenitiesVM } from '@eroman/common/src/views/app/Amenities/AmenitiesVM';
import AppContext from '../../lib/AppContext';
import { Loader } from '../../components/Loader';
import { styles } from '../../styles';
import { AmenityInfoModal } from '../AmenityInfo/AmenityInfoModal';
import { AmenitiesView } from './AmenitiesPresenter';

export class AmenitiesPage extends React.Component implements AmenitiesView {
    private presenter = AppContext.presenters.amenities(this);

    state = {
        model: new AmenitiesVM(),
        isLoading: false,
        isModalOpen: false,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    modelChanged(model: AmenitiesVM) {
        this.setState({ model });
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    private renderAmenities() {
        return <>
            {
                this.state.model.amenities.length > 0 &&
                <_ScrollableList>
                    {this.state.model.amenities.map(amenity => (
                        this.state.model.disabledAmenities.contains(amenity.id) &&
                            <AmenityBox
                                key={amenity.id}
                                id={amenity.id}
                                name={amenity.name}
                                iconPath={amenity.iconPath}
                                isDisabled={true}
                            /> ||
                            <AmenityBox
                                key={amenity.id}
                                id={amenity.id}
                                name={amenity.name}
                                iconPath={amenity.iconPath}
                                onClick={async () => {
                                    await this.presenter.selectAmenity(amenity.id);
                                    this.toggleModal();
                                }}
                                isDisabled={false}
                            />
                    ))}

                </_ScrollableList> ||
                <NoAmenities>No hay amenities registrados.</NoAmenities>
            }
        </>;
    }

    render() {
        return <>
            {this.state.isModalOpen &&
            <AmenityInfoModal
                isOpen={this.state.isModalOpen}
                closeModal={() => this.toggleModal()}
                amenity={this.state.model.selectedAmenity!}
            />
            }
            <Box>
                <Amenities>
                    <SectionHeader icon={titleFile} title={'Espacios comunes'}/>
                    {this.state.model.isLoading ? <Loader/> : this.renderAmenities()}
                </Amenities>
            </Box>
        </>;
    }
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  flex-grow: 1;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Amenities = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 20px 9px 20px 20px;
`;

const NoAmenities = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;

const _ScrollableList = styled(ScrollableList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;
