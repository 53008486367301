export class Amenity {
    constructor(
        public id: number,
        public name: string,
        public general: string,
        public availability: string,
        public slots: string,
        public iconPath: string,
    ) {}

    toJson() {
        return {
            id: this.id,
            name: this.name,
            general: this.general,
            availability: this.availability,
            slots: this.slots,
            iconPath: this.iconPath,
        };
    }

    static fromJson(json: any) {
        return new Amenity(json.id, json.name, json.info.general, json.info.availability, json.info.slots, json.iconPath);
    }
}
