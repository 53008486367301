import { Clock } from './Clock';
import { LocalDateTime } from './LocalDateTime';
import { LocalDate } from './LocalDate';

export class SystemClock implements Clock {
    now(): LocalDateTime {
        return LocalDateTime.now();
    }

    today(): LocalDate {
        return this.now().toDate();
    }
}
