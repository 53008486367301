import React from 'react';
import { LocalDate } from '@eroman/common/src/_base/time/LocalDate';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { styles } from '../../styles';
import { differenceInCalendarDays } from 'date-fns';

export const CalendarField: React.FC<Props> = (props) => {

    return <Container>
        {props.daysToShow.isNotEmpty() ?
            <Calendar
                onChange={selectedDate => props.onChange(LocalDate.fromStandardDate(selectedDate).toISO8601String())}
                value={props.value && LocalDate.fromISO8601String(props.value).toDate()}
                minDate={LocalDate.fromISO8601String(props.daysToShow.first()).toDate()}
                maxDate={LocalDate.fromISO8601String(props.daysToShow.last()).toDate()}
                minDetail='month'
                tileDisabled={props.daysToShow.isNotEmpty() && tileDisabled}
            /> :
            <Calendar
                minDate={LocalDate.today().toDate()}
                maxDate={LocalDate.today().toDate()}
            />
        }
    </Container>;

    function dateWithoutTimeZone(date: string) {
        let firstDate = new Date(date);
        let userTimezoneOffset = firstDate.getTimezoneOffset() * 60000;
        return new Date(firstDate.getTime() + userTimezoneOffset);
    }

    function calculateDatesInBetween(currentDate: Date, lastDate: Date) {
        const dates: Date[] = [];
        while (currentDate <= lastDate) {
            dates.push(new Date(currentDate));
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }

    function getDisabledDatesFrom(dates: Date[]) {
        return dates.filter(d => !props.daysToShow.includes(d.toISOString().split('T')[0]));
    }

    function generateDisabledDates() {
        let currentDate = dateWithoutTimeZone(props.daysToShow.first());
        let lastDate = dateWithoutTimeZone(props.daysToShow.last());
        const dates = calculateDatesInBetween(currentDate, lastDate);
        return getDisabledDatesFrom(dates);
    }

    function tileDisabled({ date, view }) {
        if (view === 'month') {
            return generateDisabledDates().find(dDate => differenceInCalendarDays(dDate, date) === 0);
        }
    }
};

const Container = styled.div`
  .react-calendar {
    background-color: #F5F5F5;
    margin: 10px auto;
    border-radius: 13px;
    border-color: #F4F7FE;
    padding: 5px;
    box-shadow: 0 1px 1.5px rgba(0, 14, 51, 0.25);
    
    .react-calendar__month-view__days {
      justify-content: center;
    }

    .react-calendar__navigation__label {
      box-shadow: 0 1px 1px rgba(0, 14, 51, 0.05);
      border-radius: 5px;
      font-weight: ${styles.fonts.weight.bold};
      font-size: ${styles.fonts.size.big};
      background-color: #FFFFFF;
      color: #1F1F1F;
      margin: 0 5%;
      
      &:first-letter {
        text-transform: uppercase;
      }
    }
    
    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__tile {
      background-color: #FFFFFF;
      border-radius: 5px;
      font-weight: ${styles.fonts.weight.regular};
      font-size: ${styles.fonts.size.small};
      color: #1F1F1F;
      margin: 1px;
      max-width: 45px;
      max-height: 45px;

      &:hover {
        background-color: #178BFF;
        color: #FFFFFF;
      }
      
      &[disabled] {
        background-color: #F5F5F5;
        color: rgba(0, 23, 84, 0.15);
      }
    }

    .react-calendar__tile--now {
      background-color: #11AB8F;
    }

    .react-calendar__tile--active {
      background-color: #178BFF;
      color: #FFFFFF !important;
    }
    
    .react-calendar__month-view__days__day--weekend {
      color: #1F1F1F; 
    }
    
    .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
      display: none;
    }
    
    .react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
      background: #FFFFFF;
      box-shadow: 0 1px 1px rgba(0, 14, 51, 0.05);
      border-radius: 60px;
      font-size: large;
      
      &:hover {
        &[disabled] {
          background-color: #FFFFFF;
          color: rgba(16, 16, 16, 0.3);
        }
        
        background-color: #178BFF;
        color: #FFFFFF;
      }
    }
  }
`;

interface Props {
    value: Nullable<string>;
    onChange: (value: string) => {};
    daysToShow: string[];
}
