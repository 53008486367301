import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { AuthService } from '../../models/auth/AuthService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { Validator } from '../../_base/validation/Validator';
import { InvalidCredentialsError } from '../../models/auth/InvalidCredentialsError';
import { Login, RegularCredentials, TokenCredentials } from './Login';
import { AnalyticsService } from '../../models/AnalyticsService';
import { DeviceInfo } from '../../models/DeviceInfo';
import { ActionBus } from '../../_base/actionBus/ActionBus';
import { RegisterDeviceToken } from '../registerDeviceToken/RegisterDeviceToken';

export class LoginHandler implements CommandHandler<Login> {
    constructor(
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private deviceInfo: DeviceInfo,
        private sessionStorage: SessionStorage,
        private actionBus: ActionBus
    ) {}

    async handle(command: Login) {
        await this.doLogin(command);
        await this.registerDevice();
    }

    private async doLogin(command: Login) {
        switch (command.credentials.type) {
            case 'RegularCredentials': return await this.loginWithRegularCredentials(command);
            case 'TokenCredentials': return await this.loginWithTokenCredentials(command);
        }
    }

    private async loginWithTokenCredentials(command: Login) {
        const credentials = command.credentials as TokenCredentials;
        const response = await this.authService.loginWithToken(credentials.token);
        await this.sessionStorage.do(session => {
            session.authenticate(response.user);
            session.selectUnit(parseInt(response.unitId));
        });
    }

    private async loginWithRegularCredentials(command: Login) {
        const credentials = command.credentials as RegularCredentials;
        this.validate(credentials);
        try {
            const response = await this.authService.login(credentials.email, credentials.password);
            await this.sessionStorage.do(session => {
                session.authenticate(response.user);
                session.selectUnit(parseInt(response.unitId));
                session.suggestPasswordReset = response.suggestPasswordReset;
            });
            await this.actionBus.exec(new RegisterDeviceToken());
        } catch (e: any) {
            Validator.errorToValidationError(e, [
                { error: InvalidCredentialsError, message: 'Las credenciales son inválidas' },
            ]);
        }
    }

    private validate(credentials: RegularCredentials) {
        const validator = new Validator();
        validator.check('email', credentials.email)
            .notNullOrBlank('Debe ingresar el email')
            .email('El email es inválido');
        validator.check('password', credentials.password).notNullOrBlank('Debe ingresar la contraseña');
        validator.throwIfHasErrors();
    }

    private async registerDevice() {
        try {
            await this.analyticsService.registerDevice(this.deviceInfo);
        } catch (e) {
            // Do Nothing
        }
    }
}
