import { PaymentStatuses } from '@eroman/common/src/models/payments/PaymentStatuses';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { PaymentSources } from '@eroman/common/src/models/payments/PaymentSources';

export class PaymentsVM {
    isLoading = false;
    payments: PaymentDetailVM[] = [];
    selectedPayment: Nullable<PaymentDetailVM> = null;
    isModalOpen = false;
}

export class PaymentDetailVM {
    id = 0;
    period: Nullable<string> = null;
    transactionNumber: Nullable<string> = null;
    status = PaymentStatuses.Informed;
    source = PaymentSources.Unknown;
    date = '';
    amount = '';
}
