import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { PaymentService } from '../../models/payments/PaymentService';
import { GetRedepagosPayUrl } from './GetRedepagosPayUrl';

export class GetRedepagosPayUrlHandler implements QueryHandler<GetRedepagosPayUrl, string> {
    constructor(private paymentService: PaymentService, private sessionStorage: SessionStorage) {}

    async handle(query: GetRedepagosPayUrl): Promise<string> {
        const session = await this.sessionStorage.get();
        return this.paymentService.getUnitRedepagosPayUrl(session.unit!.id);
    }
}
