import React from 'react';
import styled from 'styled-components';
import file from './file.png';
import { FileVM } from '../../app/Announcements/AnnouncementsVM';
import { styles } from '../../styles';

export const AttachmentList: React.FC<Props> = (props) => {
    return <List>
        {props.attachments.map(attachment =>
            <Attachment key={attachment.url}>
                <Icon src={file}/>
                <Label href={attachment.url} target="_blank">{attachment.label}</Label>
            </Attachment>,
        )}
    </List>;
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const Label = styled.a``;

const Icon = styled.img``;

const Attachment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  
  ${Label} {
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    font-weight: ${styles.fonts.weight.bold};
    margin-right: 5px;
    margin-left: 5px;
    display: block;
    color: #178BFF;
    
    &:hover {
      color: #1565D8;
    }
  }
  
  ${Icon} {
    width: 10px;
  }
`;

interface Props {
    attachments: FileVM[];
}
