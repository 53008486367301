import React from 'react';
import styled from 'styled-components';

export const NotFound = () => {
    return (
        <Container>
            <ErrorNumbers>
                <Number>4</Number>
                <Number color={'#178BFF'}>0</Number>
                <Number>4</Number>
            </ErrorNumbers>
            <Message>Page not found</Message>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'DM Sans', sans-serif;
`;

const ErrorNumbers = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 80px;
  font-weight: 600;
  text-align: center;
`;

const Number = styled.p.attrs({})<{ color?: string; }>`
  color: ${props => props.color || '#2B3674'}
`;

const Message = styled.h3`
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  color: #42557B;
`;
