import { BankTransferProvider } from '../../../models/associations/paymentProviders/BankTransferProvider';
import { Nullable } from '../../../_base/lang/Nullable';

export class ExpensesVM {
    state = ViewStates.NoExpenses;
    expense: Nullable<ExpenseInfoVM> = null;
    paymentInfo: Nullable<PaymentInfoVM> = null;
    showRedepagos = false;
    showCash = false;
    redepagosPayUrl: Nullable<string> = null;
    bankTransferProvider: Nullable<BankTransferProvider> = null;
    CVUNumber: Nullable<string> = null;
    showCVUTransfer = false;
    showOtherPaymentMethods = true;
}

export enum ViewStates {
    Loading = 'Loading',
    NoExpenses = 'NoExpenses',
    NoPayments = 'NoPayments',
    Payments = 'Payments',
}

export interface ExpenseInfoVM {
    period: string;
    dueDate1: string;
    amount1: string;
    dueDate2: string;
    amount2: string;
    downloadUrl: Nullable<string>;
}

export interface PaymentInfoVM {
    dueDateNumberToPay: string;
    dueDate: string;
    amount: string;
    balanceType: BalanceTypes;
    balance: string;
    payments: PaymentVM[];
}

export interface PaymentVM {
    id: number;
    date: string;
    amount: string;
}

export enum BalanceTypes {
    Positive = 'Positive',
    Negative = 'Negative',
    Zero = 'Zero',
}
