import React from 'react';
import styled from 'styled-components';
import pollDetail from './poll_detail.svg';
import { RouterProps } from '../../lib/navigation/reactRouter/RouterProps';
import { PollsVM } from '@eroman/common/src/views/app/Polls/PollsVM';
import { PollRow } from './PollRow/PollRow';
import { ScrollableList } from '../../components/ScrollableList';
import { SectionHeader } from '../../components/SectionHeader';
import { PollInfo } from './PollInfo';
import AppContext from '../../lib/AppContext';
import { styles } from '../../styles';
import { PollStates } from '@eroman/common/src/models/polls/Poll';
import { PollsView } from './PollsPresenter';

export class PollsPage extends React.Component<RouterProps> implements PollsView {
    private presenter = AppContext.presenters.polls(this);

    state = {
        model: new PollsVM(),
    };

    modelChanged(model: PollsVM) {
        this.setState({ model });
    }

    async componentDidMount() {
        await this.presenter.start();
    }

    render() {
        return (
            <Container>
                <Header>
                </Header>
                <Box>
                    {
                        this.state.model.polls.length > 0 ?
                            <>
                                <_Polls>
                                    <SectionHeader icon={pollDetail} title={'Votaciones'}/>
                                    <ScrollableList>
                                        {this.state.model.polls.map((poll, index) => (
                                            <PollRow
                                                key={index}
                                                item={poll}
                                                selected={this.state.model?.selectedPoll?.id ? poll.id === this.state.model.selectedPoll.id : false}
                                                onClick={() => this.presenter.selectPoll(poll.id)}
                                                ongoing={!this.presenter.isPollEnded(poll.id)}
                                            />
                                        ))}
                                    </ScrollableList>
                                </_Polls>
                                <Detail>
                                    <SectionHeader icon={pollDetail} title={'Detalle'}/>
                                    <PollInfo
                                        item={ this.state.model.selectedPoll! }
                                        onClick={ this.presenter.selectOption }
                                        onSubmit={ this.presenter.vote }
                                        isEnabled={ this.isPollEnabled() }
                                        isEnded={this.presenter.isSelectedPollEnded()}
                                        text={this.getButtonText()}
                                    />
                                </Detail>
                            </>
                            :
                            <NoPolls>No hay votaciones registradas</NoPolls>
                    }
                </Box>
            </Container>
        );
    }

    private isPollEnabled() {
        return (
            this.state.model.selectedPoll?.selectedOption != null
            && !this.state.model.selectedPoll?.unitHasAlreadyVoted
            && !this.presenter.isSelectedPollEnded()
            && this.state.model.selectedPoll.status !== PollStates.CLOSED
        );
    }

    private getButtonText() {
        if (this.state.model.selectedPoll?.status == PollStates.CLOSED) return 'Votación cerrada';
        if (this.presenter.isSelectedPollEnded()) return 'La encuesta finalizó';
        if (this.state.model.selectedPoll?.unitHasAlreadyVoted) return 'Ya votaste en esta encuesta!';

        else return 'VOTAR';
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Box = styled.div`
  display: flex;
  min-height: 900px;
  max-height: 77vh;
  max-width: 1210px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid rgba(146, 162, 196, 0.5);
  background-color: #FFFFFF;
`;

const Detail = styled.div`
  width: 50%;
  padding: 20px;
`;

const _Polls = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  padding: 20px 9px 20px 20px;
  border-right: 1px solid rgba(146, 162, 195, 0.5);
`;

const NoPolls = styled.div`
  margin: auto;
  font-size: ${styles.fonts.size.regular};
`;






