import React from 'react';
import file from './file.png';
import darkGrayFolder from './darkGrayFolder.png';
import styled from 'styled-components';
import { DocumentInfo } from '@eroman/common/src/views/app/Documents/DocumentsVM';
import { styles } from '../../styles';

export const DocumentRow: React.FC<Props> = (props) => {
    return (
        <Row >
            {(props.document.type == 'Directory') ?
                <File onClick={() => props.onClick!!(props.document)}>
                    <_Icon src={darkGrayFolder}/>
                    <Title>{props.document.name}</Title>
                </File>
                :
                <File href={props.document.url!!}>
                    <Icon src={file}/>
                    <Title>{props.document.name}</Title>
                </File>
            }
            <LastUpdate>{props.document.updatedAt}</LastUpdate>
        </Row>
    );
};

const File = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  text-decoration: none;
  
  &:visited {
    color: #8A96BA;
  }
  &:active {
    color: #8A96BA;
  }
`;

const Title = styled.p``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  font-size: ${styles.fonts.size.small};
  font-weight: ${styles.fonts.weight.regular};
  color: #8A96BA;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(206, 214, 229, .5);

  &:last-child {
    border-bottom: none;
  }
`;

const LastUpdate = styled.div``;

const Icon = styled.img`
  margin-right: 5px;
  max-height: 18px;
  max-width: 14px;
`;

const _Icon = styled(Icon)`
  max-width: 20px;
  max-height: 15px;
`;

interface Props {
    document: DocumentInfo;
    onClick?: (document: DocumentInfo) => void;
}
