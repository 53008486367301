import { FileNameFormatter } from '@eroman/common/src/views/lib/formatters/FileNameFormatter';
import minusIcon from './minus.png';
import React from 'react';
import styled from 'styled-components';
import { RoundButton } from '../../components/buttons/RoundButton';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';
import classNames from 'classnames';

export const UploadedFile: React.FC<Props> = (props) => {
    const fileLabel = FileNameFormatter.formatFile(props.attachment.label, 30);
    return <Container className={classNames(props.className)} >
        <span>{fileLabel}</span>
        <_RoundButton icon={minusIcon} href="#" onClick={() => props.unSelectFile(props.attachment.name) } />
    </Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const _RoundButton = styled(RoundButton)`
  margin-left: 30px;
`;

interface Props {
    attachment: FileInfo,
    className?: string,
    unSelectFile: (name: string) => void,
}
