import { ChatMessage, MessageDirections } from '../../../models/chat/ChatMessage';

export class ChatVM {
    isLoading: boolean = false;
    isWriting: boolean = false;
    messages: ChatMessage[] = [];
    prompt: string = '';
    author: string = 'Tú';
    groupedMessages: GroupedMessages[] = [];
}

export interface MessageVM {
    body: string,
    direction: MessageDirections,
    at: string,
}

export interface GroupedMessages {
    date: string;
    messages: ChatMessage[];
}
