import { ClaimCommentVM } from './ClaimCommentVM';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { EventBus } from '../../../_base/eventBus/EventBus';
import { CommentClaim } from '../../../actions/claims/CommentClaim';
import { ClaimCommented } from '../../../events/claims/ClaimCommented';
import { handleValidationErrors } from '../../../_base/validation/catchValidationErrors';
import { Nullable } from '../../../_base/lang/Nullable';
import { FileInfo } from '../../../models/general/FileInfo';


export interface ClaimCommentFormView {
    modelChanged(model: ClaimCommentVM);
    showSuccessMessage();
}

export class ClaimCommentFormPresenter {
    private model = new ClaimCommentVM();

    constructor(private view: ClaimCommentFormView, private actionBus: ActionBus, private eventBus: EventBus) {
    }

    async submit(claimId) {
        try {
            let command = new CommentClaim(claimId, this.model.message, this.model.attachments);
            await this.actionBus.exec(command);
            this.set({ message: '' });
            await this.eventBus.post(new ClaimCommented(claimId));
            this.view.showSuccessMessage();
        } catch (e: any) {
            handleValidationErrors(e, _ => this.set({ errors: e.allErrorMessages() }));
        }
    }

    set<K extends keyof ClaimCommentVM>(changes: Pick<ClaimCommentVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    addAttachment(newFile: Nullable<FileInfo>) {
        if (newFile !== null) {
            this.set({ attachments: [...this.model.attachments, newFile!!] });
        }
    }

    removeAttachment(name: string) {
        const attachments = this.model.attachments.filter(at => at.name === name);
        const index = this.model.attachments.indexOf(attachments[0]);
        this.model.attachments.splice(index, 1);
        this.set({ attachments: this.model.attachments });
    }
}
