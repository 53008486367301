import { CommandHandler } from '../../_base/actionBus/CommandHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { Logout } from './Logout';

export class LogoutHandler implements CommandHandler<Logout> {
    constructor(private sessionStorage: SessionStorage) {}

    async handle(command: Logout) {
        return this.sessionStorage.do(session => session.logout());
    }
}
