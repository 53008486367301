import React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';

export const FormGeneralError: React.FC<Props> = (props) => {
    return <Message className={props.className}>{props.error}</Message>;
};

const Message = styled.div`
  color: #E15201;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
  padding-bottom: 20px;
`;

interface Props {
    error?: string;
    className?: string;
}
