import { PrimaryButton } from '../buttons/PrimaryButton';
import React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';
import { confirmAlert } from 'react-confirm-alert';

export const AlertModal: React.FC<Props> = (props) => {
    const close = () => {
        props.onClose?.();
        props.close();
    };

    return (
        <ModalContainer>
            <Title>{props.title}</Title>
            <Description>{props.message}</Description>
            {props.image &&
                <ImageContainer>
                    <Img src={props.image} alt=""/>
                </ImageContainer>
            }
            <ButtonContainer>
                <PrimaryButton onClick={close}>Cerrar</PrimaryButton>
            </ButtonContainer>
        </ModalContainer>
    );
};

export const showAlert = (title: string, message: string, onClose?: () => void, image?: string) => {
    confirmAlert({
        customUI: (props) => {
            return (
                <AlertModal
                    title={title}
                    message={message}
                    image={image}
                    onClose={onClose}
                    close={props.onClose}
                />
            );
        },
    });
};

const Title = styled.div``;

const Description = styled.p``;

const ButtonContainer = styled.div``;

const ModalContainer = styled.div`
  border: 1px solid rgba(117, 117, 117, .2);
  border-radius: 10px;
  padding: 2rem;
  background-color: white;

  ${Title} {
    margin-bottom: 1rem;
    font-size: ${styles.fonts.size.xxbig};
  }

  ${Description} {
    margin-bottom: 2rem;
    font-size: ${styles.fonts.size.regular};
  }

  ${ButtonContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 800px;
  height: 400px;
  margin-bottom: 10px;
  margin-top: -15px;
  align-self: center;
`;

interface Props {
    title: string;
    message?: string;
    image?: string;
    onClose?: () => void;
    close: () => void;
}
