import React from 'react';
import { IsAuthenticatedFunc } from './ReactRouterNavigation';

export class AuthGate extends React.Component<Props, any> {
    state = {
        showChildren: false,
    };

    async componentDidMount() {
        const isAuthenticated = await this.props.isAuthenticated();
        if (!isAuthenticated) {
            this.props.redirectToAuth();
            return;
        }
        this.setState({ showChildren: true });
    }

    render() {
        if (!this.state.showChildren) return null;
        return <>{this.props.children}</>;
    }
}

interface Props {
    isAuthenticated: IsAuthenticatedFunc;
    redirectToAuth: () => void;
}
