import { LocalDateTime } from '../../_base/time/LocalDateTime';
import { FileInfo } from '../general/FileInfo';

export class Announcement {
    constructor(
        public id: number,
        public associationId: number,
        public title: string,
        public content: string,
        public date: LocalDateTime,
        public attachments: FileInfo[] = [],
    ) {}

    static fromJson(json: any) {
        return new Announcement(
            json.id,
            json.associationId,
            json.title,
            json.content,
            LocalDateTime.fromISO8601String(json.date),
            json.attachments,
        );
    }

    toJson() {
        return {
            id: this.id,
            associationId: this.associationId,
            title: this.title,
            content: this.content,
            date: this.date.toISO8601String(),
            attachments: this.attachments,
        };
    }
}
