import React, { Component } from 'react';
import closeIcon from './x.png';
import styled from 'styled-components';
import { FormDateField } from '../../components/form/FormDate/FormDateField';
import { FormTextField } from '../../components/form/FormText/FormTextField';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import AppContext from '../../lib/AppContext';
import { Loader } from '../../components/Loader';
import { toast } from 'react-toastify';
import { FormNumberField } from '../../components/form/FormNumber/FormNumberField';
import { FormFileField } from '../../components/form/FormFileField/FormFileField';
import { FormFileFieldStates } from '@eroman/common/src/views/components/form/FormFileField/FormFileFieldVM';
import { FormGeneralError } from '../../components/form/FormGeneralError';
import { InformPaymentView } from '@eroman/common/src/views/app/InformPayment/InformPaymentPresenter';
import { InformPaymentVM } from '@eroman/common/src/views/app/InformPayment/InformPaymentVM';
import { styles } from '../../styles';
import { RightModal } from '../../components/modals/RightModal';

export class InformPaymentModal extends Component<Props, State> implements InformPaymentView {
    private presenter = AppContext.presenters.informPayment(this);

    state = {
        model: new InformPaymentVM(),
        canSubmit: true,
    };

    componentDidMount() {
        this.presenter.start();
    }

    modelChanged(model: InformPaymentVM) {
        this.setState({ model });
    }

    showSuccessMessage() {
        this.props.closeModal();
        toast.success('Informado con éxito');
    }

    onReceiptStateChanged(state) {
        this.setState({ canSubmit: state !== FormFileFieldStates.Uploading });
    }

    renderForm() {
        return <>
            <Form>
                <FormGeneralError error={this.state.model.errors['']} />
                <FormBody>
                    <FormDateField
                        label="Fecha de pago"
                        value={this.state.model.date}
                        onChangeDate={date => this.presenter.set({ date })}
                        error={this.state.model.errors['date']}
                    />
                    <_FormNumberField
                        label="Importe pagado"
                        value={this.state.model.amount}
                        onChangeText={amount => this.presenter.set({ amount })}
                        error={this.state.model.errors['amount']}
                    />
                    <FormTextField
                        label="Número de recibo/transacción"
                        value={this.state.model.transactionNumber}
                        onChangeText={transactionNumber => this.presenter.set({ transactionNumber })}
                        error={this.state.model.errors['transactionNumber']}
                    />
                    <FormFileField
                        className="large"
                        label="Copia del comprobante"
                        value={this.state.model.receiptFile}
                        onChangeFile={receiptFile => this.presenter.set({ receiptFile })}
                        onChangeState={state => this.onReceiptStateChanged(state)}
                        error={this.state.model.errors['receiptFile']}
                    />
                </FormBody>
                <_PrimaryButton onClick={() => this.presenter.submit()} isDisabled={!this.state.canSubmit}>Informar Pago</_PrimaryButton>
            </Form>
        </>;
    }

    render() {
        return (
            <RightModal
                isOpen={this.props.isOpen}
                closeModal={this.props.closeModal}
            >
                <>
                    <Header>
                        <Column>
                            <Title>Informar pago</Title>
                            <p>Recuerde que no es necesario informar su pago si utiliza los servicios de RED DE PAGOS
                                S.A.</p>
                        </Column>
                        <Close onClick={this.props.closeModal}><img src={closeIcon} alt="Cerrar"/></Close>
                    </Header>
                    {this.state.model.isLoading ? <Form><Loader/></Form> : this.renderForm()}
                </>
            </RightModal>
        );
    }
}

const Title = styled.p``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 10px;

  p {
    font-size: ${styles.fonts.size.regular};
    font-weight: ${styles.fonts.weight.regular};
    color: #666F89;
    margin-top: 10px;
  }

  ${Title} {
    font-size: ${styles.fonts.size.xxbig};
    font-weight: ${styles.fonts.weight.bold};
    color: #2B3674;
    margin-top: 0;
    line-height: 28px;
  }

  a {
    flex-shrink: 0;
    height: min-content;
  }
`;

const Close = styled.a`
  & img:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const FormBody = styled.div``;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  margin-bottom: 25px;
  flex-grow: 1;
  
  ${FormBody} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
    > * { flex-basis: 262px; }
    
    > .large { flex-basis: 100%; }
  }
`;

const _FormNumberField = styled(FormNumberField)`
    width: 162px;
`;

const _PrimaryButton = styled(PrimaryButton)`
  width: fit-content;
`;

const Column = styled.div`
  width: 100%;
`;

interface Props {
    isOpen: boolean,
    closeModal: () => void,
}

interface State {
    model: InformPaymentVM;
    canSubmit: boolean;
}
