import { RegisterDeviceTokenHandler } from '@eroman/common/src/actions/registerDeviceToken/RegisterDeviceTokenHandler';
import { RegisterDeviceToken } from '@eroman/common/src/actions/registerDeviceToken/RegisterDeviceToken';

export class WebRegisterDeviceTokenHandler extends RegisterDeviceTokenHandler {
    constructor() {
        super();
    }

    async handle(command: RegisterDeviceToken): Promise<void> {
        // Do nothing
    }
}
