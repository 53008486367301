import { FileData } from '@eroman/common/src/infrastructure/uploader/FileData';

export class DomFileData implements FileData {
    constructor(private _file: File) {}

    get name(): string {
        return this.file.name;
    }

    get sizeInBytes(): number {
        return this.file.size;
    }

    get file(): File {
        return this._file;
    }

    getData() {
        const formData = new FormData();
        formData.append('file', this.file);
        return formData;
    }
}
