import { GetMessages } from './GetMessages';
import { ChatService } from '../../models/chat/ChatService';
import { SessionStorage } from '../../models/session/SessionStorage';
import { ChatMessage } from '../../models/chat/ChatMessage';
import { QueryHandler } from '../../_base/actionBus/QueryHandler';

export class GetMessagesHandler implements QueryHandler<GetMessages, ChatMessage[]> {
    constructor(private chatService: ChatService, private sessionStorage: SessionStorage) {}

    async handle(query: GetMessages): Promise<ChatMessage[]> {
        const session = await this.sessionStorage.get();
        let organizationName = 'eroman';
        let recipient = `${organizationName}-${session.unit!.manager.id}-${session.unit!.association.id}-${session.user!.email}`;
        return this.chatService.getMessages(recipient);
    }
}
