import { Nullable } from '../../../../_base/lang/Nullable';
import { FileInfo } from '../../../../models/general/FileInfo';

export class FormFileFieldVM {
    state = FormFileFieldStates.SelectFile;
    fileInfo: Nullable<FileInfo> = null;
    uploadProgress = 0;
    error: Nullable<string> = null;
}

export enum FormFileFieldStates {
    SelectFile = 'SelectFile',
    Uploading = 'Uploading',
    Uploaded = 'Uploaded',
}
