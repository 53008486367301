import { PaymentProviders } from './PaymentProviders';
import { RedepagosProvider } from './RedepagosProvider';
import { PaymentProvider } from './PaymentProvider';
import { BankTransferProvider } from './BankTransferProvider';
import { CashProvider } from './CashProvider';
import { CVUTransferProvider } from './CVUTransferProvider';

export function paymentProviderfromJson(json: any): PaymentProvider {
    switch (json.type) {
        case PaymentProviders.Redepagos: return new RedepagosProvider();
        case PaymentProviders.BankTransfer: return BankTransferProvider.fromJson(json);
        case PaymentProviders.Cash: return new CashProvider();
        case PaymentProviders.CVUTransfer: return new CVUTransferProvider();
        default: throw new Error(`Invalid payment provider with type: ${json.type}`);
    }
}
