import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { GetUnitClaims } from '@eroman/common/src/actions/claims/GetUnitClaims';
import { ClaimActivityInfo } from '@eroman/common/src/views/app/Claims/ClaimActivityInfo';
import { Claim } from '@eroman/common/src/models/claims/Claim';
import { EventBus } from '@eroman/common/src/_base/eventBus/EventBus';
import { WebNavigator } from '../../lib/navigation/WebNavigator';
import { DatesFormatter } from '@eroman/common/src/views/lib/formatters/DatesFormatter';
import { GetClaimActivity } from '@eroman/common/src/actions/claims/GetClaimActivity';
import { ClaimActivity } from '@eroman/common/src/models/claims/activity/ClaimActivity';
import { Nullable } from '@eroman/common/src/_base/lang/Nullable';
import { ClaimCommentedActivity } from '@eroman/common/src/models/claims/activity/ClaimCommentedActivity';
import { FileInfo } from '@eroman/common/src/models/general/FileInfo';
import { FileVM } from '../Announcements/AnnouncementsVM';
import { DownloadUrlBuilder } from '@eroman/common/src/views/lib/DownloadUrlBuilder';
import { ClaimsVM } from './ClaimsVM';
import { ClaimCommented } from '@eroman/common/src/events/claims/ClaimCommented';
import { ClaimCreated } from '@eroman/common/src/events/claims/ClaimCreated';

export interface ClaimsView {
    modelChanged(model: ClaimsVM);
}

export class ClaimsPresenter {
    private model = new ClaimsVM();
    private activities: ClaimActivity[] = [];

    constructor(
        private view: ClaimsView,
        private actionBus: ActionBus,
        private eventBus: EventBus,
        private navigator: WebNavigator,
        private downloadUrlBuilder: DownloadUrlBuilder
    ) {
        this.eventBus.subscribe(this, ClaimCommented, this.refresh.bind(this));
        this.eventBus.subscribe(this, ClaimCreated, this.refresh.bind(this));
        this.navigator.currentRouteChanged.subscribe(this, this.onCurrentRouteChanged.bind(this));
    }

    async start() {
        await this.loadClaims();
        await this.selectClaimFromCurrentRoute();
    }

    async refresh() {
        await this.loadClaims();
        await this.loadClaimActivities();
    }

    private async loadClaims() {
        this.set({ isLoading: true });
        const claims = await this.actionBus.query(new GetUnitClaims());
        this.set({ claims: claims.map(claim => this.toClaimInfo(claim)), isLoading: false });
        if (this.navigator.currentRoute?.name === 'claims' && this.model.claims.length > 0) {
            this.navigator.navigate('claim-detail', { id: this.model.claims.first().id });
        }
    }

    private toFileVM(file: FileInfo): FileVM {
        return {
            url: this.downloadUrlBuilder.buildFor(file),
            label: file.label,
        };
    }

    toClaimInfo(claim: Claim) {
        return {
            id: claim.id,
            subjectTitle: claim.subject.title,
            description: claim.description,
            isClosed: claim.isClosed,
            createdAt: DatesFormatter.fullDateTimeWithoutSeconds(claim.createdAt),
            attachments: claim.attachments.map(this.toFileVM.bind(this)),
        };
    }

    private async loadClaimActivities() {
        this.set({ isLoading: true });
        if (this.model.selectedClaim !== null) {
            const activities = await this.actionBus.query(new GetClaimActivity(this.model.selectedClaim.id));
            this.activities = activities ? activities : this.activities;
            this.set({ activities: this.activities.map(a => this.toClaimActivityInfo(a)) });
        }
        this.set({ isLoading: false });
    }

    private toClaimActivityInfo(activity: ClaimActivity): ClaimActivityInfo {
        let message: Nullable<string> = null;
        let attachments: FileVM[] = [];
        if (activity.type === 'ClaimCommentedActivity') {
            const castedActivity = activity as ClaimCommentedActivity;
            message = castedActivity.commentMessage;
            attachments = castedActivity.attachments.map(a => this.toFileVM(a));
        }

        return {
            id: activity.id,
            type: activity.type,
            date: DatesFormatter.fullDateTimeWithoutSeconds(activity.date),
            claimId: activity.claimId,
            actorName: activity.actorName,
            actorType: activity.actorType,
            commentMessage: message,
            attachments: attachments,
        };
    }

    async selectClaim(id: number) {
        this.navigator.navigate('claim-detail', { id });
    }

    async onCurrentRouteChanged() {
        await this.selectClaimFromCurrentRoute();
    }

    private async selectClaimFromCurrentRoute() {
        const id = parseInt(this.navigator.currentRoute?.params?.id, 10);
        let selectedClaim = this.model.claims.singleOrNull(p => p.id === id);
        this.set({ selectedClaim: selectedClaim });
        await this.loadClaimActivities();
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }

    set<K extends keyof ClaimsVM>(changes: Pick<ClaimsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
