import React from 'react';
import styled from 'styled-components';
import closeIcon from './closeIcon.png';
import openIcon from './openIcon.png';
import arrow from '../../Announcements/arrow.png';
import clip from '../../Announcements/clip.png';
import classNames from 'classnames';
import { ClaimInfo } from '../ClaimsVM';
import { styles } from '../../../styles';

export const ClaimRow: React.FC<Props> = (props) => {
    return (
        <Container
            className={classNames(props.className, { selected: props.selected })}
            onClick={() => props.onClick(props.item.id)}
        >
            <Header>
                <Icon src={props.item.isClosed ? closeIcon : openIcon}/>
                <Status>{props.item.isClosed ? 'Cerrado ' : 'Abierto '}</Status>
                <Date>&nbsp;|&nbsp;{props.item.createdAt}</Date>
                {props.item.attachments.length > 0 && <Img src={clip} alt="Adjunto"/>}
                <img src={arrow} alt="Ver comunicado"/>
            </Header>
            <Title>{props.item.subjectTitle}</Title>
        </Container>);
};

const Icon = styled.img``;

const Status = styled.p``;

const Date = styled.p``;

const Img = styled.img``;

const Header = styled.div`
  display: flex;
  align-items: center;

  ${Date} {
    flex-grow: 1;
    font-size: ${styles.fonts.size.small};
    line-height: 18px;
    color: #8A96BA;
  }

  img {
    visibility: hidden;
    width: 5px;
    height: 9px;
  }

  ${Icon} {
    visibility: visible;
    width: 14px;
    height: 14px;
    margin-right: 16px;
  }

  ${Img} {
    width: 9px;
    visibility: visible;
    height: 15px;
    margin-right: 9px;
  }
`;

const Container = styled.div`
  padding: 15px;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover, &.selected {
    background-color: #F4F7FE;

    ${Header} img {
      visibility: visible;
    }
  }
`;

const Title = styled.p`
  color: #5F6B8C;
  font-size: ${styles.fonts.size.big};
  font-weight: ${styles.fonts.weight.regular};
  margin-left: 30px;
`;

interface Props {
    item: ClaimInfo,
    onClick: (id: number) => void;
    selected: boolean;
    className?: string;
}
