import { QueryHandler } from '../../_base/actionBus/QueryHandler';
import { SessionStorage } from '../../models/session/SessionStorage';
import { GetUnitPolls } from './GetUnitPolls';
import { Poll } from '../../models/polls/Poll';
import { PollService } from '../../models/polls/PollService';

export class GetUnitPollsHandler implements QueryHandler<GetUnitPolls, Poll[]> {
    constructor(private pollService: PollService, private sessionStorage: SessionStorage) {}

    async handle(query: GetUnitPolls): Promise<Poll[]> {
        const session = await this.sessionStorage.get();
        return this.pollService.getUnitPolls(session.unit!.id);
    }
}
