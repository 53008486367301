import { HtmlSanitizer } from './HtmlSanitizer';
import safeHtml from 'safe-html';

export class SafeHtmlSanitizer implements HtmlSanitizer {
    constructor(
        private allowedTags: String[] = ['b', 'p', 'br', 'strong', 'i', 'h1', 'h2', 'h3', 'h4', 'h5', 'u'],
        private allowedAttrs: String[] = [],
    ) {}

    sanitize(html: string): string {
        const config = { allowedAttributes: this.allowedAttrs, allowedTags: this.allowedTags };
        return safeHtml(html, config);
    }
}
