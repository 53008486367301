import { Command } from '../../_base/actionBus/Command';
import { ChatMessage } from '../../models/chat/ChatMessage';

export class SendMessage extends Command<ChatMessage> {
    constructor(
        public managerId: number,
        public message: string,
    ) {
        super();
    }
}
