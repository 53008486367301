import React from 'react';
import styled from 'styled-components';
import { styles } from '../../../styles';

export const HalfDetailField: React.FC<Props> = (props) => (
    <Container>
        <Title>{props.title}</Title>
        <Value color={props.color}>{props.value}</Value>
    </Container>
);

const Container = styled.div`
  flex-basis: 47%;
`;

const Title = styled.p`
  font-family: "Nunito", sans-serif;
  font-size: 13px;
  font-weight: ${styles.fonts.weight.bold};
  color: #8A96BA;
  line-height: 18px;
`;
const Value = styled.p.attrs((props: { color: string }) => props)`
  color: ${(props) => props.color};
  font-size: ${styles.fonts.size.regular};
  font-weight: ${styles.fonts.weight.regular};
`;

interface Props {
    title: string,
    value: string,
    color?: string,
}