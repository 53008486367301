import React from 'react';
import styled from 'styled-components';
import { styles } from '../styles';

export const HtmlContent: React.FC<Props> = (props) => (
    <Content dangerouslySetInnerHTML={{ __html: props.content }}/>
);

interface Props {
    content: string
}

const Content = styled.div`
  font-size: ${styles.fonts.size.regular};
  line-height: 21px;
  color: #5F6B8C;

  p {
    font-size: 13px;
    line-height: 18px;
    color: #5F6B8C;
  }

  h1 {
    line-height: 18px;
    color: #5F6B8C;
  }

  h2 {
    line-height: 18px;
    color: #5F6B8C;
  }

  h3 {
    line-height: 18px;
    color: #5F6B8C;
  }

  h4 {
    line-height: 18px;
    color: #5F6B8C;
  }

  h5 {
    line-height: 18px;
    color: #5F6B8C;
  }

  a {
    line-height: 18px;
    color: #5F6B8C;
  }
`;
