import { BaseActionsConfig, BaseActionsInitializer } from '@eroman/common/src/infrastructure/BaseActionsInitializer';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { RegisterDeviceToken } from '@eroman/common/src/actions/registerDeviceToken/RegisterDeviceToken';
import { WebRegisterDeviceTokenHandler } from '../actions/RegisterDeviceToken/WebRegisterDeviceTokenHandler';

export interface ActionsConfig extends BaseActionsConfig {
}

export class ActionsInitializer extends BaseActionsInitializer {
    constructor(config: ActionsConfig) {
        super(config);
    }

    protected registerCommands(actionBus: ActionBus) {
        super.registerCommands(actionBus);
        actionBus.registerCommand(RegisterDeviceToken, new WebRegisterDeviceTokenHandler());
    }

    protected registerQueries(actionBus: ActionBus) {
        super.registerQueries(actionBus);
    }
}
