import React from 'react';
import styled from 'styled-components';
import { ClaimActivityInfo } from '@eroman/common/src/views/app/Claims/ClaimActivityInfo';
import { ActivityIcon } from './ActivityIcon';
import { styles } from '../../../styles';
import { AttachmentList } from '../../../components/attachmentList/AttachmentList';

export const ClaimActivity: React.FC<Props> = (props) => {
    return (
        <Activity>
            {props.info.actorType === 'Manager' && <ActivityIcon color={'#5F6B8C'} letter={'A'} />}
            {props.info.actorType === 'User' && <ActivityIcon color={'#CED6E5'} letter={props.info.actorName[0].toUpperCase()} />}
            <ActivityInfo>
                {props.info.type === 'ClaimClosedActivity' && <Action>{props.info.actorName} ha cerrado el reclamo</Action> }
                {props.info.type === 'ClaimOpenedActivity' && <Action>{props.info.actorName} ha abierto el reclamo</Action> }
                {
                    props.info.type === 'ClaimCommentedActivity' &&
                    <CommentBox>
                        <p>{props.info.commentMessage}</p>
                    </CommentBox>
                }
                {
                    props.info.attachments.isNotEmpty() && <AttachmentList attachments={props.info.attachments} />
                }
                <Date>{props.info.date}</Date>
            </ActivityInfo>
        </Activity>
    );
};

const ActivityInfo = styled.div``;

const Action = styled.div``;

const Date = styled.div``;

const CommentBox = styled.div``;

const Activity = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  
  ${ActivityInfo} {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
    
    ${Action} {
      color: #5F6B8C;
      font-size: 13px;
      font-weight: ${styles.fonts.weight.bold};
    }
    
    ${Date} {
      font-size: 11px;
      font-weight: 400;
    }
    
    ${CommentBox} {
      background-color: #F4F7FE;
      padding: 10px;
      flex-grow: 1;
      margin-bottom: 3px;
      
      p {
        text-align: left;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
`;

interface Props {
    info: ClaimActivityInfo,
}
