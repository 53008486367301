import React from 'react';
import { ClaimActivityInfo } from '@eroman/common/src/views/app/Claims/ClaimActivityInfo';
import styled from 'styled-components';
import { ClaimActivity } from './ClaimActivity/ClaimActivity';
import { ClaimCommentForm } from './ClaimCommentForm';
import { AttachmentList } from '../../components/attachmentList/AttachmentList';
import { ClaimInfo } from './ClaimsVM';
import { styles } from '../../styles';
import { ScrollableList } from '../../components/ScrollableList';

export const ClaimDetail: React.FC<Props> = (props) => {
    return <ScrollableList>
        <Title>{props.claim.subjectTitle}</Title>
        <Content>{props.claim.description}</Content>
        <AttachmentList attachments={props.claim.attachments} />
        <ClaimStatusContainer>
            <Status>
                Estado del reclamo: {props.claim.isClosed ? <Closed>Cerrado</Closed> : <Open>Abierto</Open>}
            </Status>
        </ClaimStatusContainer>
        <ClaimActivityContainer>
            <_Title>ACTIVIDAD</_Title>
            {!props.claim.isClosed && <ClaimCommentForm claimId={props.claim.id}/>}
            {props.activities.isNotEmpty() && props.activities.map(activity => <ClaimActivity key={activity.date} info={activity}/>)}
        </ClaimActivityContainer>
    </ScrollableList>;
};

const Content = styled.div`
  color: #8A96BA;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.regular};
  line-height: 21px;
`;

const Title = styled.p`
  color: #5F6B8C;
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.big};
  margin-bottom: 8px;
`;

const _Title = styled(Title)`
  margin-top: 15px;
`;

const ClaimStatusContainer = styled.div`
  border-top: 1px solid rgba(146, 162, 195, 0.5);
  border-bottom: 1px solid rgba(146, 162, 195, 0.5);
  margin-top: 15px;
`;

const Status = styled.p`
  font-weight: ${styles.fonts.weight.regular};
  font-size: ${styles.fonts.size.small};
  color: #5F6B8C;
  line-height: 16px;
  margin: 22px 0;
`;

const Closed = styled.span`
  color: #BDBDBD;
`;

const Open = styled.span`
  color: #11AB8F;
`;

const ClaimActivityContainer = styled.div``;

interface Props {
    claim: ClaimInfo;
    activities: ClaimActivityInfo[];
}
